import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes } from 'react';

import styles from './AssetPill.module.scss';

interface AssetPillProps extends HTMLAttributes<SVGElement> {
  isActive?: boolean;
  pill: string;
  content?: string;
}

export const AssetPill: FunctionComponent<AssetPillProps> = ({ isActive = false, pill, content, className }) => (
  <div className={classNames(className, isActive && styles.isActive, styles.assetPill)} data-testid="assetPill">
    <div className={styles.pill}>{pill}</div>
    <span className={styles.content}>{content}</span>
  </div>
);
