import classNames from 'classnames';
import { HTMLAttributes, useState, useRef } from 'react';
import { FieldError } from 'react-hook-form';
import { ReactElement } from 'react-markdown';

import { useBundle } from '@amzn/react-arb-tools';

import { Icon } from 'src/components/atoms';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';

import styles from './TextArea.module.scss';

interface TextAreaProps extends HTMLAttributes<HTMLTextAreaElement> {
  label: string;
  id: string;
  maxLength: number;
  value: string;
  errors?: FieldError;
  disabled?: boolean;
  sublabel?: string;
}

export function TextArea({
  label,
  id,
  maxLength,
  value,
  errors,
  disabled,
  sublabel,
  ...props
}: TextAreaProps): ReactElement {
  const [isFocused, setIsFocused] = useState(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const resize = (e: MouseEvent) => {
    if (textAreaRef.current && !disabled) {
      textAreaRef.current.style.height = `${e.clientY - textAreaRef.current.getBoundingClientRect().top}px`;
    }
  };

  const stopResize = () => {
    window.removeEventListener('mousemove', resize);
    window.removeEventListener('mouseup', stopResize);
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    window.addEventListener('mousemove', resize);
    window.addEventListener('mouseup', stopResize);
  };

  const characterCount = `${value.length} / ${maxLength.toLocaleString()}`;

  return (
    <div className={styles.textAreaContainer}>
      {sublabel && (
        <div className={styles.subLabelContainer}>
          <Icon name="info" className={classNames(styles.subLabelIcon, errors && styles.error)} />
          <p className={classNames(styles.subLabel, errors && styles.error)}>{sublabel}</p>
        </div>
      )}
      <div
        className={classNames(styles.textAreaWrapper, errors && styles.error, disabled && styles.disabled)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      >
        {!value && !isFocused && (
          <label htmlFor={id} className={classNames(styles.label, errors && styles.error, disabled && styles.disabled)}>
            {label}
          </label>
        )}
        <textarea
          className={classNames(styles.textArea, errors && styles.error, disabled && styles.disabled)}
          ref={textAreaRef}
          id={id}
          name={id}
          disabled={disabled}
          aria-disabled={disabled}
          data-testid="textarea-input"
          {...props}
        />
        <div
          className={classNames(styles.textAreaResizer, errors && styles.error, disabled && styles.disabled)}
          onMouseDown={handleMouseDown}
          aria-hidden
        >
          <Icon name="resize" />
        </div>
        <p className={classNames(styles.characterCount, errors && styles.error, disabled && styles.disabled)}>
          {characterCount} {t(commonBundle, 'characterCount')}
        </p>
      </div>
      {errors && !disabled && (
        <div className={styles.errorWrapper}>
          <Icon name="error" className={styles.errorIcon} />
          <p className={styles.errorMessage}>{errors.message}</p>
        </div>
      )}
    </div>
  );
}
