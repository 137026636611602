import classNames from 'classnames';
import React, { type ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { Icon, IconType } from 'src/components/atoms';
import { CTA_TYPE, LIST_VARIANT, ListItem } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { shouldRenderFluidPageSection } from 'src/helpers/library-helpers';
import { ImageAttributes } from 'src/interfaces/CommonAttributes';

import styles from './Accordion.module.scss';

export enum ACCORDION_VARIANT {
  MainBanner = 'main-banner',
  AccordionComponent = 'accordion-component',
}

export type AccordionProps = {
  theme?: SECTION_THEME;
  variant?: ACCORDION_VARIANT;
  title?: string;
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle?: string;
  content: {
    cta?: { href: string; icon?: IconType; type?: CTA_TYPE; label?: string };
    image?: ImageAttributes;
    text?: string;
    subtitle?: string;
    secondText?: string;
    links?: { label: string; url: string }[];
  }[];
};

type AccordionComponentProps = AccordionProps & {
  isOpen: boolean;
  onClick(): void;
};

export function Accordion({
  theme = SECTION_THEME.Dark,
  variant = ACCORDION_VARIANT.MainBanner,
  title,
  subtitle,
  content,
  isOpen,
  titleSize = 'h4',
  onClick,
}: AccordionComponentProps): ReactElement {
  const [contentHeight, setContentHeight] = useState<number | string>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const renderFluidPageSection = shouldRenderFluidPageSection();

  const TitleTag = React.createElement(
    titleSize,
    { className: classNames(styles.accordionTitle, styles[theme], styles[variant], isOpen && styles.isOpen) },
    title
  );

  useEffect(() => {
    const updateContentHeight = () => {
      if (isOpen) {
        setContentHeight('auto');
        window.requestAnimationFrame(() => contentRef.current && setContentHeight(contentRef.current.scrollHeight));
      } else {
        setContentHeight(0);
      }
    };

    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);

    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, [isOpen, contentRef]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  return (
    <div className={classNames(styles.accordion, renderFluidPageSection && styles.hasFluidPaddings, styles[theme])}>
      <button
        className={styles.accordionHeader}
        type="button"
        onClick={onClick}
        onKeyDown={onKeyDown}
        aria-expanded={isOpen}
      >
        {TitleTag}
        <div
          className={classNames(
            styles.accordionToggle,
            styles[variant],
            styles[theme],
            styles[isOpen ? 'open' : 'closed']
          )}
          aria-label={isOpen ? 'Collapse' : 'Expand'}
        >
          <Icon name={isOpen ? 'minus' : 'plus'} className={styles.accordionIcon} />
        </div>
      </button>
      <div className={styles.content} ref={contentRef} style={{ height: contentHeight }} aria-hidden={!isOpen}>
        {subtitle && <p className={classNames(styles.subtitle, styles[theme])}> {subtitle} </p>}
        {variant === ACCORDION_VARIANT.MainBanner && (
          <p className={classNames(styles.accordionContent, styles[theme])}> {content[0].text} </p>
        )}
        {variant === ACCORDION_VARIANT.AccordionComponent &&
          content.map((item, index) => (
            <React.Fragment key={`${item.text}-${item.subtitle}`}>
              <ListItem
                key={`${item.text}-${item.subtitle}`}
                theme={theme}
                title={item.subtitle}
                headingSize="h5"
                subtitle={item.text}
                image={item.image}
                links={item.links}
                cta={item.cta}
                isOpen={isOpen}
                variant={LIST_VARIANT.Accordion}
              />
              {index < content.length - 1 && <div className={classNames(styles.listUnderline, styles[theme])} />}
            </React.Fragment>
          ))}
      </div>
    </div>
  );
}
