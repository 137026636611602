import translationde from './translation-de.json';
import translationen from './translation-en.json';
import translationes from './translation-es.json';
import translationfr from './translation-fr.json';
import translationit from './translation-it.json';
import translationja from './translation-ja.json';
import translationpt from './translation-pt.json';
import translationzh from './translation-zh.json';
import translation from './translation.json';

// webpack transforms these JSON imports to resource URLs, so we cast our export accordingly here.
const i18nEndPointMap: Record<string, string> = {
  'translation-de.json': translationde as unknown as string,
  'translation-en.json': translationen as unknown as string,
  'translation-es.json': translationes as unknown as string,
  'translation-fr.json': translationfr as unknown as string,
  'translation-it.json': translationit as unknown as string,
  'translation-ja.json': translationja as unknown as string,
  'translation-pt.json': translationpt as unknown as string,
  'translation-zh.json': translationzh as unknown as string,
  'translation.json': translation as unknown as string
}

export default i18nEndPointMap;
