import { FunctionComponent } from 'react';

import { Card, CardProps } from 'src/components/common';

import styles from './Grid.module.scss';

type GridProps = {
  cards: Array<CardProps>;
};

export const Grid: FunctionComponent<GridProps> = ({ cards }: GridProps) => (
  <div className={styles.grid}>
    {cards.map((card, index) => {
      const key = `${card.title}-${index}`;
      return <Card key={key} {...card} />;
    })}
  </div>
);
