import { FunctionComponent, PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import {
  AccordionDisplay,
  Page,
  Resource,
  ResourceType,
  SectionType,
} from '@amzn/coral_com-amazon-sssapinterface-model';
import {
  AsxFormContentType,
  AsxFormType,
  ModuleIngress,
  PageIngress,
} from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { KatProgress } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { IconType } from 'src/components/atoms';
import {
  AnchorLinks,
  Card,
  CardV2,
  CTA_TYPE,
  ListComponent,
  MainBanner,
  PageSection,
  SecondaryHero,
  Slider,
  SliderV2,
  TeaserCard,
  TeaserCardImage,
  TextComponent,
} from 'src/components/common';
import { CONTACT_FORM_KEYS } from 'src/components/pages/contact/ContactForm';
import { pageIngressToTopic } from 'src/components/pages/contact/form-helpers';
import { useFocusAreaTeaser } from 'src/components/pages/home/hooks/useFocusAreaCards';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';
import { FORM_OVERRIDES_QUERY_PARAM, METADATA_QUERY_PARAM } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import {
  extractAssetDisplayType,
  getDisplayType,
  shouldRenderAccessibleSlider,
  t,
  formatUrl,
  formatResourceTypeForPill,
} from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import measurementImage from 'src/images/resources/measureAndReportBannerImage.jpg';
import scienceExchangeImage from 'src/images/resources/scienceExchangeBannerImage.jpg';

import { useResourcePageSections } from './useResourcePageSections';

interface ResourceTemplateProps extends PropsWithChildren {
  page: Page;
  mainBundle: ReturnType<typeof useBundle>[0];
}

const PAGE_INGRESS_BY_ID: Record<string, PageIngress> = {
  buildings: PageIngress.BUILDINGS,
  'carbon-freeenergy': PageIngress.CARBON_FREE_ENERGY,
  carbonneutralization: PageIngress.CARBON_NEUTRALIZATION,
  humanrights: PageIngress.HUMAN_RIGHTS,
  transportation: PageIngress.TRANSPORTATION,
  wasteandcircularity: PageIngress.WASTE_AND_CIRCULARITY,
  waterstewardship: PageIngress.WATER_STEWARDSHIP,
  measurement: PageIngress.MEASUREMENT,
};

const MODULE_TO_CONTENT_TYPE: Partial<Record<ModuleIngress, AsxFormContentType>> = {
  [ModuleIngress.CASE_STUDY_CARD]: AsxFormContentType.CASE_STUDY,
  [ModuleIngress.ADDITIONAL_RESOURCES_CARD]: AsxFormContentType.RESOURCE,
};

export const ResourceTemplate: FunctionComponent<ResourceTemplateProps> = ({ children, page, mainBundle }) => {
  const { getCtaByResource } = useCommonStrings();
  const { isSmallDesktop } = useDeviceSize();
  const displayType = getDisplayType(isSmallDesktop);
  const {
    isBundleLoading,
    commonBundle,
    secondaryHeroSection,
    secondaryHeroResourceBundle,
    secondaryHeroResource,
    secondaryHeroImageAlt,
    secondaryHeroImage,
    playbookSection,
    playbookResource,
    playBookResourceBundle,
    playbookPdf,
    playbookImage,
    playbookImageAlt,
    playbookAccordions,
    caseStudiesSection,
    caseStudiesResources,
    caseStudiesResourceBundle,
    additionalResSection,
    additionalResResources,
    bundleByResourceType,
  } = useResourcePageSections(page);

  const focusAreaTeasers = useFocusAreaTeaser();
  const location = useLocation();

  // TODO remove after testing https://taskei.amazon.dev/tasks/V1558443290
  const renderAccessibleSlider = shouldRenderAccessibleSlider();

  if (!page) {
    return <Navigate to="/404" />;
  }

  if (isBundleLoading || !mainBundle) {
    return <KatProgress indeterminate type="circular" />;
  }

  const isMeasurementPage = location.pathname === ROUTES.MEASUREMENT;

  const formatContactUsLink = (moduleIngress: ModuleIngress) => {
    const pageIngress = PAGE_INGRESS_BY_ID[page.id];

    const contentType = MODULE_TO_CONTENT_TYPE[moduleIngress];
    const topic = pageIngressToTopic[pageIngress];
    const queryStrings: Record<string, string> = {
      [FORM_OVERRIDES_QUERY_PARAM]: JSON.stringify({
        [CONTACT_FORM_KEYS.FORM_TYPE]: AsxFormType.GENERAL_CONTENT_INTAKE,
        ...(contentType && { [CONTACT_FORM_KEYS.CONTENT_TYPES]: [contentType] }),
        ...(topic && { [CONTACT_FORM_KEYS.FORM_TOPICS]: [topic] }),
      }),
      [METADATA_QUERY_PARAM]: JSON.stringify({
        pageIngress,
        moduleIngress,
      }),
    };
    return `${ROUTES.CONTACT_US}?${new URLSearchParams(queryStrings).toString()}`;
  };

  return (
    <>
      {secondaryHeroSection && (
        <PageSection id={SectionType.SECONDARY_HERO}>
          <SecondaryHero
            heading={t(secondaryHeroResourceBundle, secondaryHeroResource.display?.title)}
            copy={t(secondaryHeroResourceBundle, secondaryHeroResource.display?.shortDescription)}
            image={{
              alt: secondaryHeroImageAlt,
              src: secondaryHeroImage,
            }}
            theme={SECTION_THEME.Light}
          />
        </PageSection>
      )}

      <AnchorLinks key={page.id} />

      {playbookSection && (
        <PageSection theme="green-primary" data-anchor id={t(commonBundle, 'playbookAnchor_id')}>
          <MainBanner
            title={t(playBookResourceBundle, playbookResource.display?.title)}
            accordions={
              playbookAccordions?.map((accordion: AccordionDisplay) => ({
                title: t(playBookResourceBundle, accordion.title),
                content: [{ text: t(playBookResourceBundle, accordion.description) }],
              })) ?? []
            }
            imageUrl={playbookImage ?? ''}
            alt={playbookImageAlt}
            cta={{
              icon: getCtaByResource(playbookResource.resourceType as ResourceType).icon as IconType,
              href: playbookPdf,
              label: getCtaByResource(playbookResource.resourceType as ResourceType).label,
            }}
            theme={SECTION_THEME.Dark}
          />
        </PageSection>
      )}

      {caseStudiesSection && (
        <PageSection theme="slate-dark" data-anchor id={t(commonBundle, 'caseStudiesAnchor_id')}>
          {renderAccessibleSlider ? (
            <SliderV2
              key={`case-studies-slider-${page.id}`}
              displayContents
              size="large"
              theme={SECTION_THEME.Dark}
              heading={t(mainBundle, caseStudiesSection.display?.title)}
              description={t(mainBundle, caseStudiesSection.display?.shortDescription)}
              tertiaryCta={{ href: formatContactUsLink(ModuleIngress.CASE_STUDY_CARD) }}
            >
              {caseStudiesResources.map((resource: Resource) => (
                <CardV2
                  headingSize="h5"
                  theme={SECTION_THEME.Dark}
                  pill={formatResourceTypeForPill(resource?.resourceType)}
                  key={resource.display?.name}
                  image={
                    resource.assets.secondaryAssets?.thumbnail &&
                    formatUrl(
                      extractAssetDisplayType(resource.assets.secondaryAssets?.thumbnail, displayType)?.assetUrl
                    )
                  }
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(caseStudiesResourceBundle, resource.display?.title)}
                  subtitle={t(caseStudiesResourceBundle, resource.display?.shortDescription)}
                  icon="download"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </SliderV2>
          ) : (
            <Slider
              key={`case-studies-slider-${page.id}`}
              displayContents
              size="large"
              theme={SECTION_THEME.Dark}
              heading={t(mainBundle, caseStudiesSection.display?.title)}
              description={t(mainBundle, caseStudiesSection.display?.shortDescription)}
              tertiaryCta={{ href: formatContactUsLink(ModuleIngress.CASE_STUDY_CARD) }}
            >
              {caseStudiesResources.map((resource: Resource) => (
                <Card
                  headingSize="h5"
                  theme={SECTION_THEME.Dark}
                  pill={formatResourceTypeForPill(resource?.resourceType)}
                  key={resource.display?.name}
                  image={
                    resource.assets.secondaryAssets?.thumbnail &&
                    formatUrl(
                      extractAssetDisplayType(resource.assets.secondaryAssets?.thumbnail, displayType)?.assetUrl
                    )
                  }
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(caseStudiesResourceBundle, resource.display?.title)}
                  subtitle={t(caseStudiesResourceBundle, resource.display?.shortDescription)}
                  icon="chevronRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </Slider>
          )}
        </PageSection>
      )}

      {children}

      {additionalResSection && (
        <PageSection data-anchor id={t(commonBundle, 'additionalResourcesAnchor_id')}>
          <TextComponent
            theme={SECTION_THEME.Light}
            heading={t(mainBundle, additionalResSection.display?.title) ?? ''}
            copy={t(mainBundle, additionalResSection.display?.shortDescription) ?? ''}
            formVariant="resources"
            ctas={{
              tertiary: {
                href: formatContactUsLink(ModuleIngress.ADDITIONAL_RESOURCES_CARD),
              },
            }}
          />
          <ListComponent
            items={additionalResResources.map((resource: Resource, index) => ({
              title: t(bundleByResourceType[resource.resourceType], resource.display?.title) || '',
              subtitle: t(bundleByResourceType[resource.resourceType], resource.display?.shortDescription) || '',
              cta: {
                type: index === 0 ? CTA_TYPE.Pdf : CTA_TYPE.Link,
                href: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                icon:
                  resource.assets.primaryAsset[0].contentFormat === 'PDF'
                    ? ('download' as IconType)
                    : ('chevronTopRight' as IconType),
              },
              image: {
                url: formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl),
                alt: resource.assets.primaryAsset[0].altText ?? '',
              },
              ...(resource.assets.primaryAsset[0].contentFormat === 'PDF' && { label: 'PDF 2.1 MB' }),
            }))}
          />
        </PageSection>
      )}

      {!isMeasurementPage && (
        <PageSection theme="slate-dark">
          <TeaserCard title={commonBundle ? t(commonBundle, 'keepExploringLabel') ?? '' : ''}>
            {focusAreaTeasers && focusAreaTeasers.length > 1 ? (
              focusAreaTeasers.map((props) => <TeaserCardImage key={props.cardTitle} {...props} />)
            ) : (
              <>
                {/* fall back cards */}
                <TeaserCardImage
                  image={measurementImage}
                  cardTitle={commonBundle ? t(commonBundle, 'measurementTitle_label') ?? '' : ''}
                  cta={commonBundle ? t(commonBundle, 'learnMore_label') ?? '' : ''}
                  linkUrl={ROUTES.MEASUREMENT}
                  alt={commonBundle ? t(commonBundle, 'measurement_imageAlt') ?? '' : ''}
                />
                )
                <TeaserCardImage
                  image={scienceExchangeImage}
                  cardTitle={commonBundle ? t(commonBundle, 'scienceTitle_label') ?? '' : ''}
                  cta={commonBundle ? t(commonBundle, 'learnMore_label') ?? '' : ''}
                  linkUrl={ROUTES.SCIENCE_EXCHANGE}
                  alt={commonBundle ? t(commonBundle, 'science_imageAlt') ?? '' : ''}
                />
              </>
            )}
          </TeaserCard>
        </PageSection>
      )}
      <ClimatePledgeSection />
    </>
  );
};
