import { FunctionComponent } from 'react';

import { KatDropdown, KatOption } from '@amzn/katal-react';

import { Icon, IconType } from 'src/components/atoms';

import styles from './Dropdown.module.scss';

export interface DropdownOption {
  text: string;
  value?: string;
  icon?: IconType;
}

interface DropdownProps extends KatDropdown.Props {
  options?: DropdownOption[];
  className?: string;
  reversedIconOrder?: boolean;
}

export const Dropdown: FunctionComponent<DropdownProps> = ({ options, className, reversedIconOrder, ...props }) => (
  <KatDropdown className={className} value={options?.[0]?.value || options?.[0]?.text} {...props} richSelectionLabel>
    {options &&
      options?.map((option) => (
        <KatOption
          className={styles.katalOption}
          key={option.text}
          value={option.value ?? option.text}
          // Under certain conditions the Dropdown fails to trigger onChange upon browser history transitions.
          // Explicitly triggering the onChange on option select in order to address this.
          onClick={() =>
            props.onChange &&
            props.onChange(new CustomEvent('change', { detail: { value: option.value ?? option.text } }) as any)
          }
        >
          {reversedIconOrder ? (
            <div data-testid="dropdown-option" className={styles.optionReversed}>
              {option.text}
              {option.icon && <Icon name={option.icon} className={styles.icon} />}
            </div>
          ) : (
            <div data-testid="dropdown-option" className={styles.option}>
              {option.icon && <Icon name={option.icon} className={styles.icon} />}
              {option.text}
            </div>
          )}
        </KatOption>
      ))}
  </KatDropdown>
);
