import classnames, { Argument } from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './PrimaryButtonV2.module.scss';

export enum PRIMARY_BUTTON_V2_SIZE {
  Regular = 'regularSize',
  Small = 'smallSize',
}

export enum PRIMARY_BUTTON_V2_VARIANT {
  Default = 'default',
  Inverted = 'inverted',
  Outline = 'outline',
}

export enum PRIMARY_BUTTON_V2_ICON_POSITION {
  After = 'after',
  Before = 'before',
}

type PrimaryButtonV2Props = (
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
  | React.ButtonHTMLAttributes<HTMLButtonElement>
) & {
  // TODO add documentation comments for every prop
  /**
   * String label displayed in button
   */
  label?: string;
  isBold?: boolean;
  /**
   * Optional icon to display in the button
   */
  icon?: IconType;
  highlightedIcon?: string;
  buttonSize?: PRIMARY_BUTTON_V2_SIZE;
  /**
   * Optional display variant for the button
   */
  variant?: PRIMARY_BUTTON_V2_VARIANT;
  /**
   * Optional theme style to apply to the button
   */
  theme?: SECTION_THEME;
  className?: Argument;
  /**
   * Optional position string for icon displayed in the button
   */
  iconPosition?: PRIMARY_BUTTON_V2_ICON_POSITION;
  /**
   * Optional argument to add a class to the content of the button, it's different from className because this one targets the
   * inner div where the content/copy is, rather than targeting the outer anchor like className does
   */
  contentClassName?: Argument;
  /**
   * For the cards we need to remove animation
   */
  hasHoverAnimation?: boolean;
  /**
   * Optional link to open on button click
   */
  link?: string;
  /**
   * Optional query strings to add to the link
   */
  queryStrings?: Record<string, string>;
};

/**
 * Main button element to be used on ASX. Supports themes and variations for different rendering styles.
 */
export const PrimaryButtonV2: FC<PrimaryButtonV2Props> = ({
  label,
  isBold,
  icon,
  variant = PRIMARY_BUTTON_V2_VARIANT.Default,
  theme = SECTION_THEME.Light,
  buttonSize = PRIMARY_BUTTON_V2_SIZE.Regular,
  className,
  link,
  queryStrings,
  iconPosition = PRIMARY_BUTTON_V2_ICON_POSITION.After,
  contentClassName,
  hasHoverAnimation = true,
  ...props
}) => {
  const commonProps = {
    className: classnames(
      className,
      styles.primaryButton,
      styles[variant],
      theme === SECTION_THEME.Dark && styles.isDark,
      !label && styles.isOnlyIcon,
      styles[buttonSize],
      isBold && styles.bold,
      icon && label && styles.icon,
      hasHoverAnimation && styles.hasHoverAnimation
    ),
  };

  const content = (
    <div className={styles.buttonContent}>
      <div
        className={classnames(
          styles.primaryContent,
          iconPosition === PRIMARY_BUTTON_V2_ICON_POSITION.Before && styles.reversed,
          contentClassName
        )}
      >
        {label && <span>{label}</span>}
        {icon && <Icon name={icon} className={styles.icon} />}
      </div>
      <div
        aria-hidden
        className={classnames(
          styles.secondaryContent,
          iconPosition === PRIMARY_BUTTON_V2_ICON_POSITION.Before && styles.reversed,
          contentClassName
        )}
      >
        {label && <span className={styles.label}>{label}</span>}
        {icon && <Icon name={icon} className={styles.icon} />}
      </div>
    </div>
  );

  if (link) {
    // TODO: Refactor this into a "SmartLink" wrapper element which uses the correct native or react-router link
    const relativeLink = link.startsWith('/');
    const formattedLink = queryStrings ? `${link}?${new URLSearchParams(queryStrings).toString()}` : link;
    return !relativeLink ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        data-testid="primaryButton"
        href={formattedLink}
        {...commonProps}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {content}
      </a>
    ) : (
      <Link
        data-testid="primaryButton"
        to={formattedLink}
        {...commonProps}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {content}
      </Link>
    );
  }

  return (
    <button type="button" {...commonProps} {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}>
      {content}
    </button>
  );
};
