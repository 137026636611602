import classNames, { Argument } from 'classnames';
import { ReactElement } from 'react';

import { AsxImage, GridSvg } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './GridImage.module.scss';

interface GridImageProps {
  imageUrl: string;
  alt?: string;
  theme?: SECTION_THEME;
  className?: Argument;
}

export function GridImage({ imageUrl, alt, theme = SECTION_THEME.Light, className }: GridImageProps): ReactElement {
  return (
    <div className={classNames(styles.imagesContainer, className)}>
      <GridSvg theme={theme} />
      <AsxImage key={`${imageUrl}-${alt}`} src={imageUrl} alt={alt ?? ''} className={styles.image} />
    </div>
  );
}
