import { Resource } from '@amzn/coral_com-amazon-sssapinterface-model';

import CaseStudy from './casestudy.json';
import Collaborators from './collaborators.json';
import Data from './data.json';
import Guidance from './guidance.json';
import Methodology from './methodology.json';
import Playbook from './playbook.json';
import Policy from './policy.json';
import SecondaryHero from './secondaryhero.json';
import Tool from './tool.json';

function createResources(): { [resourceId: string]: Resource } {
  const caseStudyResources: { [resourceId: string]: Resource } = CaseStudy;
  const dataResources: { [resourceId: string]: Resource } = Data;
  const guidanceResources: { [resourceId: string]: Resource } = Guidance;
  const methodologyResources: { [resourceId: string]: Resource } = Methodology;
  const collaboratorsResources: { [resourceId: string]: Resource } = Collaborators;
  const playbookResources: { [resourceId: string]: Resource } = Playbook;
  const policyResources: { [resourceId: string]: Resource } = Policy;
  const secondaryHeroResources: { [resourceId: string]: Resource } = SecondaryHero;
  const toolResources: { [resourceId: string]: Resource } = Tool;
  return {
    ...caseStudyResources,
    ...dataResources,
    ...guidanceResources,
    ...methodologyResources,
    ...collaboratorsResources,
    ...policyResources,
    ...playbookResources,
    ...secondaryHeroResources,
    ...toolResources,
  };
}

const AllResources: { [resourceId: string]: Resource } = createResources();

export default AllResources;
