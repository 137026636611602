import { useBundle } from '@amzn/react-arb-tools';

import { DATA_INITIATIVE_PAGE_BUNDLE_NAME } from 'src/constants';

export const useDataInitiativeSections = () => {
  const [dataInitiativeBundle, isDataInitiativeBundleLoading] = useBundle(DATA_INITIATIVE_PAGE_BUNDLE_NAME);
  const isBundleLoading = isDataInitiativeBundleLoading;
  return {
    isBundleLoading,
    dataInitiativeBundle,
  };
};
