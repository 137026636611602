import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { HEADING_SIZE, Heading, PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms';
import { formatResourceTypeForPill } from 'src/helpers/resource-helpers';
import { t } from 'src/helpers/translation-helpers';

import styles from './SearchResult.module.scss';

interface SearchResultProps {
  documentTitle: string;
  sourceURI?: string;
  resultType?: string;
  description: string;
  translationBundleLabel: string;
}

interface ResultBoxProps {
  tag?: string;
  title: string;
  content: string;
  link?: string;
  translationBundleLabel: string;
}

const ResultBox: FunctionComponent<ResultBoxProps> = ({ tag, title, content, link, translationBundleLabel }) => {
  const [translationBundle] = useBundle(translationBundleLabel);

  if (!content || !title) return null;

  return (
    translationBundle && (
      <div className={styles.resultBoxContainer}>
        <div className={styles.contentContainer}>
          {tag && <div className={styles.tagSlot}>{formatResourceTypeForPill(tag)}</div>}
          <Heading as="h5" size={HEADING_SIZE.h5} className={styles.title}>
            {title}
          </Heading>
          <p className={styles.content}>{t(translationBundle, content)}</p>
        </div>
        <div className={styles.linkContainer}>
          {link && (
            <div role="link">
              <PrimaryButton
                target="_self"
                link={link}
                icon="chevronRight"
                buttonSize={PRIMARY_BUTTON_SIZE.Regular}
                className={styles.link}
              />
            </div>
          )}
        </div>
      </div>
    )
  );
};

export const SearchResult: FunctionComponent<SearchResultProps> = ({
  documentTitle,
  description,
  resultType,
  sourceURI,
  translationBundleLabel,
}) => (
  <ResultBox
    tag={resultType}
    title={documentTitle}
    content={description}
    link={sourceURI}
    translationBundleLabel={translationBundleLabel}
  />
);
