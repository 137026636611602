import classNames from 'classnames';
import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { KatProgress } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';

import { BackButton } from 'src/components/atoms';
import { PageSection } from 'src/components/common';
import { ScrollToTopButton } from 'src/components/pages/search/ScrollToTopButton';
import { SearchBar } from 'src/components/pages/search/SearchBar';
import { SearchResults } from 'src/components/pages/search/SearchResults';
import { useSearch } from 'src/components/pages/search/hooks/use-search';
import { LOADING_ARIA_LABEL, SEARCH_RESULTS_PAGE_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './SearchResultsPage.module.scss';

export const SearchResultsPage: FunctionComponent = () => {
  const [query, setQuery] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchBundle] = useBundle(SEARCH_RESULTS_PAGE_BUNDLE_NAME);
  const { commonBundle } = useCommonStrings();
  const inputRef = useRef<HTMLInputElement>(null);
  const { isSmallDesktop } = useDeviceSize();

  const { searchResults, isLoading, hasError, sanitizedQuery } = useSearch(searchParams);

  useEffect(() => {
    const queryString = searchParams.get('q') ?? '';
    setQuery(queryString.trim());
  }, [searchParams]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    inputRef.current?.blur();
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set('q', query);
    const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;
    if (searchParams.toString() === newSearchParams.toString()) {
      window.location.reload(); // force re-render on error
    } else {
      window.history.pushState({ searchParams: newSearchParams.toString() }, '', newUrl);
      setSearchParams(newSearchParams);
    }
  };

  const handleSearchInput = (event: any) => {
    setQuery(event.target.value);
  };

  const handleClearClick = () => {
    setQuery('');
    inputRef.current?.focus();
  };

  // Depending on the status of the search the back button moves to different places,
  // for that reason it's easier to just render it somewhere else instead of trying
  // to fix it's positioning with CSS

  // If we're on desktop, if we haven't searched anything or if we have no results for our search, we want it at the top
  const renderBackButtonAtTheTop = isSmallDesktop || !searchResults || searchResults?.length === 0;

  // If we're on mobile AND we have searched something AND that search has provided results, we want it next to the search input
  const renderBackButtonNextToSearchInput = !isSmallDesktop && searchResults && searchResults?.length > 0;

  return (
    <>
      <PageSection
        theme="grey-secondary"
        className={classNames(styles.searchResultsWrapper, !searchResults && styles.noResults)}
      >
        {renderBackButtonAtTheTop && <BackButton variant="iconOnlyMobile" />}
        <div
          className={classNames(
            styles.searchBarWrapper,
            searchResults && styles.hasResults,
            searchResults?.length === 0 && styles.noResults
          )}
        >
          {renderBackButtonNextToSearchInput && (
            <BackButton variant="iconOnlyMobile" className={styles.collapsedBackButton} />
          )}
          <SearchBar
            query={query}
            searchBundle={searchBundle}
            inputRef={inputRef}
            handleSubmit={handleSubmit}
            handleSearchInput={handleSearchInput}
            handleClearClick={handleClearClick}
            searchParams={searchParams}
            searchResults={searchResults}
            hasError={hasError}
          />
        </div>
        {isLoading && (
          <PageSection>
            <div role="status">
              <KatProgress
                katAriaLabel={t(commonBundle, LOADING_ARIA_LABEL)}
                indeterminate
                size="medium"
                type="circular"
              />
            </div>
          </PageSection>
        )}
      </PageSection>
      {searchResults && !isLoading && (
        <>
          <PageSection theme="grey-secondary">
            <SearchResults
              searchParams={searchParams}
              searchResults={searchResults}
              hasError={hasError}
              searchBundle={searchBundle}
              sanitizedQuery={sanitizedQuery}
            />
          </PageSection>
          <ScrollToTopButton />
        </>
      )}
    </>
  );
};
