import { useMemo } from 'react';

import { Page, SectionType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { useBundle } from '@amzn/react-arb-tools';

import {
  GUIDANCE_RESOURCE_BUNDLE_NAME,
  TOOL_RESOURCE_BUNDLE_NAME,
  DATA_RESOURCE_BUNDLE_NAME,
  MEASUREMENT_PAGE_BUNDLE_NAME,
  COMMON_BUNDLE_NAME,
} from 'src/constants';
import AllResources from 'src/fixtures/structures/resources';
import { getSectionsByType } from 'src/helpers';

export const useMeasurementSections = (page?: Page) => {
  const [commonBundle, isCommonBundleLoading] = useBundle(COMMON_BUNDLE_NAME);
  const [toolResourcesBundle, isToolResourcesBundleLoading] = useBundle(TOOL_RESOURCE_BUNDLE_NAME);
  const [measurementResourcesBundle, isMeasurementResourcesBundleLoading] = useBundle(MEASUREMENT_PAGE_BUNDLE_NAME);
  const [dataResourcesBundle, isLoadingData] = useBundle(DATA_RESOURCE_BUNDLE_NAME);
  const [guidanceResourcesBundle, isLoadingGuidance] = useBundle(GUIDANCE_RESOURCE_BUNDLE_NAME);

  return useMemo(() => {
    if (!page) return {};

    // TOOLS SECTION
    const [toolSection] = getSectionsByType(page.sections, SectionType.MEASUREMENT_TOOLS);
    const toolResources = toolSection ? toolSection.resources.map((id) => AllResources[id]) : [];

    // GUIDANCE SECTION
    const [guidanceSection] = getSectionsByType(page.sections, SectionType.GUIDANCE);
    const guidanceResources = guidanceSection ? guidanceSection.resources.map((id) => AllResources[id]) : [];

    // DATA SECTION
    const [dataSection] = getSectionsByType(page.sections, SectionType.DATA);
    const dataResources = dataSection ? dataSection.resources.map((id) => AllResources[id]) : [];

    const isBundleLoading =
      isCommonBundleLoading ||
      isLoadingData ||
      isLoadingGuidance ||
      isMeasurementResourcesBundleLoading ||
      isToolResourcesBundleLoading;

    return {
      isBundleLoading,
      commonBundle,
      dataSection,
      dataResources,
      dataResourcesBundle,
      measurementResourcesBundle,
      guidanceSection,
      guidanceResources,
      guidanceResourcesBundle,
      toolSection,
      toolResources,
      toolResourcesBundle,
    };
  }, [
    page,
    isCommonBundleLoading,
    isLoadingData,
    isLoadingGuidance,
    isMeasurementResourcesBundleLoading,
    isToolResourcesBundleLoading,
    toolResourcesBundle,
    commonBundle,
    dataResourcesBundle,
    guidanceResourcesBundle,
    measurementResourcesBundle,
  ]);
};
