import { useMemo } from 'react';

import { Page, ResourceType, SectionType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { useBundle } from '@amzn/react-arb-tools';

import {
  CASE_STUDY_RESOURCE_BUNDLE_NAME,
  POLICY_RESOURCE_BUNDLE_NAME,
  PLAYBOOK_RESOURCE_BUNDLE_NAME,
  SECONDARY_HERO_RESOURCE_BUNDLE_NAME,
  GUIDANCE_RESOURCE_BUNDLE_NAME,
  METHODOLOGY_RESOURCE_BUNDLE_NAME,
  TOOL_RESOURCE_BUNDLE_NAME,
  COMMON_BUNDLE_NAME,
} from 'src/constants';
import AllResources from 'src/fixtures/structures/resources';
import {
  t,
  extractAssetBySectionAndDisplay,
  getResourcesBySectionType,
  getSectionsByType,
  getDisplayType,
  extractAssetDisplayType,
} from 'src/helpers';
import { formatUrl } from 'src/helpers/formatting-helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

export const useResourcePageSections = (page?: Page) => {
  const [commonBundle, isCommonBundleLoading] = useBundle(COMMON_BUNDLE_NAME);
  const [secondaryHeroResourceBundle, isSecondaryHeroResourceBundleLoading] = useBundle(
    SECONDARY_HERO_RESOURCE_BUNDLE_NAME
  );
  const [policyResourceBundle, isPolicyResourceBundleLoading] = useBundle(POLICY_RESOURCE_BUNDLE_NAME);
  const [playBookResourceBundle, isPlayBookResourceBundleLoading] = useBundle(PLAYBOOK_RESOURCE_BUNDLE_NAME);
  const [caseStudiesResourceBundle, isCaseStudiesResourceBundleLoading] = useBundle(CASE_STUDY_RESOURCE_BUNDLE_NAME);
  const [additionalResourcesBundle, isAdditionalResourcesBundleLoading] = useBundle(GUIDANCE_RESOURCE_BUNDLE_NAME);
  const [methodologyResourcesBundle, isMethodologyResourcesBundleLoading] = useBundle(METHODOLOGY_RESOURCE_BUNDLE_NAME);
  const [toolResourcesBundle, isToolResourcesBundleLoading] = useBundle(TOOL_RESOURCE_BUNDLE_NAME);

  const { isSmallDesktop } = useDeviceSize();

  return useMemo(() => {
    if (!page) return {};

    const displayType = getDisplayType(isSmallDesktop);

    // SECONDARY HERO SECTION
    const [secondaryHeroSection] = getSectionsByType(page.sections, SectionType.SECONDARY_HERO);
    const secondaryHeroResource =
      secondaryHeroSection && getResourcesBySectionType(page.sections, SectionType.SECONDARY_HERO)[0];
    const secondaryHeroAsset =
      secondaryHeroSection && extractAssetBySectionAndDisplay(page.sections, SectionType.SECONDARY_HERO, displayType);

    const secondaryHeroImageAlt = secondaryHeroResource.assets.primaryAsset[0].altText;
    const secondaryHeroImage = formatUrl(secondaryHeroAsset);

    // PLAYBOOK SECTION
    const [playbookSection] = getSectionsByType(page.sections, SectionType.PLAYBOOK);
    const playbookResource = playbookSection && getResourcesBySectionType(page.sections, SectionType.PLAYBOOK)[0];
    const playbookPrimaryAsset =
      playbookSection && extractAssetBySectionAndDisplay(page.sections, SectionType.PLAYBOOK, displayType);

    const playbookPdf = formatUrl(playbookPrimaryAsset);
    const playbookSecondaryAssets = playbookResource.assets.secondaryAssets?.thumbnail;
    const playbookImage =
      playbookSecondaryAssets &&
      extractAssetDisplayType(playbookSecondaryAssets, displayType) &&
      formatUrl(extractAssetDisplayType(playbookSecondaryAssets, displayType)?.assetUrl);
    const fallbackPlaybookAltText = t(commonBundle, 'playbook_fallbackImageAlt') ?? '';
    const playbookImageAlt =
      playbookSecondaryAssets && playbookSecondaryAssets[0].altText
        ? playbookSecondaryAssets[0].altText
        : fallbackPlaybookAltText;

    const playbookAccordions = playbookResource.display?.playbookAccordion;

    // CASE STUDIES SECTION
    const [caseStudiesSection] = getSectionsByType(page.sections, SectionType.CASE_STUDIES);
    const caseStudiesResources = caseStudiesSection ? caseStudiesSection.resources.map((id) => AllResources[id]) : [];

    // ADDITIONAL RESOURCES SECTION
    const [additionalResSection] = getSectionsByType(page.sections, SectionType.ADDITIONAL_RESOURCES);
    const additionalResResources = additionalResSection.resources.map((id) => AllResources[id]).filter(Boolean);

    const isBundleLoading =
      isCommonBundleLoading ||
      isSecondaryHeroResourceBundleLoading ||
      isPolicyResourceBundleLoading ||
      isPlayBookResourceBundleLoading ||
      isCaseStudiesResourceBundleLoading ||
      isAdditionalResourcesBundleLoading ||
      isMethodologyResourcesBundleLoading ||
      isToolResourcesBundleLoading;

    const bundleByResourceType: Record<string, any> = {
      [ResourceType.GUIDANCE]: additionalResourcesBundle,
      [ResourceType.TOOL]: toolResourcesBundle,
      [ResourceType.POLICY]: policyResourceBundle,
      [ResourceType.PLAYBOOK]: playBookResourceBundle,
      [ResourceType.METHODOLOGY]: methodologyResourcesBundle,
    };

    return {
      isBundleLoading,
      commonBundle,
      secondaryHeroSection,
      secondaryHeroResourceBundle,
      secondaryHeroResource,
      secondaryHeroImage,
      secondaryHeroImageAlt,
      playbookSection,
      playbookResource,
      playBookResourceBundle,
      playbookPdf,
      playbookImage,
      playbookImageAlt,
      playbookAccordions,
      caseStudiesSection,
      caseStudiesResources,
      caseStudiesResourceBundle,
      additionalResSection,
      additionalResResources,
      bundleByResourceType,
      formatUrl,
    };
  }, [
    page,
    isSmallDesktop,
    commonBundle,
    isCommonBundleLoading,
    isSecondaryHeroResourceBundleLoading,
    isPolicyResourceBundleLoading,
    isPlayBookResourceBundleLoading,
    isCaseStudiesResourceBundleLoading,
    isAdditionalResourcesBundleLoading,
    isMethodologyResourcesBundleLoading,
    isToolResourcesBundleLoading,
    additionalResourcesBundle,
    toolResourcesBundle,
    policyResourceBundle,
    playBookResourceBundle,
    methodologyResourcesBundle,
    secondaryHeroResourceBundle,
    caseStudiesResourceBundle,
  ]);
};
