import { FunctionComponent } from 'react';

import { CardV2, CardV2Props } from 'src/components/common';

import styles from './GridV2.module.scss';

type GridV2Props = {
  cards: Array<CardV2Props>;
};

export const GridV2: FunctionComponent<GridV2Props> = ({ cards }: GridV2Props) => (
  <div className={styles.grid}>
    {cards.map((card, index) => {
      const key = `${card.title}-${index}`;
      return <CardV2 key={key} {...card} />;
    })}
  </div>
);
