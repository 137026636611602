import classNames, { type Argument } from 'classnames';
import { FunctionComponent } from 'react';

import styles from './Divider.module.scss';

type DividerProps = {
  className?: Argument;
};

export const Divider: FunctionComponent<DividerProps> = ({ className, ...props }) => (
  <div className={styles.boundedContainer} data-testid="divider">
    <div className={classNames(className, styles.horizontalDivider)} {...props} />
  </div>
);
