import { Page } from '@amzn/coral_com-amazon-sssapinterface-model';

import FocusArea from './focusarea.json';
import Measurement from './measurement.json';

const Pages: { [resourceId: string]: Page } = {
  ...FocusArea,
  ...Measurement,
};

const FocusAreaPage: { [resourceId: string]: Page } = FocusArea;

export { Pages, FocusAreaPage };
