import { Component, ErrorInfo, ReactNode } from 'react';

import { ErrorFallback } from 'src/components/pages/error/ErrorFallback';
import { logError, emitCountMetric } from 'src/logger';
import { ERROR_BOUNDARY_ENCOUNTER } from 'src/logging-helpers/metrics-constants';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, errorInfo?: ErrorInfo) {
    emitCountMetric(ERROR_BOUNDARY_ENCOUNTER, 1);
    logError('ErrorBoundary', 'ErrorBoundaryEncounter', error, errorInfo);
    this.setState({ hasError: true });
  }

  render(): ReactNode {
    const { hasError } = this.state;
    const { children, fallback } = this.props;
    if (hasError) {
      return fallback ?? <ErrorFallback />;
    }

    return children;
  }
}
