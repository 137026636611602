/* istanbul ignore file */
import translations from 'translations';
import arbManifest from 'translations/arbManifest';

import { LocalizationContextBuilder, MessageBundle, LocalizationContext } from '@amzn/arb-tools';

import { DEFAULT_LOCALE } from 'src/constants';
import { emitCountMetric, logError } from 'src/logger';
import { TRANSLATION_STRING_FAILURE, STRING_ID_DIMENSION } from 'src/logging-helpers/metrics-constants';

const LOCALIZATION_CONTEXTS = new Map<string, LocalizationContext>();

export function getLocalizationContext(locale: string) {
  let lc = LOCALIZATION_CONTEXTS.get(locale);

  if (!lc) {
    lc = new LocalizationContextBuilder().withLocale(locale).withDefaultLocale(DEFAULT_LOCALE).build();

    LOCALIZATION_CONTEXTS.set(locale, lc);
  }

  return lc;
}

export function t(
  bundle: MessageBundle,
  stringId?: string,
  args?: Record<string, unknown>,
  fallBackString: string = ''
) {
  if (!bundle || !stringId) {
    return fallBackString;
  }
  try {
    if (!args) {
      return bundle.getMessage(stringId);
    }

    return bundle.formatMessage(stringId, args);
  } catch (err: any) {
    logError('translation-helpers#t', `Error in t function for stringId: [${stringId}]`, err);
    emitCountMetric(TRANSLATION_STRING_FAILURE, 1, { [STRING_ID_DIMENSION]: stringId });
    return fallBackString;
  }
}

export function resolveBundleUrlWithFallbacks(resourcePackPath: string | undefined, locale: string, bundle: string) {
  if (resourcePackPath && translations[resourcePackPath]) {
    return translations[resourcePackPath];
  }

  if (!resourcePackPath && arbManifest.endpointMap?.[locale]?.[bundle]) {
    return translations[arbManifest.endpointMap[locale][bundle]];
  }

  if (arbManifest.endpointMap?.[DEFAULT_LOCALE]?.[bundle]) {
    return translations[arbManifest.endpointMap[DEFAULT_LOCALE][bundle]];
  }

  return undefined;
}

export function getMbmOptions(manifest: { endpointMap: Record<string, Record<string, string>> }) {
  let currentLocale = DEFAULT_LOCALE;
  const documentLocale = document?.querySelector('html')!.getAttribute('lang');

  if (documentLocale) {
    currentLocale = documentLocale;
  }

  const localizationContext = getLocalizationContext(currentLocale);

  return {
    arbManifest: manifest,
    defaultLocalizationContext: localizationContext,
    resolveBundleUrl: (resourcePackPath: string | undefined, locale: string, bundle: string) =>
      resolveBundleUrlWithFallbacks(resourcePackPath, locale, bundle),
  };
}
