import { html, property } from 'lit-element';
import MultiClamp from 'multi-clamp';
import { KatLitElement, register } from '../../shared/base';

/**
 * @component {kat-carousel-image} CarouselImage The image that goes inside a carousel. The aspect ratio of the image is
 * maintained and it will be scaled up or down to match the carousel.
 */
@register('kat-carousel-image')
export class KatCarouselImage extends KatLitElement {
  /** Defines the URL path to the image file. */
  @property()
  src = '';

  /**
   * Defines how the image fills the carousel. "contain" means that the image will not be clipped but will scale to fit inside of
   * the width or height of the carousel. For images of a different aspect ratio this will mean the background will show on the top or sides. "cover" means that the
   * image will be clipped to match the carousel's aspect ratio. The image will always be centered.
   * @enum {value} contain
   * @enum {value} cover
   */
  @property({ attribute: 'image-fit' })
  imageFit = 'contain';

  /** The label to be displayed for this image. */
  @property()
  label?: string;

  /** The URL path to have this image link to. The image and the label are both clickable links when this is set. */
  @property({ attribute: 'link-href' })
  linkHref = '';

  /** The target value of the link. Defaults to "_blank". See <a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a">MDN: The Anchor Element</a> */
  @property({ attribute: 'link-target' })
  linkTarget = '_blank';

  /** The alternative label to show only to screen readers. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel = '';

  private _clamp = null;

  // disable shadow-dom
  createRenderRoot() {
    return this;
  }

  firstUpdated() {
    // Disables display:none for a bit to let the image to preload so
    this.classList.add('kat-visually-hidden');

    window.requestAnimationFrame(() => {
      this.classList.remove('kat-visually-hidden');
    });

    this.addEventListener('click', () => {
      this.click();
    });
  }

  onCarouselResize() {
    this._clampLabel();
    this._detectLabelHeightOverflow();
  }

  getAriaLabel() {
    if (this.katAriaLabel !== '') {
      return this.katAriaLabel;
    }
    return this.label;
  }

  _clampLabel() {
    let label = this.getElementsByClassName('kat-carousel-label-text')[0];
    if (label?.children[0]?.tagName === 'A') {
      label = label.children[0];
    }

    if (label) {
      if (this._clamp) {
        label.innerText = this.label;
        this._clamp.reload();
      } else {
        this._clamp = new MultiClamp(label, {
          ellipsis: '...',
          clamp: 'auto',
        });
      }
    }
  }

  _detectLabelHeightOverflow() {
    const label = this.getElementsByClassName('kat-carousel-label')[0];
    if (!label) {
      return;
    }
    const inner = label.getElementsByClassName('kat-carousel-label-inner')[0];

    const boundingLabel = label.getBoundingClientRect();
    const boundingInner = inner.getBoundingClientRect();
    if (boundingInner.height > boundingLabel.height) {
      label.style.height = '100%';
    } else {
      label.style.removeProperty('height');
    }
  }

  click() {
    /* istanbul ignore else  */
    if (this.linkHref) {
      const link = this.getElementsByTagName('a')[0];
      /* istanbul ignore else  */
      if (link) {
        link.click();
      }
    }
  }

  renderLabel() {
    if (this.label) {
      if (this.linkHref) {
        return html`
          <a
            href=${this.linkHref}
            class="kat-carousel-label-text"
            tabindex="-1"
            target=${this.linkTarget}
            >${this.label}</a
          >
        `;
      }
      return html`<div class="kat-carousel-label-text">${this.label}</div>`;
    }

    return null;
  }

  render() {
    const style = `background-image: url(${this.src});`;
    const labelText = this.renderLabel();
    const label = labelText
      ? html`
          <div class="kat-carousel-label">
            <div class="kat-carousel-label-inner">${labelText}</div>
          </div>
        `
      : null;
    const hiddenLink =
      this.linkHref && !this.label
        ? html`
            <a
              href=${this.linkHref}
              class="kat-carousel-hide"
              tabindex="-1"
              target=${this.linkTarget}
            ></a>
          `
        : null;
    return html`
      <div class="kat-carousel-background-image" style=${style}></div>
      ${label} ${hiddenLink}
    `;
  }
}
