import classNames from 'classnames';
import { FunctionComponent, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './Tagline.module.scss';

interface TaglineProps extends PropsWithChildren, HTMLAttributes<HTMLSpanElement> {}

export const Tagline: FunctionComponent<TaglineProps> = ({ children, className }) => (
  <span className={classNames(className, styles.tagline)}>{children}</span>
);
