import classNames, { type Argument } from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import { icons, IconType } from 'src/components/atoms/icons/icons';

import styles from './Icon.module.scss';

export type IconProps = HTMLProps<HTMLDivElement> & {
  name: IconType;
  className?: Argument;
  /* Optional boolean to remove icon from accessibility tree */
  isAriaHidden?: boolean;
};

const isValidIconType = (name: any): name is IconType => Object.keys(icons).includes(name);

export const Icon: FunctionComponent<IconProps> = ({ name, className, isAriaHidden }) => {
  const SelectedIcon = icons[name];

  return (
    <div data-testid={isValidIconType(name) ? name : 'invalid-icon'} className={classNames(styles.icon, className)}>
      {SelectedIcon && <SelectedIcon aria-hidden={Boolean(isAriaHidden)} />}
    </div>
  );
};
