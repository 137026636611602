import classNames from 'classnames';
import { HTMLProps, type ReactElement } from 'react';

import { convertToSentenceCase } from 'src/helpers/formatting-helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './AnchorPill.module.scss';

type AnchorPillProps = HTMLProps<HTMLAnchorElement> & {
  copy: string;
  sectionId: string;
  isActive?: boolean;
};

export function AnchorPill({ copy, sectionId, isActive = false }: AnchorPillProps): ReactElement {
  const { isSmallDesktop } = useDeviceSize();

  let navbarHeight: number;

  if (isSmallDesktop) {
    navbarHeight = 96;
  } else {
    navbarHeight = 72;
  }

  const anchorLinksHeight = 96;
  const offsetHeight = navbarHeight + anchorLinksHeight;

  // Helper function to find the first focusable element inside the section
  const getFirstFocusableElement = (container: HTMLElement): HTMLElement | null => {
    const focusableSelectors = [
      'a[href]',
      'button:not([disabled])',
      'input:not([disabled])',
      'textarea:not([disabled])',
      'select:not([disabled])',
      '[tabindex]:not([tabindex="-1"])',
    ];
    const focusableElements = container.querySelectorAll(focusableSelectors.join(', '));
    return focusableElements.length ? (focusableElements[0] as HTMLElement) : null;
  };

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    // Remove focus from the currently focused element
    const currentlyFocused = document.activeElement as HTMLElement;
    currentlyFocused.blur(); // Removes focus from the currently focused anchor pill
    const targetSection = document.querySelector(sectionId) as HTMLElement | null;

    if (targetSection) {
      const top = isSmallDesktop
        ? window.scrollY + targetSection.getBoundingClientRect().top - navbarHeight
        : window.scrollY + targetSection.getBoundingClientRect().top - offsetHeight;

      window.scrollTo({ behavior: 'smooth', top });

      // Detect if the user presses 'Tab'
      const handleTabPress = (keydownEvent: KeyboardEvent) => {
        if (keydownEvent.key === 'Tab') {
          keydownEvent.preventDefault(); // Prevent the default tabbing behavior

          const firstFocusableElement = getFirstFocusableElement(targetSection);
          if (firstFocusableElement) {
            firstFocusableElement.focus();
          }

          // Clean up the event listener after focusing
          window.removeEventListener('keydown', handleTabPress);
        }
      };

      // Add keydown event listener to detect 'Tab' press
      window.addEventListener('keydown', handleTabPress);
    }
  };

  return (
    <a
      className={classNames(styles.anchorPill, isActive && styles.active)}
      href={sectionId}
      onClick={handleClick}
      aria-current={isActive}
    >
      {convertToSentenceCase(copy)}
    </a>
  );
}
