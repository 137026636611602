import classNames from 'classnames';
import { type ReactElement, useCallback, useEffect, useRef, useState } from 'react';

import { FormattedLink, Icon } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './AccordionWithIndex.module.scss';

export type AccordionWithIndexProps = {
  index?: number;
  theme?: SECTION_THEME;
  title: string;
  content: string;
  links?: Array<{
    text: string;
    link: string;
  }>;
};

export type AccordionWithIndexComponentProps = AccordionWithIndexProps & {
  showIndexDecoration?: boolean;
  isOpen: boolean;
  onClick(): void;
  dataTestId?: string;
};

export function AccordionWithIndex({
  index,
  title,
  content,
  links,
  showIndexDecoration,
  isOpen,
  theme = SECTION_THEME.Light,
  onClick,
  dataTestId,
}: AccordionWithIndexComponentProps): ReactElement {
  const [contentHeight, setContentHeight] = useState<number | string>(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const closedAccordionHeight = 54;

  useEffect(() => {
    const updateContentHeight = () => {
      if (isOpen) {
        setContentHeight('auto');
        window.requestAnimationFrame(() => contentRef.current && setContentHeight(contentRef.current.scrollHeight));
      } else {
        setContentHeight(closedAccordionHeight);
      }
    };

    updateContentHeight();
    window.addEventListener('resize', updateContentHeight);

    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, [isOpen, contentRef]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        onClick();
      }
    },
    [onClick]
  );

  const formattedIndex = index !== undefined ? `[${String(index + 1).padStart(2, '0')}]` : '';

  return (
    <div className={classNames(styles.accordionWithIndex, styles[theme])}>
      <div
        className={styles.accordionHeader}
        onClick={onClick}
        onKeyDown={onKeyDown}
        role="button"
        tabIndex={0}
        aria-expanded={isOpen}
      >
        {index !== undefined && (
          <p className={classNames(styles.accordionIndex, !isOpen && showIndexDecoration && styles.indexDecoration)}>
            {formattedIndex}
          </p>
        )}
        <button
          type="button"
          className={classNames(styles.accordionToggle, styles[isOpen ? 'open' : 'closed'])}
          aria-label={isOpen ? 'Collapse' : 'Expand'}
          data-testid={dataTestId}
        >
          <Icon name={isOpen ? 'minus' : 'plus'} className={styles.accordionIcon} />
        </button>
        <div
          className={styles.accordionTextContainer}
          ref={contentRef}
          style={{ height: contentHeight }}
          aria-hidden={!isOpen}
        >
          <h3 className={classNames(styles.accordionTitle, styles[isOpen ? 'open' : 'closed'])}>{title}</h3>

          <p className={styles.accordionContent}>{content}</p>
          <div className={styles.accordionLinksWrapper}>
            {links &&
              links.map((link, linkIndex) => {
                const key = `${link.text}-${linkIndex}`;
                return <FormattedLink className={styles.externalLink} key={key} {...link} bold openNewTab />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
