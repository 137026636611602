import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';

import { MessageBundle } from '@amzn/arb-tools';
import {
  AsxFormContentType,
  AsxFormTopic,
  AsxFormType,
  RelationToAmazon,
} from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { useBundle } from '@amzn/react-arb-tools';

import {
  PRIMARY_BUTTON_SIZE,
  PrimaryButton,
  TextInput,
  Picker,
  TextArea,
  DropdownInput,
  Icon,
  AsxMarkdownContent,
} from 'src/components/atoms';
import { CheckboxDescriptionGroup, CheckboxPillGroup, GridImage } from 'src/components/common';
import {
  asxFormContentTypeTranslationMap,
  asxFormTopicTranslationMap,
  asxFormTypeTranslationMap,
  relationToAmazonTranslationMap,
  relationToAmazonDescriptionTranslationMap,
} from 'src/components/pages/contact/form-helpers';
import { COMMON_BUNDLE_NAME, ContactFormStrings } from 'src/constants';
import { addRequiredLabelSymbol, t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import generalFormImage from 'src/images/fallback/fallback-image-3.png';
import contentFormImage from 'src/images/list-component/listComponent01.jpeg';

import styles from './ContactForm.module.scss';

export enum CONTACT_FORM_KEYS {
  NAME = 'name',
  EMAIL = 'email',
  WEBSITE = 'website',
  JOB_TITLE = 'jobTitle',
  COMPANY = 'company',
  COMPANY_SIZE = 'companySize',
  RELATIONS_TO_AMAZON = 'relationsToAmazon',
  ADDITIONAL_RELATIONS = 'additionalRelations',
  DESCRIPTION = 'description',
  FORM_TYPE = 'formType',
  FORM_TOPICS = 'formTopics',
  ADDITIONAL_TOPICS = 'additionalTopics',
  CONTENT_TYPES = 'contentTypes',
  ADDITIONAL_CONTENT_TYPES = 'additionalContentTypes',
}

export type ContactFormValues = {
  [CONTACT_FORM_KEYS.NAME]: string;
  [CONTACT_FORM_KEYS.EMAIL]: string;
  [CONTACT_FORM_KEYS.WEBSITE]: string;
  [CONTACT_FORM_KEYS.JOB_TITLE]: string;
  [CONTACT_FORM_KEYS.COMPANY]: string;
  [CONTACT_FORM_KEYS.COMPANY_SIZE]: string;
  [CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON]: RelationToAmazon[];
  [CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS]?: string;
  [CONTACT_FORM_KEYS.DESCRIPTION]: string;
  [CONTACT_FORM_KEYS.FORM_TYPE]: AsxFormType;
  [CONTACT_FORM_KEYS.FORM_TOPICS]: AsxFormTopic[];
  [CONTACT_FORM_KEYS.ADDITIONAL_TOPICS]?: string;
  [CONTACT_FORM_KEYS.CONTENT_TYPES]?: AsxFormContentType[];
  [CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES]?: string;
};

export interface ContactFormProps {
  contactBundle: MessageBundle;
  initialFormValues: Partial<ContactFormValues>;
  handleFormSubmit: (formValues: ContactFormValues) => void;
  handleFormTypeChange: (formType: string) => void;
  selectedFormType: AsxFormType;
}

const formTypeTopicMap: Record<AsxFormType, string> = {
  [AsxFormType.GENERAL_FEEDBACK]: ContactFormStrings.FEEDBACK_TOPIC_LABEL,
  [AsxFormType.GENERAL_CONTENT_INTAKE]: ContactFormStrings.CONTENT_TOPIC_LABEL,
};

const formTypeHeaderMap: Record<AsxFormType, string> = {
  [AsxFormType.GENERAL_FEEDBACK]: ContactFormStrings.FEEDBACK_HEADER,
  [AsxFormType.GENERAL_CONTENT_INTAKE]: ContactFormStrings.CONTENT_HEADER,
};

const formTypeDescriptionMap: Record<AsxFormType, string> = {
  [AsxFormType.GENERAL_FEEDBACK]: ContactFormStrings.FEEDBACK_DESCRIPTION_LABEL,
  [AsxFormType.GENERAL_CONTENT_INTAKE]: ContactFormStrings.CONTENT_DESCRIPTION_LABEL,
};

const formTypeDescriptionPlaceholderMap: Record<AsxFormType, string> = {
  [AsxFormType.GENERAL_FEEDBACK]: ContactFormStrings.FEEDBACK_DESCRIPTION_PLACEHOLDER,
  [AsxFormType.GENERAL_CONTENT_INTAKE]: ContactFormStrings.CONTENT_DESCRIPTION_PLACEHOLDER,
};

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const shortInputMaxLength = 300;
const longInputMaxLength = 1000;

// TODO fix tests
export const ContactForm: FunctionComponent<ContactFormProps> = ({
  contactBundle,
  initialFormValues,
  handleFormSubmit,
  handleFormTypeChange,
  selectedFormType,
}) => {
  const {
    control,
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    getValues,
    setValue,
  } = useForm<ContactFormValues>({
    reValidateMode: 'onSubmit',
    defaultValues: { ...initialFormValues },
  });

  const watchFormType = watch(CONTACT_FORM_KEYS.FORM_TYPE);
  const watchRelationOther = watch(CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON)?.includes(RelationToAmazon.OTHER);
  const watchContentTypeOther = watch(CONTACT_FORM_KEYS.CONTENT_TYPES)?.includes(AsxFormContentType.OTHER);
  const watchTopicOther = watch(CONTACT_FORM_KEYS.FORM_TOPICS)?.includes(AsxFormTopic.OTHER);

  useEffect(() => {
    // Clear errors when the component mounts
    clearErrors();

    // Unregister additional fields when the 'other' option is deselected
    const handleAdditionalFields = () => {
      if (watchRelationOther) {
        register(CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS);
      } else if (!watchRelationOther) {
        unregister(CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS);
      }
      if (watchTopicOther) {
        register(CONTACT_FORM_KEYS.ADDITIONAL_TOPICS);
      } else if (!watchTopicOther) {
        unregister(CONTACT_FORM_KEYS.ADDITIONAL_TOPICS);
      }
      if (watchContentTypeOther) {
        register(CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES);
      } else if (!watchContentTypeOther) {
        unregister(CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES);
      }
    };

    // Call the handleAdditionalFields function whenever the watched values change
    handleAdditionalFields();

    // Cleanup function to handle the additional fields when the component unmounts
    return () => handleAdditionalFields();
  }, [clearErrors, watchRelationOther, watchTopicOther, watchContentTypeOther, register, unregister]);

  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const companySizeRanges = [
    { label: `0 ${t(commonBundle, 'picker_employees')}` },
    { label: `1-100 ${t(commonBundle, 'picker_employees')}` },
    { label: `101-200 ${t(commonBundle, 'picker_employees')}` },
    { label: `201-500 ${t(commonBundle, 'picker_employees')}` },
    { label: `501-1,000 ${t(commonBundle, 'picker_employees')}` },
    { label: `1,001-2,000 ${t(commonBundle, 'picker_employees')}` },
    { label: `2,001-5,000 ${t(commonBundle, 'picker_employees')}` },
    { label: `5,001-10,000 ${t(commonBundle, 'picker_employees')}` },
    { label: `10,001+ ${t(commonBundle, 'picker_employees')}` },
  ];

  const errorsPresentRef = useRef<HTMLDivElement>(null);
  const formHasErrors = Object.keys(errors).length > 0;
  const { isSmallDesktop } = useDeviceSize();

  useEffect(() => {
    if (formHasErrors && errorsPresentRef.current) {
      // We want to offset our scroll by 120 on desktop and 96 on mobile to avoid our errors present element being
      // hidden behind the navbar
      const navbarOffset = isSmallDesktop ? 120 : 96;
      const topOffset = errorsPresentRef.current.getBoundingClientRect().top + window.scrollY - navbarOffset;
      window.scrollTo({ behavior: 'smooth', top: topOffset });
    }
  }, [formHasErrors, isSmallDesktop]);

  const validateEmptyTextInput = (inputValue: string) =>
    !!inputValue.trim() || t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL);

  const [formWidth, setFormWidth] = useState<number>();

  const getFormWidth = () => {
    const formElement = document.querySelector('form');
    if (formElement) {
      setFormWidth(formElement.getBoundingClientRect().width);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      getFormWidth();
    };

    getFormWidth();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      <div className={styles.contactFormWrapper}>
        <div className={styles.contactFormImageContainer} data-testid="formImageContainer">
          <h2 className={styles.contactFormHeading}>{t(contactBundle, 'form_heading')}</h2>
          <GridImage
            imageUrl={selectedFormType === AsxFormType.GENERAL_CONTENT_INTAKE ? contentFormImage : generalFormImage}
            alt={t(contactBundle, 'generalFeedbackImage_alt')}
          />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(handleFormSubmit)} id="contactForm" data-testid="contactForm">
          <div>
            <p className={styles.formParagraph}>{t(contactBundle, ContactFormStrings.MISSION_HEADER)}</p>
          </div>

          <div className={styles.formDropdownContainer}>
            <DropdownInput
              label={t(contactBundle, ContactFormStrings.FORM_TYPE_LABEL)}
              name={CONTACT_FORM_KEYS.FORM_TYPE}
              control={control}
              options={Object.values(AsxFormType).map((formType) => ({
                value: formType,
                label: t(contactBundle, asxFormTypeTranslationMap[formType], undefined, formType),
              }))}
              onChange={(selectedOption) => {
                handleFormTypeChange(selectedOption);
                clearErrors();
              }}
            />
          </div>

          {formHasErrors && (
            <div className={styles.formErrorsPresentWrapper} ref={errorsPresentRef}>
              <Icon name="error" className={styles.formErrorsPresentIcon} />
              <p className={styles.formErrorsPresent}>
                {t(contactBundle, ContactFormStrings.ERRORS_PRESENT_DESCRIPTION)}
              </p>
            </div>
          )}

          <div>
            <p className={styles.formRequiredFields}>{t(contactBundle, ContactFormStrings.REQUIRED_FIELDS_LABEL)}</p>
          </div>

          <div>
            <h4 className={styles.formSectionHeader}>
              {t(contactBundle, ContactFormStrings.PERSONAL_INFORMATION_HEADER)}
            </h4>
          </div>

          <div className={styles.formInputWrapper}>
            <TextInput
              label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.NAME_PLACEHOLDER_LABEL))}
              inputType="text"
              id={CONTACT_FORM_KEYS.NAME}
              value={getValues(CONTACT_FORM_KEYS.NAME) ?? ''}
              {...register(CONTACT_FORM_KEYS.NAME, {
                required: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                maxLength: {
                  value: shortInputMaxLength,
                  message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                },
                validate: (inputValue) => validateEmptyTextInput(inputValue),
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const fieldValue = event.target.value;
                setValue(CONTACT_FORM_KEYS.NAME, fieldValue);
                clearErrors(CONTACT_FORM_KEYS.NAME);
              }}
              errors={errors[CONTACT_FORM_KEYS.NAME]}
              iconName="profile"
            />
          </div>

          <div className={styles.formInputWrapper}>
            <TextInput
              label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.EMAIL_PLACEHOLDER_LABEL))}
              inputType="text"
              id={CONTACT_FORM_KEYS.EMAIL}
              value={getValues(CONTACT_FORM_KEYS.EMAIL) ?? ''}
              {...register(CONTACT_FORM_KEYS.EMAIL, {
                required: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                maxLength: {
                  value: shortInputMaxLength,
                  message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                },
                pattern: {
                  value: emailRegex,
                  message: t(contactBundle, ContactFormStrings.INVALID_EMAIL_LABEL),
                },
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const fieldValue = event.target.value;
                setValue(CONTACT_FORM_KEYS.EMAIL, fieldValue);
                clearErrors(CONTACT_FORM_KEYS.EMAIL);
              }}
              errors={errors[CONTACT_FORM_KEYS.EMAIL]}
              iconName="mail"
            />
          </div>

          <div className={styles.formInputWrapper}>
            <TextInput
              label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.JOB_TITLE_LABEL))}
              inputType="text"
              id={CONTACT_FORM_KEYS.JOB_TITLE}
              value={getValues(CONTACT_FORM_KEYS.JOB_TITLE) ?? ''}
              {...register(CONTACT_FORM_KEYS.JOB_TITLE, {
                required: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                maxLength: {
                  value: shortInputMaxLength,
                  message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                },
                validate: (inputValue) => validateEmptyTextInput(inputValue),
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const fieldValue = event.target.value;
                setValue(CONTACT_FORM_KEYS.JOB_TITLE, fieldValue);
                clearErrors(CONTACT_FORM_KEYS.JOB_TITLE);
              }}
              errors={errors[CONTACT_FORM_KEYS.JOB_TITLE]}
              iconName="job"
            />
          </div>

          <div className={styles.formInputWrapper}>
            <TextInput
              label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.COMPANY_LABEL))}
              inputType="text"
              id={CONTACT_FORM_KEYS.COMPANY}
              value={getValues(CONTACT_FORM_KEYS.COMPANY) ?? ''}
              {...register(CONTACT_FORM_KEYS.COMPANY, {
                required: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_LABEL_ALT),
                maxLength: {
                  value: shortInputMaxLength,
                  message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                },
                validate: (inputValue) => validateEmptyTextInput(inputValue),
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const fieldValue = event.target.value;
                setValue(CONTACT_FORM_KEYS.COMPANY, fieldValue);
                clearErrors(CONTACT_FORM_KEYS.COMPANY);
              }}
              errors={errors[CONTACT_FORM_KEYS.COMPANY]}
              iconName="companyProfile"
            />
          </div>

          <div className={styles.formInputWrapper}>
            <TextInput
              label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.WEBSITE_LABEL))}
              inputType="text"
              id={CONTACT_FORM_KEYS.WEBSITE}
              value={getValues(CONTACT_FORM_KEYS.WEBSITE) ?? ''}
              {...register(CONTACT_FORM_KEYS.WEBSITE, {
                required: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_LABEL_ALT),
                maxLength: {
                  value: shortInputMaxLength,
                  message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                },
                validate: (inputValue) => validateEmptyTextInput(inputValue),
              })}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const fieldValue = event.target.value;
                setValue(CONTACT_FORM_KEYS.WEBSITE, fieldValue);
                clearErrors(CONTACT_FORM_KEYS.WEBSITE);
              }}
              errors={errors[CONTACT_FORM_KEYS.WEBSITE]}
              iconName="link"
            />
          </div>

          <div className={styles.formInputWrapper}>
            <label htmlFor={CONTACT_FORM_KEYS.COMPANY_SIZE} className={styles.formLabel}>
              {addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.COMPANY_SIZE_LABEL))}
              <Picker
                ariaLabel={t(contactBundle, ContactFormStrings.COMPANY_SIZE_LABEL)}
                ranges={companySizeRanges}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const newIndex = Number(event.target.value);
                  const selectedValue = companySizeRanges[newIndex].label;
                  setValue(CONTACT_FORM_KEYS.COMPANY_SIZE, selectedValue);
                }}
              />
            </label>
          </div>

          <div>
            <Controller
              name={CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                },
              }}
              render={({ field: { value } }) => (
                <CheckboxDescriptionGroup
                  id={CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON}
                  label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.AMAZON_RELATION_LABEL))}
                  options={Object.values(RelationToAmazon).map((relation) => ({
                    value: relation,
                    checkboxLabel: t(contactBundle, relationToAmazonTranslationMap[relation], undefined, relation),
                    checkboxDescription:
                      relation === RelationToAmazon.OTHER
                        ? ''
                        : t(contactBundle, relationToAmazonDescriptionTranslationMap[relation], undefined, relation),
                  }))}
                  testId={`checkbox-description-group-${CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON}`}
                  onChange={(relation, isChecked) => {
                    const currentSelection = value ?? [];
                    if (isChecked) {
                      setValue(CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON, [
                        ...currentSelection,
                        relation as RelationToAmazon,
                      ]);
                    } else {
                      setValue(
                        CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON,
                        currentSelection.filter((rel) => rel !== relation)
                      );
                    }
                    clearErrors(CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON);
                  }}
                  errors={errors[CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON] as FieldError}
                  sublabel={t(contactBundle, ContactFormStrings.SELECT_INFO)}
                  showAdditionalInput={watchRelationOther}
                  additionalInputProps={{
                    label: addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.ADDITIONAL_RELATION_LABEL)),
                    id: CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS,
                    additionalInputValue: getValues(CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS) ?? '',
                    onChange: (nestedInputValue) => {
                      setValue(CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS, nestedInputValue);
                      clearErrors(CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS);
                    },
                    register,
                    registerOptions: {
                      required:
                        watchRelationOther && t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                      maxLength: {
                        value: shortInputMaxLength,
                        message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                      },
                      validate: (inputValue) => validateEmptyTextInput(inputValue),
                    },
                    error: errors[CONTACT_FORM_KEYS.ADDITIONAL_RELATIONS],
                  }}
                  initialValues={initialFormValues[CONTACT_FORM_KEYS.RELATIONS_TO_AMAZON]}
                />
              )}
            />
          </div>

          <div className={styles.formSectionHeaderWrapper}>
            <h4 className={styles.formSectionHeader}>{t(contactBundle, formTypeHeaderMap[watchFormType])}</h4>
          </div>

          <div className={styles.formInputWrapper}>
            <Controller
              name={CONTACT_FORM_KEYS.FORM_TOPICS}
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                },
              }}
              render={({ field: { value } }) => (
                <CheckboxPillGroup
                  id={CONTACT_FORM_KEYS.FORM_TOPICS}
                  label={addRequiredLabelSymbol(t(contactBundle, formTypeTopicMap[watchFormType]))}
                  options={Object.values(AsxFormTopic).map((topic) => ({
                    value: topic,
                    checkboxLabel: t(contactBundle, asxFormTopicTranslationMap[topic], undefined, topic),
                  }))}
                  testId={`checkbox-pill-group-${CONTACT_FORM_KEYS.FORM_TOPICS}`}
                  onChange={(contentType, isChecked) => {
                    const currentSelection = value ?? [];

                    if (isChecked) {
                      setValue(CONTACT_FORM_KEYS.FORM_TOPICS, [...currentSelection, contentType as AsxFormTopic]);
                    } else {
                      setValue(
                        CONTACT_FORM_KEYS.FORM_TOPICS,
                        currentSelection.filter((type) => type !== contentType)
                      );
                    }

                    clearErrors(CONTACT_FORM_KEYS.FORM_TOPICS);
                  }}
                  errors={errors[CONTACT_FORM_KEYS.FORM_TOPICS] as FieldError}
                  sublabel={t(contactBundle, ContactFormStrings.SELECT_INFO)}
                  showAdditionalInput={watchTopicOther}
                  additionalInputProps={{
                    label: addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.ADDITIONAL_TOPIC_LABEL)),
                    id: CONTACT_FORM_KEYS.ADDITIONAL_TOPICS,
                    additionalInputValue: getValues(CONTACT_FORM_KEYS.ADDITIONAL_TOPICS) ?? '',
                    onChange: (nestedInputValue) => {
                      setValue(CONTACT_FORM_KEYS.ADDITIONAL_TOPICS, nestedInputValue);
                      clearErrors(CONTACT_FORM_KEYS.ADDITIONAL_TOPICS);
                    },
                    register,
                    registerOptions: {
                      required:
                        watchTopicOther && t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                      maxLength: {
                        value: shortInputMaxLength,
                        message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                      },
                      validate: (inputValue) => validateEmptyTextInput(inputValue),
                    },
                    error: errors[CONTACT_FORM_KEYS.ADDITIONAL_TOPICS],
                  }}
                  initialValues={initialFormValues[CONTACT_FORM_KEYS.FORM_TOPICS]}
                />
              )}
            />
          </div>

          {watchFormType === AsxFormType.GENERAL_CONTENT_INTAKE && (
            <div className={styles.formInputWrapper}>
              <Controller
                name={CONTACT_FORM_KEYS.CONTENT_TYPES}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                  },
                }}
                render={({ field: { value } }) => (
                  <CheckboxPillGroup
                    id={CONTACT_FORM_KEYS.CONTENT_TYPES}
                    label={addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.CONTENT_TYPE_LABEL))}
                    options={Object.values(AsxFormContentType).map((contentType) => ({
                      value: contentType,
                      checkboxLabel: t(
                        contactBundle,
                        asxFormContentTypeTranslationMap[contentType],
                        undefined,
                        contentType
                      ),
                    }))}
                    testId={`checkbox-pill-group-${CONTACT_FORM_KEYS.CONTENT_TYPES}`}
                    onChange={(contentType, isChecked) => {
                      const currentSelection = value ?? [];

                      if (isChecked) {
                        setValue(CONTACT_FORM_KEYS.CONTENT_TYPES, [
                          ...currentSelection,
                          contentType as AsxFormContentType,
                        ]);
                      } else {
                        setValue(
                          CONTACT_FORM_KEYS.CONTENT_TYPES,
                          currentSelection.filter((type) => type !== contentType)
                        );
                      }

                      clearErrors(CONTACT_FORM_KEYS.CONTENT_TYPES);
                    }}
                    errors={errors[CONTACT_FORM_KEYS.CONTENT_TYPES] as FieldError}
                    sublabel={t(contactBundle, ContactFormStrings.SELECT_INFO)}
                    showAdditionalInput={watchContentTypeOther}
                    additionalInputProps={{
                      label: addRequiredLabelSymbol(t(contactBundle, ContactFormStrings.ADDITIONAL_CONTENT_TYPE_LABEL)),
                      id: CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES,
                      additionalInputValue: getValues(CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES) ?? '',
                      onChange: (nestedInputValue) => {
                        setValue(CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES, nestedInputValue);
                        clearErrors(CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES);
                      },
                      register,
                      registerOptions: {
                        required:
                          watchContentTypeOther &&
                          t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                        maxLength: {
                          value: shortInputMaxLength,
                          message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${shortInputMaxLength}`,
                        },
                        validate: (inputValue) => validateEmptyTextInput(inputValue),
                      },
                      error: errors[CONTACT_FORM_KEYS.ADDITIONAL_CONTENT_TYPES],
                    }}
                    initialValues={initialFormValues[CONTACT_FORM_KEYS.CONTENT_TYPES]}
                  />
                )}
              />
            </div>
          )}

          <div>
            <p className={styles.formSubtitle}>
              {addRequiredLabelSymbol(t(contactBundle, formTypeDescriptionMap[watchFormType]))}
            </p>
          </div>

          <div>
            <TextArea
              label={t(contactBundle, formTypeDescriptionPlaceholderMap[watchFormType])}
              id={CONTACT_FORM_KEYS.DESCRIPTION}
              maxLength={longInputMaxLength}
              value={getValues(CONTACT_FORM_KEYS.DESCRIPTION) ?? ''}
              {...register(CONTACT_FORM_KEYS.DESCRIPTION, {
                required: t(contactBundle, ContactFormStrings.REQUIRED_FIELD_PLACEHOLDER_LABEL),
                maxLength: {
                  value: longInputMaxLength,
                  message: `${t(contactBundle, ContactFormStrings.INPUT_TOO_LONG_LABEL)} ${longInputMaxLength}`,
                },
                validate: (inputValue) => validateEmptyTextInput(inputValue),
              })}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
                const fieldValue = event.target.value;
                setValue(CONTACT_FORM_KEYS.DESCRIPTION, fieldValue);
                clearErrors(CONTACT_FORM_KEYS.DESCRIPTION);
              }}
              errors={errors[CONTACT_FORM_KEYS.DESCRIPTION]}
              sublabel={t(contactBundle, ContactFormStrings.DESCRIPTION_INFO)}
            />
          </div>
        </form>
      </div>
      <div className={styles.submitButtonWrapper} data-testid="submitButtonWrapper" style={{ width: `${formWidth}px` }}>
        <AsxMarkdownContent
          className={styles.privacyNoticeLabel}
          copy={t(contactBundle, ContactFormStrings.FORM_SUBMIT_PRIVACY_NOTICE_LABEL)}
        />

        <PrimaryButton
          label={t(contactBundle, ContactFormStrings.FORM_SUBMIT_BUTTON_LABEL)}
          icon="chevronRight"
          type="submit"
          form="contactForm"
          data-testid="submit-button"
          buttonSize={PRIMARY_BUTTON_SIZE.Small}
        />
      </div>
    </div>
  );
};
