import { FunctionComponent, useContext } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { SaveCreditsInterestFormResponse } from '@amzn/coral_com-amazon-sssapinterface-model-operations';
import { FormMetadata } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { useBundle } from '@amzn/react-arb-tools';

import { SSSAPIClientContext } from 'src/clients/SSSAPIClientProvider';
import { PageSection } from 'src/components/common';
import {
  CreditsInterestForm,
  CreditsInterestFormValues,
} from 'src/components/pages/credits/interest/CreditsInterestForm';
import { RefererContext } from 'src/components/wrappers';
import { METADATA_QUERY_PARAM } from 'src/constants';
import { CONTACT_FORM_PAGE_BUNDLE_NAME } from 'src/constants/common-constants';
import { useApi } from 'src/hooks/use-api';

export const CreditsInterestFormPage: FunctionComponent = () => {
  const [contactFormBundle] = useBundle(CONTACT_FORM_PAGE_BUNDLE_NAME);

  const { saveCreditsInterestForm } = useContext(SSSAPIClientContext);
  const refTag = useContext(RefererContext);

  const [searchParams] = useSearchParams();
  const formMetadata = searchParams.get(METADATA_QUERY_PARAM);

  // TODO create error handler https://issues.amazon.com/issues/ASX-237
  const { response, callApi } = useApi<SaveCreditsInterestFormResponse>();

  const handleFormSubmit: SubmitHandler<CreditsInterestFormValues> = (formValues) => {
    const metadata = FormMetadata.clone({
      ...(refTag && { refTag }),
      ...(formMetadata && JSON.parse(formMetadata)),
    });
    callApi(saveCreditsInterestForm, formValues, metadata);
  };

  // TODO remove once component is implemented https://issues.amazon.com/issues/ASX-210
  /* istanbul ignore next */
  if (response && response.id) {
    console.log('Thank you!');
  }

  return (
    <PageSection>
      <CreditsInterestForm contactBundle={contactFormBundle} handleFormSubmit={handleFormSubmit} />
    </PageSection>
  );
};
