import { FunctionComponent, ChangeEvent, RefObject, useEffect, useState } from 'react';

import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './SearchInput.module.scss';

export enum INPUT_STYLE {
  Regular = 'searchInput',
  Collapsed = 'collapsedSearchInput',
}

const MIN_FONT_SIZE_MOBILE = 22;
const MAX_FONT_SIZE_MOBILE = 40;
const MIN_LINE_HEIGHT_MOBILE = 30;
const MAX_LINE_HEIGHT_MOBILE = 48;
const MAX_CHAR_COUNT_BEFORE_TEXTWRAP_MOBILE = 35;
const MIN_FONT_SIZE_DESKTOP = 32;
const MAX_FONT_SIZE_DESKTOP = 88;
const MIN_LINE_HEIGHT_DESKTOP = 40;
const MAX_LINE_HEIGHT_DESKTOP = 96;
const MAX_CHAR_COUNT_BEFORE_TEXTWRAP_DESKTOP = 60;

interface SearchInputProps {
  placeholder?: string;
  value: string;
  maxLength: number;
  onInput: (e: ChangeEvent<HTMLInputElement>) => void;
  inputRef: RefObject<HTMLInputElement>;
  className: INPUT_STYLE;
}

// TODO add unit tests
export const SearchInput: FunctionComponent<SearchInputProps> = ({
  placeholder,
  value,
  maxLength,
  onInput,
  inputRef,
  className,
}) => {
  const { isSmallDesktop } = useDeviceSize();
  const [fontSize, setFontSize] = useState(isSmallDesktop ? MAX_FONT_SIZE_DESKTOP : MAX_FONT_SIZE_MOBILE);
  const [lineHeight, setLineHeight] = useState(isSmallDesktop ? MAX_LINE_HEIGHT_DESKTOP : MAX_LINE_HEIGHT_MOBILE);

  useEffect(() => {
    const maxFontSize = isSmallDesktop ? MAX_FONT_SIZE_DESKTOP : MAX_FONT_SIZE_MOBILE;
    const minFontSize = isSmallDesktop ? MIN_FONT_SIZE_DESKTOP : MIN_FONT_SIZE_MOBILE;
    const maxLineHeight = isSmallDesktop ? MAX_LINE_HEIGHT_DESKTOP : MAX_LINE_HEIGHT_MOBILE;
    const minLineHeight = isSmallDesktop ? MIN_LINE_HEIGHT_DESKTOP : MIN_LINE_HEIGHT_MOBILE;
    const maxCharCountBeforeTextWrap = isSmallDesktop
      ? MAX_CHAR_COUNT_BEFORE_TEXTWRAP_DESKTOP
      : MAX_CHAR_COUNT_BEFORE_TEXTWRAP_MOBILE;
    const fontSizeScalingFactor = (maxFontSize - minFontSize) / maxCharCountBeforeTextWrap;
    const lineHeightScalingFactor = (maxLineHeight - minLineHeight) / maxCharCountBeforeTextWrap;
    const newFontSize = Math.max(minFontSize, maxFontSize - fontSizeScalingFactor * value.length);
    const newLineHeight = Math.max(minLineHeight, maxLineHeight - lineHeightScalingFactor * value.length);
    setFontSize(newFontSize);
    setLineHeight(newLineHeight);
  }, [isSmallDesktop, value]);

  return (
    <input
      data-testid="search-input"
      type="search"
      className={styles[className]}
      placeholder={placeholder}
      value={value}
      maxLength={maxLength}
      onInput={onInput}
      ref={inputRef}
      style={{ fontSize: `${fontSize}px`, lineHeight: `${lineHeight}px` }}
    />
  );
};
