import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { PageSection, SmallBanner } from 'src/components/common';
import { CLOUDFRONT_PREFIX, COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

export const ClimatePledgeSection: FunctionComponent = () => {
  const [bundle] = useBundle(COMMON_BUNDLE_NAME);
  return (
    <PageSection theme="grey-tertiary">
      <SmallBanner
        theme={SECTION_THEME.Light}
        heading={t(bundle, 'climatePledge_heading')}
        cta={{
          label: t(bundle, 'climatePledgeButton_label'),
          href: 'https://www.theclimatepledge.com/',
        }}
        image={{
          url: `${CLOUDFRONT_PREFIX}/locale/en-us/resource-type/image/variation/desktop/TCPglobalcomponent-2.png`,
          alt: t(bundle, 'footerTemplateImage_alt'),
        }}
        logo
      />
    </PageSection>
  );
};
