import classNames from 'classnames';
import { useEffect, useState, useRef } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { AnchorPill, Icon } from 'src/components/atoms';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { convertToSentenceCase } from 'src/helpers/formatting-helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './AnchorLinks.module.scss';
/**
 * AnchorLinks Component
 *
 * This component dynamically collects IDs of sections on the page that have a `data-anchor` attribute.
 * These IDs are then used to generate a list of anchor links for navigation.
 *
 * To use this component, add an `id` and `data-anchor` attribute to each section you want to include
 * in the navigation. For example:
 *
 * <div id="section1" data-anchor> ... </div>
 * <div id="section2" data-anchor> ... </div>
 *
 * The component will automatically detect these sections and create anchor links for them.
 */

export const AnchorLinks: React.FC = () => {
  const [anchorLinks, setAnchorLinks] = useState<string[]>([]);
  const [showButtonRight, setShowButtonRight] = useState(false);
  const [showButtonLeft, setShowButtonLeft] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [scrollDirection, setScrollDirection] = useState('scrolling down');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const anchorLinksRef = useRef<HTMLDivElement>(null);
  const anchorListRef = useRef<HTMLUListElement>(null);
  const scrollDistance = 150;
  const { isSmallDesktop } = useDeviceSize();
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const desktopTopOffset = 110;
  const anchorLinksHeight = 72;
  const desktopAnchorLinksTopExpand = '110px';
  const desktopAnchorLinksTopCollapse = '8px';
  const theme = SECTION_THEME.Light;

  const showRightButtonLogic = () => {
    const anchorListElement = anchorListRef.current;
    if (anchorListElement) {
      setShowButtonRight(anchorListElement.scrollWidth > anchorListElement.clientWidth);
    }
  };

  const [headerHeight, setHeaderHeight] = useState(0);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const prevIsHeaderHiddenRef = useRef(isHeaderHidden); // Use ref to track the previous state

  // useEffect for observing header height and visibility
  useEffect(() => {
    const header = document.getElementById('sc-navbar-container');

    if (!header) return;

    const updateHeaderState = () => {
      const currentHeight = header.offsetHeight;
      const currentIsHeaderHidden = header.getAttribute('aria-hidden') === 'true';

      // Update state only if values change
      if (currentHeight !== headerHeight) {
        setHeaderHeight(currentHeight);
      }

      setIsHeaderHidden(currentIsHeaderHidden);
    };

    // Initial call to set state
    updateHeaderState();

    // Create a MutationObserver to watch for changes in the header's aria-hidden attribute
    const observer = new MutationObserver(() => {
      updateHeaderState(); // Update the state when aria-hidden changes
    });

    observer.observe(header, { attributes: true, attributeFilter: ['aria-hidden'] });

    // Cleanup the observer on component unmount
    // eslint-disable-next-line consistent-return
    return () => {
      observer.disconnect();
    };
  }, [headerHeight]);

  // useEffect for making changes based on header height and visibility
  useEffect(() => {
    const anchorLinksElement = isSmallDesktop ? anchorLinksRef?.current : wrapperRef?.current;

    if (anchorLinksElement) {
      const currentTop = parseFloat(window.getComputedStyle(anchorLinksElement).top) ?? 0;

      if (prevIsHeaderHiddenRef.current !== isHeaderHidden) {
        if (isHeaderHidden) {
          // Header changed from visible to hidden, subtract height
          anchorLinksElement.style.top = isSmallDesktop
            ? desktopAnchorLinksTopCollapse
            : `${currentTop - headerHeight}px`;
        } else {
          // Header changed from hidden to visible, add back height
          anchorLinksElement.style.top = isSmallDesktop
            ? desktopAnchorLinksTopExpand
            : `${currentTop + headerHeight}px`;
        }

        prevIsHeaderHiddenRef.current = isHeaderHidden;
      }
    }
  }, [headerHeight, isHeaderHidden, anchorLinksRef, isSmallDesktop]);

  // let's add those section names and see if we need the 'scroll to right' button on load
  useEffect(() => {
    const sections = document.querySelectorAll('[data-anchor]');
    const ids = Array.from(sections).map((section) => section.id);
    setAnchorLinks(ids);

    const load = () => {
      showRightButtonLogic();
    };

    window.addEventListener('load', load);
    return () => window.removeEventListener('load', load);
  }, []);

  // let's handle resize to see if we need the 'scroll to right' button
  useEffect(() => {
    const anchorListElement = anchorListRef.current;

    const handleResize = () => {
      showRightButtonLogic();
      if (anchorListElement) {
        setIsScrolledToEnd(
          anchorListElement.scrollLeft + anchorListElement.clientWidth >= anchorListElement.scrollWidth
        );
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // let's make active index the one on screen
  const getVisibleSectionIndex = (): number => {
    const sections = document.querySelectorAll('[data-anchor]');
    const viewportHeight = window.innerHeight;
    const sectionTops = Array.from(sections).map((section) => {
      const rect = section.getBoundingClientRect();
      return {
        top: rect.top,
        bottom: rect.bottom,
      };
    });

    for (let i = 0; i < sectionTops.length; i += 1) {
      const { top, bottom } = sectionTops[i];
      if (top <= viewportHeight / 2 && bottom >= viewportHeight / 2) {
        return i;
      }
    }

    return -1;
  };

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    // let's change the class of the list so it can be sticky.
    const updateScrollDirection = () => {
      const { scrollY } = window; // Object destructuring to extract scrollY

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      setScrollDirection(scrollY > lastScrollY ? 'scrolling down' : 'scrolling up');
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      const newIndex = getVisibleSectionIndex();
      const anchorLinksElement = anchorLinksRef.current;
      const wrapperElement = wrapperRef.current;

      if (!ticking) {
        window.requestAnimationFrame(updateScrollDirection);
        ticking = true;
      }
      // just in case
      showRightButtonLogic();

      if (anchorLinksElement && wrapperElement && isSmallDesktop) {
        const anchorLinksRect = anchorLinksElement.getBoundingClientRect();
        const wrapperRect = wrapperElement.getBoundingClientRect();

        if (scrollDirection === 'scrolling down' && anchorLinksRect.top <= desktopTopOffset) {
          anchorLinksElement.style.position = 'fixed';
        }
        if (scrollDirection === 'scrolling up' && wrapperRect.top > anchorLinksHeight) {
          anchorLinksElement.style.position = 'static';
        }
      }
      if (!isSmallDesktop && anchorLinksElement) {
        anchorLinksElement.style.position = 'relative';
      }

      setActiveIndex(newIndex);
    };

    setActiveIndex(getVisibleSectionIndex());

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollDirection, isSmallDesktop]);

  // let's see if we need the left button
  useEffect(() => {
    const anchorListElement = anchorListRef.current;

    if (anchorListElement) {
      const handleListScroll = () => {
        setIsScrolledToEnd(
          anchorListElement.scrollLeft + anchorListElement.clientWidth >= anchorListElement.scrollWidth
        );
        setShowButtonLeft(anchorListElement.scrollLeft > 0);
      };

      anchorListElement.addEventListener('scroll', handleListScroll);

      return () => anchorListElement.removeEventListener('scroll', handleListScroll);
    }
    return undefined;
  }, []);

  const handleScrollRight = () => {
    const anchorListElement = anchorListRef.current;
    if (anchorListElement) {
      anchorListElement.scrollBy({ left: scrollDistance, behavior: 'smooth' });
    }
  };

  const handleScrollLeft = () => {
    const anchorListElement = anchorListRef.current;
    if (anchorListElement) {
      anchorListElement.scrollBy({ left: -scrollDistance, behavior: 'smooth' });
    }
  };

  // TODO: This is hacky but necessary for now because this is the only anchor link which requires custom transformation
  // Remove with localization, when all the anchor link logic should be updated to use panther for translation rather than dynamically
  // generating the label from the section id: https://app.asana.com/0/1206972973144714/1207689939313252/f
  const crossSectorSolutionsSectionId = 'cross-sector-solutions';

  // Transforms `cross-sector-solutions` -> `Cross-sector solutions`
  // Unlike other section ids where hyphens are removed and transformed to `Sentence case`,
  // we need to persist the first hyphen in `Cross-sector solutions`.
  const transformCrossSectorSolutionsSectionId = (sectionId: string) => {
    const sectionIdSplitByHyphen = sectionId.split('-');
    const crossWordIndex = 0;
    const sectorWordIndex = 1;
    const solutionsWordIndex = 2;
    return `${convertToSentenceCase(sectionIdSplitByHyphen[crossWordIndex])}-${sectionIdSplitByHyphen[sectorWordIndex]} ${sectionIdSplitByHyphen[solutionsWordIndex]}`;
  };

  return (
    <nav
      aria-label={t(commonBundle, 'secondary_navigation')}
      className={classNames(styles.wrapper, styles[theme])}
      ref={wrapperRef}
    >
      <div
        ref={anchorLinksRef}
        className={classNames(
          styles.AnchorLinks,
          showButtonRight && styles.showButtonRight,
          showButtonLeft && styles.showButtonLeft
        )}
      >
        <ul
          className={classNames(
            styles.anchorList,
            showButtonRight && styles.showButtonRight,
            showButtonLeft && styles.showButtonLeft
          )}
          ref={anchorListRef}
        >
          {anchorLinks.map((sectionId, index) => {
            const isActive = index === activeIndex;

            return (
              <li className={styles.pillContainer} key={sectionId}>
                {isActive && <div className={styles.activeAnchorPillBullet} />}
                <AnchorPill
                  sectionId={`#${sectionId}`}
                  copy={
                    sectionId === crossSectorSolutionsSectionId
                      ? transformCrossSectorSolutionsSectionId(sectionId)
                      : sectionId.replace(/-/g, ' ')
                  }
                  isActive={isActive}
                />
              </li>
            );
          })}
        </ul>

        <div className={classNames(styles.buttonWrapperLeft, styles[theme], showButtonLeft && styles.showButtonLeft)}>
          <button
            type="button"
            className={styles.button}
            onClick={handleScrollLeft}
            style={{ rotate: '180deg' }}
            aria-label={t(commonBundle, 'button_previous')}
            data-testid="left-button"
          >
            <Icon name="chevronRight" />
          </button>
        </div>
        <div
          className={classNames(
            styles.buttonWrapperRight,
            styles[theme],
            showButtonRight && !isScrolledToEnd && styles.showButtonRight
          )}
        >
          <button
            type="button"
            className={styles.button}
            onClick={handleScrollRight}
            aria-label={t(commonBundle, 'button_next')}
            data-testid="right-button"
          >
            <Icon name="chevronRight" />
          </button>
        </div>
      </div>
    </nav>
  );
};
