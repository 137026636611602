import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { ErrorTemplate } from 'src/components/templates/error/ErrorTemplate';
import { ERROR_WRAPPER_RESOURCE_BUNDLE_NAME } from 'src/constants';

export const ErrorFallback: FunctionComponent = () => {
  const [errorBundle] = useBundle(ERROR_WRAPPER_RESOURCE_BUNDLE_NAME);

  return (
    <ErrorTemplate
      bundle={errorBundle}
      titleLabel="errorFallback_title"
      descriptionLabel="errorFallback_description"
      altText="errorFallback_altText"
    />
  );
};
