import classNames from 'classnames';
import { type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './FilterTag.module.scss';

interface FilterTagProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: SECTION_THEME;
  className?: string;
  icon: IconType;
  label: string;
  isActive?: boolean;
  index: number;
  setActiveTab?: (index: number) => void;
  activeTab: number;
  onClick?: () => void;
}

export function FilterTag({
  icon,
  label,
  className,
  theme = SECTION_THEME.Dark,
  index,
  activeTab,
  setActiveTab,
  onClick,
  ...props
}: FilterTagProps): ReactElement {
  const isActive = index === activeTab;

  const handleClick = () => {
    if (setActiveTab) {
      setActiveTab(index);
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type="button"
      role="tab"
      aria-controls={`tabpanel-${index}`}
      aria-selected={isActive}
      className={classNames(styles.FilterTag, styles[theme], isActive && styles.isActive, className)}
      onClick={handleClick}
      {...props}
    >
      <Icon className={styles.icon} name={icon} />
      <p className={styles.label}>{label}</p>
    </button>
  );
}
