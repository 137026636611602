enum EASE {
  CubicInOut = 'cubic-bezier(0.65, 0, 0.35, 1)',
  In = 'cubic-bezier(0.42, 0, 1, 1)',
}

const FULL_STROKE_DASH_LOADING_BAR = 125;
const INITIAL_STROKE_DASH_ARRAY_VALUE = '0 150';
let lastStrokeDashArray = INITIAL_STROKE_DASH_ARRAY_VALUE;

const Animations = {
  getFillGraphicAnim: (progress: number) => [
    {
      strokeDasharray: lastStrokeDashArray,
      strokeDashoffset: '0',
    },
    {
      strokeDasharray: `${FULL_STROKE_DASH_LOADING_BAR * progress} 150`,
      strokeDashoffset: '0',
    },
  ],
  fadeIn: [
    {
      opacity: '0',
    },
    {
      opacity: '1',
    },
  ],

  fadeOut: [
    {
      opacity: '1',
    },
    {
      opacity: '0',
    },
  ],
};

const setInvisible = (element: SVGCircleElement | SVGSVGElement) => {
  element.animate(Animations.fadeOut, {
    duration: 0, // set
    iterations: 1,
    fill: 'forwards',
  });
};

export const resetGraphic = (element: SVGCircleElement | SVGSVGElement) => {
  setInvisible(element);
  element.animate(Animations.getFillGraphicAnim(0), {
    duration: 0, // set
    iterations: 1,
    fill: 'forwards',
  });
};

export const resetToBeginning = (
  self: HTMLDivElement,
  graphic: SVGCircleElement,
  circles: Array<SVGCircleElement> | undefined
) => {
  self.getAnimations().forEach((anim) => {
    anim.cancel();
    anim.pause();
  });
  // eslint-disable-next-line no-param-reassign
  graphic.style.transform = '';
  graphic.getAnimations().forEach((anim) => anim.pause());
  const animation = graphic.animate(Animations.getFillGraphicAnim(0), {
    duration: 0, // set
    iterations: 1,
    fill: 'forwards',
  });
  animation.finished.then(() => {
    lastStrokeDashArray = INITIAL_STROKE_DASH_ARRAY_VALUE;
  });
  circles?.forEach((circle) => {
    circle.getAnimations().forEach((anim) => anim.pause());
    setInvisible(circle);
  });
};

export const playFillGraphicAnimation = (
  element: SVGCircleElement,
  circle: SVGCircleElement,
  progress: number,
  onComplete: () => Promise<any>
) => {
  const animation = element.animate(Animations.getFillGraphicAnim(progress), {
    duration: 800, // ms
    iterations: 1,
    easing: EASE.CubicInOut,
    fill: 'forwards',
  });

  if (circle) {
    circle.animate(Animations.fadeIn, {
      delay: 630, // ms
      duration: 150, // ms
      iterations: 1,
      easing: EASE.CubicInOut,
      fill: 'forwards',
    });
  }

  animation.finished.then(() => {
    lastStrokeDashArray = `${FULL_STROKE_DASH_LOADING_BAR * progress} 150`;
    onComplete();
  });
};

export const playFadeOutAnimation = (elements: SVGCircleElement[], onComplete: () => void) => {
  elements.forEach((element) => {
    const animation = element.animate(Animations.fadeOut, {
      delay: 2000, // ms
      duration: 500, // ms
      iterations: 1,
      easing: EASE.In,
      fill: 'forwards',
    });

    animation.finished.then(onComplete);
  });
};

export const playGraphicOutAnimation = (
  element: SVGCircleElement,
  circles: SVGCircleElement[],
  onComplete: () => void
) => {
  // eslint-disable-next-line no-param-reassign
  element.style.transform = 'rotateX(180deg) translateY(-100%)';
  const animation = element.animate(Animations.getFillGraphicAnim(0), {
    duration: 800, // ms
    iterations: 1,
    easing: EASE.CubicInOut,
    fill: 'forwards',
  });

  circles[0].animate(Animations.fadeOut, {
    delay: 300, // ms
    duration: 50, // ms
    iterations: 1,
    fill: 'forwards',
  });

  circles[1].animate(Animations.fadeOut, {
    delay: 500, // ms
    duration: 50, // ms
    iterations: 1,
    fill: 'forwards',
  });

  animation.finished.then(() => {
    // eslint-disable-next-line no-param-reassign
    element.style.transform = '';
    lastStrokeDashArray = '0 150';
    onComplete();
  });
};

// Helper sleep method built doing a noop animation, the goal is keeping
// any sleep animations tied to an html element so it's easy to stop
// and cancel any sleep while still using the animate api so we  don't
// run into race conditions by using setTimeouts
export const sleepMs = (duration: number, element: HTMLElement) =>
  new Promise<void>((resolve) => {
    if (element && element.animate) {
      const animation = element.animate(
        {},
        {
          duration,
        }
      );

      animation.onfinish = () => resolve();
    }
  });
