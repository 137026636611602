import { FunctionComponent, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToSection: FunctionComponent = () => {
  const { pathname, hash } = useLocation();
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const retryCountRef = useRef(0);

  useEffect(() => {
    const cleanTimeout = () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
      retryCountRef.current = 0;
    };

    // Clean up the previous timeout if it exists
    cleanTimeout();

    if (!hash) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' as ScrollBehavior,
      });
    } else {
      const checkInterval = 300; // Check every X ms
      const maxRetries = 5; // Maximum number of retries (retries * interval = total timeout time)

      const waitForRenderAndScroll = () => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          cleanTimeout();
        } else if (retryCountRef.current < maxRetries) {
          retryCountRef.current += 1;
          timeoutId.current = setTimeout(waitForRenderAndScroll, checkInterval);
        }
      };

      waitForRenderAndScroll();
    }

    return cleanTimeout;
  }, [pathname, hash]);

  return null;
};
