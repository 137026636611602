import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './grid.lit.scss';

/**
 * @component {kat-grid} KatGrid The grid is a flexible layout component made up of regular, repeating elements called grid-items. It’s an alternative to a table when you don’t need to directly compare large datasets line-by-line or column-by-column. The layout and content of the each grid-item should be consistent, but this is up to the builder to implement.
 * @example Default { "content": "<kat-grid-item>1</kat-grid-item><kat-grid-item>2</kat-grid-item><kat-grid-item>3<br />3.1<br />3.2<br />3.3</kat-grid-item><kat-grid-item>4</kat-grid-item><kat-grid-item>5</kat-grid-item><kat-grid-item>6</kat-grid-item><kat-grid-item>7</kat-grid-item><kat-grid-item>8</kat-grid-item><kat-grid-item>9</kat-grid-item><kat-grid-item>10<br />10.1</kat-grid-item><kat-grid-item>11</kat-grid-item><kat-grid-item>12</kat-grid-item>" }
 * @example DenseCustomCard { "density": "high", "variant": "card", "colsXXL": 3, "colsXL": 4, "colsL": 5, "colsM": 6, "colsS": 7, "content": "<kat-grid-item>1<br />1.1</kat-grid-item><kat-grid-item>2</kat-grid-item><kat-grid-item>3</kat-grid-item><kat-grid-item>4</kat-grid-item><kat-grid-item>5</kat-grid-item><kat-grid-item>6</kat-grid-item><kat-grid-item>7</kat-grid-item><kat-grid-item>8</kat-grid-item><kat-grid-item>9</kat-grid-item><kat-grid-item>10</kat-grid-item><kat-grid-item>11<br />11.1<br />11.2</kat-grid-item><kat-grid-item>12</kat-grid-item>" }
 * @example VerticalList { "verticalList": true, "variant": "card", "content": "<kat-grid-item>1<br />1.1</kat-grid-item><kat-grid-item>2</kat-grid-item><kat-grid-item>3<br />3.1<br />3.2</kat-grid-item>" }
 * @subcomponent ./grid-item.ts
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-grid')
export class KatGrid extends KatLitElement {
  static get styles() {
    return [baseStyles, styles];
  }

  // Using this map doesn't work in the property definitions, but
  // makes the changedProperties iteration more efficient
  static colsPropertyToAttributeMap: Record<string, string> = {
    colsXXL: 'cols-xxl',
    colsXL: 'cols-xl',
    colsL: 'cols-l',
    colsM: 'cols-m',
    colsS: 'cols-s',
  };

  /** Display the grid as a vertical list */
  @property({ attribute: 'vertical-list' })
  verticalList?: boolean;

  /**
   * Density of content in grid.
   * @enum {value} high Reduced padding between each grid-item.
   * @enum {value} low Default padding between each grid-item. - Default
   */
  @property()
  density?: 'high' | 'low' = 'low';

  /**
   * Styling of content in grid.
   * @enum {value} card Card styling applied to grid item.
   * @enum {value} item No styling applied to grid item. - Default
   */
  @property()
  variant?: 'card' | 'item' = 'item';

  /** Number of columns at XXL breakpoint. Default 6. */
  @property({ attribute: 'cols-xxl' })
  colsXXL?: number = 6;

  /** Number of columns at XL breakpoint. Default 4. */
  @property({ attribute: 'cols-xl' })
  colsXL?: number = 4;

  /** Number of columns at L breakpoint. Default 3. */
  @property({ attribute: 'cols-l' })
  colsL?: number = 3;

  /** Number of columns at M breakpoint. Default 2. */
  @property({ attribute: 'cols-m' })
  colsM?: number = 2;

  /** Number of columns at S breakpoint. Default 1. */
  @property({ attribute: 'cols-s' })
  colsS?: number = 1;

  updated(_changedProperties: Map<string, unknown>) {
    _changedProperties.forEach((val, property) => {
      const attribute = KatGrid.colsPropertyToAttributeMap[property];
      if (attribute) {
        this.style.setProperty(`--kat-grid-${attribute}`, this[property]);
      }
    });
  }

  render() {
    return html`<div class="container">
      <div class="grid"><slot></slot></div>
    </div>`;
  }
}
