var endpointMap = {
	de: {
		assetgraphic: "translation-de.json",
		common: "translation-de.json",
		"pages.contactform": "translation-de.json",
		"pages.datainitiativepage": "translation-de.json",
		"pages.focusarea": "translation-de.json",
		"pages.gettingstartedpage": "translation-de.json",
		"pages.homepage": "translation-de.json",
		"pages.measurement": "translation-de.json",
		"pages.scienceexchange": "translation-de.json",
		"pages.searchresultspage": "translation-de.json",
		"resources.casestudy": "translation-de.json",
		"resources.collaborators": "translation-de.json",
		"resources.data": "translation-de.json",
		"resources.guidance": "translation-de.json",
		"resources.methodology": "translation-de.json",
		"resources.playbook": "translation-de.json",
		"resources.policy": "translation-de.json",
		"resources.secondaryhero": "translation-de.json",
		"resources.tool": "translation-de.json",
		"templates.error": "translation-de.json",
		"templates.notfound": "translation-de.json"
	},
	"de-DE": {
		assetgraphic: "translation-de.json",
		common: "translation-de.json",
		"pages.contactform": "translation-de.json",
		"pages.datainitiativepage": "translation-de.json",
		"pages.focusarea": "translation-de.json",
		"pages.gettingstartedpage": "translation-de.json",
		"pages.homepage": "translation-de.json",
		"pages.measurement": "translation-de.json",
		"pages.scienceexchange": "translation-de.json",
		"pages.searchresultspage": "translation-de.json",
		"resources.casestudy": "translation-de.json",
		"resources.collaborators": "translation-de.json",
		"resources.data": "translation-de.json",
		"resources.guidance": "translation-de.json",
		"resources.methodology": "translation-de.json",
		"resources.playbook": "translation-de.json",
		"resources.policy": "translation-de.json",
		"resources.secondaryhero": "translation-de.json",
		"resources.tool": "translation-de.json",
		"templates.error": "translation-de.json",
		"templates.notfound": "translation-de.json"
	},
	en: {
		assetgraphic: "translation-en.json",
		common: "translation-en.json",
		"pages.contactform": "translation-en.json",
		"pages.datainitiativepage": "translation-en.json",
		"pages.focusarea": "translation-en.json",
		"pages.gettingstartedpage": "translation-en.json",
		"pages.homepage": "translation-en.json",
		"pages.measurement": "translation-en.json",
		"pages.scienceexchange": "translation-en.json",
		"pages.searchresultspage": "translation-en.json",
		"resources.casestudy": "translation-en.json",
		"resources.collaborators": "translation-en.json",
		"resources.data": "translation-en.json",
		"resources.guidance": "translation-en.json",
		"resources.methodology": "translation-en.json",
		"resources.playbook": "translation-en.json",
		"resources.policy": "translation-en.json",
		"resources.secondaryhero": "translation-en.json",
		"resources.tool": "translation-en.json",
		"templates.error": "translation-en.json",
		"templates.notfound": "translation-en.json"
	},
	"en-US": {
		assetgraphic: "translation-en.json",
		common: "translation-en.json",
		"pages.contactform": "translation-en.json",
		"pages.datainitiativepage": "translation-en.json",
		"pages.focusarea": "translation-en.json",
		"pages.gettingstartedpage": "translation-en.json",
		"pages.homepage": "translation-en.json",
		"pages.measurement": "translation-en.json",
		"pages.scienceexchange": "translation-en.json",
		"pages.searchresultspage": "translation-en.json",
		"resources.casestudy": "translation-en.json",
		"resources.collaborators": "translation-en.json",
		"resources.data": "translation-en.json",
		"resources.guidance": "translation-en.json",
		"resources.methodology": "translation-en.json",
		"resources.playbook": "translation-en.json",
		"resources.policy": "translation-en.json",
		"resources.secondaryhero": "translation-en.json",
		"resources.tool": "translation-en.json",
		"templates.error": "translation-en.json",
		"templates.notfound": "translation-en.json"
	},
	es: {
		assetgraphic: "translation-es.json",
		common: "translation-es.json",
		"pages.contactform": "translation-es.json",
		"pages.datainitiativepage": "translation-es.json",
		"pages.focusarea": "translation-es.json",
		"pages.gettingstartedpage": "translation-es.json",
		"pages.homepage": "translation-es.json",
		"pages.measurement": "translation-es.json",
		"pages.scienceexchange": "translation-es.json",
		"pages.searchresultspage": "translation-es.json",
		"resources.casestudy": "translation-es.json",
		"resources.collaborators": "translation-es.json",
		"resources.data": "translation-es.json",
		"resources.guidance": "translation-es.json",
		"resources.methodology": "translation-es.json",
		"resources.playbook": "translation-es.json",
		"resources.policy": "translation-es.json",
		"resources.secondaryhero": "translation-es.json",
		"resources.tool": "translation-es.json",
		"templates.error": "translation-es.json",
		"templates.notfound": "translation-es.json"
	},
	"es-ES": {
		assetgraphic: "translation-es.json",
		common: "translation-es.json",
		"pages.contactform": "translation-es.json",
		"pages.datainitiativepage": "translation-es.json",
		"pages.focusarea": "translation-es.json",
		"pages.gettingstartedpage": "translation-es.json",
		"pages.homepage": "translation-es.json",
		"pages.measurement": "translation-es.json",
		"pages.scienceexchange": "translation-es.json",
		"pages.searchresultspage": "translation-es.json",
		"resources.casestudy": "translation-es.json",
		"resources.collaborators": "translation-es.json",
		"resources.data": "translation-es.json",
		"resources.guidance": "translation-es.json",
		"resources.methodology": "translation-es.json",
		"resources.playbook": "translation-es.json",
		"resources.policy": "translation-es.json",
		"resources.secondaryhero": "translation-es.json",
		"resources.tool": "translation-es.json",
		"templates.error": "translation-es.json",
		"templates.notfound": "translation-es.json"
	},
	"es-MX": {
		assetgraphic: "translation-es.json",
		common: "translation-es.json",
		"pages.contactform": "translation-es.json",
		"pages.datainitiativepage": "translation-es.json",
		"pages.focusarea": "translation-es.json",
		"pages.gettingstartedpage": "translation-es.json",
		"pages.homepage": "translation-es.json",
		"pages.measurement": "translation-es.json",
		"pages.scienceexchange": "translation-es.json",
		"pages.searchresultspage": "translation-es.json",
		"resources.casestudy": "translation-es.json",
		"resources.collaborators": "translation-es.json",
		"resources.data": "translation-es.json",
		"resources.guidance": "translation-es.json",
		"resources.methodology": "translation-es.json",
		"resources.playbook": "translation-es.json",
		"resources.policy": "translation-es.json",
		"resources.secondaryhero": "translation-es.json",
		"resources.tool": "translation-es.json",
		"templates.error": "translation-es.json",
		"templates.notfound": "translation-es.json"
	},
	"es-US": {
		assetgraphic: "translation-es.json",
		common: "translation-es.json",
		"pages.contactform": "translation-es.json",
		"pages.datainitiativepage": "translation-es.json",
		"pages.focusarea": "translation-es.json",
		"pages.gettingstartedpage": "translation-es.json",
		"pages.homepage": "translation-es.json",
		"pages.measurement": "translation-es.json",
		"pages.scienceexchange": "translation-es.json",
		"pages.searchresultspage": "translation-es.json",
		"resources.casestudy": "translation-es.json",
		"resources.collaborators": "translation-es.json",
		"resources.data": "translation-es.json",
		"resources.guidance": "translation-es.json",
		"resources.methodology": "translation-es.json",
		"resources.playbook": "translation-es.json",
		"resources.policy": "translation-es.json",
		"resources.secondaryhero": "translation-es.json",
		"resources.tool": "translation-es.json",
		"templates.error": "translation-es.json",
		"templates.notfound": "translation-es.json"
	},
	fr: {
		assetgraphic: "translation-fr.json",
		common: "translation-fr.json",
		"pages.contactform": "translation-fr.json",
		"pages.datainitiativepage": "translation-fr.json",
		"pages.focusarea": "translation-fr.json",
		"pages.gettingstartedpage": "translation-fr.json",
		"pages.homepage": "translation-fr.json",
		"pages.measurement": "translation-fr.json",
		"pages.scienceexchange": "translation-fr.json",
		"pages.searchresultspage": "translation-fr.json",
		"resources.casestudy": "translation-fr.json",
		"resources.collaborators": "translation-fr.json",
		"resources.data": "translation-fr.json",
		"resources.guidance": "translation-fr.json",
		"resources.methodology": "translation-fr.json",
		"resources.playbook": "translation-fr.json",
		"resources.policy": "translation-fr.json",
		"resources.secondaryhero": "translation-fr.json",
		"resources.tool": "translation-fr.json",
		"templates.error": "translation-fr.json",
		"templates.notfound": "translation-fr.json"
	},
	"fr-BE": {
		assetgraphic: "translation-fr.json",
		common: "translation-fr.json",
		"pages.contactform": "translation-fr.json",
		"pages.datainitiativepage": "translation-fr.json",
		"pages.focusarea": "translation-fr.json",
		"pages.gettingstartedpage": "translation-fr.json",
		"pages.homepage": "translation-fr.json",
		"pages.measurement": "translation-fr.json",
		"pages.scienceexchange": "translation-fr.json",
		"pages.searchresultspage": "translation-fr.json",
		"resources.casestudy": "translation-fr.json",
		"resources.collaborators": "translation-fr.json",
		"resources.data": "translation-fr.json",
		"resources.guidance": "translation-fr.json",
		"resources.methodology": "translation-fr.json",
		"resources.playbook": "translation-fr.json",
		"resources.policy": "translation-fr.json",
		"resources.secondaryhero": "translation-fr.json",
		"resources.tool": "translation-fr.json",
		"templates.error": "translation-fr.json",
		"templates.notfound": "translation-fr.json"
	},
	"fr-CA": {
		assetgraphic: "translation-fr.json",
		common: "translation-fr.json",
		"pages.contactform": "translation-fr.json",
		"pages.datainitiativepage": "translation-fr.json",
		"pages.focusarea": "translation-fr.json",
		"pages.gettingstartedpage": "translation-fr.json",
		"pages.homepage": "translation-fr.json",
		"pages.measurement": "translation-fr.json",
		"pages.scienceexchange": "translation-fr.json",
		"pages.searchresultspage": "translation-fr.json",
		"resources.casestudy": "translation-fr.json",
		"resources.collaborators": "translation-fr.json",
		"resources.data": "translation-fr.json",
		"resources.guidance": "translation-fr.json",
		"resources.methodology": "translation-fr.json",
		"resources.playbook": "translation-fr.json",
		"resources.policy": "translation-fr.json",
		"resources.secondaryhero": "translation-fr.json",
		"resources.tool": "translation-fr.json",
		"templates.error": "translation-fr.json",
		"templates.notfound": "translation-fr.json"
	},
	"fr-FR": {
		assetgraphic: "translation-fr.json",
		common: "translation-fr.json",
		"pages.contactform": "translation-fr.json",
		"pages.datainitiativepage": "translation-fr.json",
		"pages.focusarea": "translation-fr.json",
		"pages.gettingstartedpage": "translation-fr.json",
		"pages.homepage": "translation-fr.json",
		"pages.measurement": "translation-fr.json",
		"pages.scienceexchange": "translation-fr.json",
		"pages.searchresultspage": "translation-fr.json",
		"resources.casestudy": "translation-fr.json",
		"resources.collaborators": "translation-fr.json",
		"resources.data": "translation-fr.json",
		"resources.guidance": "translation-fr.json",
		"resources.methodology": "translation-fr.json",
		"resources.playbook": "translation-fr.json",
		"resources.policy": "translation-fr.json",
		"resources.secondaryhero": "translation-fr.json",
		"resources.tool": "translation-fr.json",
		"templates.error": "translation-fr.json",
		"templates.notfound": "translation-fr.json"
	},
	it: {
		assetgraphic: "translation-it.json",
		common: "translation-it.json",
		"pages.contactform": "translation-it.json",
		"pages.datainitiativepage": "translation-it.json",
		"pages.focusarea": "translation-it.json",
		"pages.gettingstartedpage": "translation-it.json",
		"pages.homepage": "translation-it.json",
		"pages.measurement": "translation-it.json",
		"pages.scienceexchange": "translation-it.json",
		"pages.searchresultspage": "translation-it.json",
		"resources.casestudy": "translation-it.json",
		"resources.collaborators": "translation-it.json",
		"resources.data": "translation-it.json",
		"resources.guidance": "translation-it.json",
		"resources.methodology": "translation-it.json",
		"resources.playbook": "translation-it.json",
		"resources.policy": "translation-it.json",
		"resources.secondaryhero": "translation-it.json",
		"resources.tool": "translation-it.json",
		"templates.error": "translation-it.json",
		"templates.notfound": "translation-it.json"
	},
	"it-IT": {
		assetgraphic: "translation-it.json",
		common: "translation-it.json",
		"pages.contactform": "translation-it.json",
		"pages.datainitiativepage": "translation-it.json",
		"pages.focusarea": "translation-it.json",
		"pages.gettingstartedpage": "translation-it.json",
		"pages.homepage": "translation-it.json",
		"pages.measurement": "translation-it.json",
		"pages.scienceexchange": "translation-it.json",
		"pages.searchresultspage": "translation-it.json",
		"resources.casestudy": "translation-it.json",
		"resources.collaborators": "translation-it.json",
		"resources.data": "translation-it.json",
		"resources.guidance": "translation-it.json",
		"resources.methodology": "translation-it.json",
		"resources.playbook": "translation-it.json",
		"resources.policy": "translation-it.json",
		"resources.secondaryhero": "translation-it.json",
		"resources.tool": "translation-it.json",
		"templates.error": "translation-it.json",
		"templates.notfound": "translation-it.json"
	},
	ja: {
		assetgraphic: "translation-ja.json",
		common: "translation-ja.json",
		"pages.contactform": "translation-ja.json",
		"pages.datainitiativepage": "translation-ja.json",
		"pages.focusarea": "translation-ja.json",
		"pages.gettingstartedpage": "translation-ja.json",
		"pages.homepage": "translation-ja.json",
		"pages.measurement": "translation-ja.json",
		"pages.scienceexchange": "translation-ja.json",
		"pages.searchresultspage": "translation-ja.json",
		"resources.casestudy": "translation-ja.json",
		"resources.collaborators": "translation-ja.json",
		"resources.data": "translation-ja.json",
		"resources.guidance": "translation-ja.json",
		"resources.methodology": "translation-ja.json",
		"resources.playbook": "translation-ja.json",
		"resources.policy": "translation-ja.json",
		"resources.secondaryhero": "translation-ja.json",
		"resources.tool": "translation-ja.json",
		"templates.error": "translation-ja.json",
		"templates.notfound": "translation-ja.json"
	},
	"ja-JP": {
		assetgraphic: "translation-ja.json",
		common: "translation-ja.json",
		"pages.contactform": "translation-ja.json",
		"pages.datainitiativepage": "translation-ja.json",
		"pages.focusarea": "translation-ja.json",
		"pages.gettingstartedpage": "translation-ja.json",
		"pages.homepage": "translation-ja.json",
		"pages.measurement": "translation-ja.json",
		"pages.scienceexchange": "translation-ja.json",
		"pages.searchresultspage": "translation-ja.json",
		"resources.casestudy": "translation-ja.json",
		"resources.collaborators": "translation-ja.json",
		"resources.data": "translation-ja.json",
		"resources.guidance": "translation-ja.json",
		"resources.methodology": "translation-ja.json",
		"resources.playbook": "translation-ja.json",
		"resources.policy": "translation-ja.json",
		"resources.secondaryhero": "translation-ja.json",
		"resources.tool": "translation-ja.json",
		"templates.error": "translation-ja.json",
		"templates.notfound": "translation-ja.json"
	},
	pt: {
		assetgraphic: "translation-pt.json",
		common: "translation-pt.json",
		"pages.contactform": "translation-pt.json",
		"pages.datainitiativepage": "translation-pt.json",
		"pages.focusarea": "translation-pt.json",
		"pages.gettingstartedpage": "translation-pt.json",
		"pages.homepage": "translation-pt.json",
		"pages.measurement": "translation-pt.json",
		"pages.scienceexchange": "translation-pt.json",
		"pages.searchresultspage": "translation-pt.json",
		"resources.casestudy": "translation-pt.json",
		"resources.collaborators": "translation-pt.json",
		"resources.data": "translation-pt.json",
		"resources.guidance": "translation-pt.json",
		"resources.methodology": "translation-pt.json",
		"resources.playbook": "translation-pt.json",
		"resources.policy": "translation-pt.json",
		"resources.secondaryhero": "translation-pt.json",
		"resources.tool": "translation-pt.json",
		"templates.error": "translation-pt.json",
		"templates.notfound": "translation-pt.json"
	},
	"pt-BR": {
		assetgraphic: "translation-pt.json",
		common: "translation-pt.json",
		"pages.contactform": "translation-pt.json",
		"pages.datainitiativepage": "translation-pt.json",
		"pages.focusarea": "translation-pt.json",
		"pages.gettingstartedpage": "translation-pt.json",
		"pages.homepage": "translation-pt.json",
		"pages.measurement": "translation-pt.json",
		"pages.scienceexchange": "translation-pt.json",
		"pages.searchresultspage": "translation-pt.json",
		"resources.casestudy": "translation-pt.json",
		"resources.collaborators": "translation-pt.json",
		"resources.data": "translation-pt.json",
		"resources.guidance": "translation-pt.json",
		"resources.methodology": "translation-pt.json",
		"resources.playbook": "translation-pt.json",
		"resources.policy": "translation-pt.json",
		"resources.secondaryhero": "translation-pt.json",
		"resources.tool": "translation-pt.json",
		"templates.error": "translation-pt.json",
		"templates.notfound": "translation-pt.json"
	},
	"pt-PT": {
		assetgraphic: "translation-pt.json",
		common: "translation-pt.json",
		"pages.contactform": "translation-pt.json",
		"pages.datainitiativepage": "translation-pt.json",
		"pages.focusarea": "translation-pt.json",
		"pages.gettingstartedpage": "translation-pt.json",
		"pages.homepage": "translation-pt.json",
		"pages.measurement": "translation-pt.json",
		"pages.scienceexchange": "translation-pt.json",
		"pages.searchresultspage": "translation-pt.json",
		"resources.casestudy": "translation-pt.json",
		"resources.collaborators": "translation-pt.json",
		"resources.data": "translation-pt.json",
		"resources.guidance": "translation-pt.json",
		"resources.methodology": "translation-pt.json",
		"resources.playbook": "translation-pt.json",
		"resources.policy": "translation-pt.json",
		"resources.secondaryhero": "translation-pt.json",
		"resources.tool": "translation-pt.json",
		"templates.error": "translation-pt.json",
		"templates.notfound": "translation-pt.json"
	},
	und: {
		assetgraphic: "translation.json",
		common: "translation.json",
		"pages.contactform": "translation.json",
		"pages.datainitiativepage": "translation.json",
		"pages.focusarea": "translation.json",
		"pages.gettingstartedpage": "translation.json",
		"pages.homepage": "translation.json",
		"pages.measurement": "translation.json",
		"pages.scienceexchange": "translation.json",
		"pages.searchresultspage": "translation.json",
		"resources.casestudy": "translation.json",
		"resources.collaborators": "translation.json",
		"resources.data": "translation.json",
		"resources.guidance": "translation.json",
		"resources.methodology": "translation.json",
		"resources.playbook": "translation.json",
		"resources.policy": "translation.json",
		"resources.secondaryhero": "translation.json",
		"resources.tool": "translation.json",
		"templates.error": "translation.json",
		"templates.notfound": "translation.json"
	},
	zh: {
		assetgraphic: "translation-zh.json",
		common: "translation-zh.json",
		"pages.contactform": "translation-zh.json",
		"pages.datainitiativepage": "translation-zh.json",
		"pages.focusarea": "translation-zh.json",
		"pages.gettingstartedpage": "translation-zh.json",
		"pages.homepage": "translation-zh.json",
		"pages.measurement": "translation-zh.json",
		"pages.scienceexchange": "translation-zh.json",
		"pages.searchresultspage": "translation-zh.json",
		"resources.casestudy": "translation-zh.json",
		"resources.collaborators": "translation-zh.json",
		"resources.data": "translation-zh.json",
		"resources.guidance": "translation-zh.json",
		"resources.methodology": "translation-zh.json",
		"resources.playbook": "translation-zh.json",
		"resources.policy": "translation-zh.json",
		"resources.secondaryhero": "translation-zh.json",
		"resources.tool": "translation-zh.json",
		"templates.error": "translation-zh.json",
		"templates.notfound": "translation-zh.json"
	},
	"zh-CN": {
		assetgraphic: "translation-zh.json",
		common: "translation-zh.json",
		"pages.contactform": "translation-zh.json",
		"pages.datainitiativepage": "translation-zh.json",
		"pages.focusarea": "translation-zh.json",
		"pages.gettingstartedpage": "translation-zh.json",
		"pages.homepage": "translation-zh.json",
		"pages.measurement": "translation-zh.json",
		"pages.scienceexchange": "translation-zh.json",
		"pages.searchresultspage": "translation-zh.json",
		"resources.casestudy": "translation-zh.json",
		"resources.collaborators": "translation-zh.json",
		"resources.data": "translation-zh.json",
		"resources.guidance": "translation-zh.json",
		"resources.methodology": "translation-zh.json",
		"resources.playbook": "translation-zh.json",
		"resources.policy": "translation-zh.json",
		"resources.secondaryhero": "translation-zh.json",
		"resources.tool": "translation-zh.json",
		"templates.error": "translation-zh.json",
		"templates.notfound": "translation-zh.json"
	},
	"zh-TW": {
		assetgraphic: "translation-zh.json",
		common: "translation-zh.json",
		"pages.contactform": "translation-zh.json",
		"pages.datainitiativepage": "translation-zh.json",
		"pages.focusarea": "translation-zh.json",
		"pages.gettingstartedpage": "translation-zh.json",
		"pages.homepage": "translation-zh.json",
		"pages.measurement": "translation-zh.json",
		"pages.scienceexchange": "translation-zh.json",
		"pages.searchresultspage": "translation-zh.json",
		"resources.casestudy": "translation-zh.json",
		"resources.collaborators": "translation-zh.json",
		"resources.data": "translation-zh.json",
		"resources.guidance": "translation-zh.json",
		"resources.methodology": "translation-zh.json",
		"resources.playbook": "translation-zh.json",
		"resources.policy": "translation-zh.json",
		"resources.secondaryhero": "translation-zh.json",
		"resources.tool": "translation-zh.json",
		"templates.error": "translation-zh.json",
		"templates.notfound": "translation-zh.json"
	}
};
var arbManifest = {
	endpointMap: endpointMap
};

export { arbManifest as default, endpointMap };
