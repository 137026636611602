export enum ROUTES {
  ROOT = '/',
  GETTING_STARTED = '/getting-started',
  SEARCH = '/search',
  FOCUS_AREAS = '/focus-areas',
  SCIENCE_EXCHANGE = '/science-exchange',
  MEASUREMENT = '/measurement',
  DATA_INITIATIVE = '/data-initiative',
  CONTACT_US = '/contact-us',
  DEBORAH_INTEREST = '/credits/interest',
}
