import { FunctionComponent } from 'react';

import { AsxImage } from 'src/components/atoms';

import styles from './ImagesBackground.module.scss';

type ImagesBackgroundProps = {
  images: Array<{ src: string; alt: string }>;
};

export const ImagesBackground: FunctionComponent<ImagesBackgroundProps> = ({ images }: ImagesBackgroundProps) => (
  <div className={styles.imagesBackground}>
    {images.map((image, index) => {
      const key = `${index}-${image.src}`;

      // Added aria-hidden since these bg images don't convey relevant information for screen-reader users
      return <AsxImage aria-hidden className={styles.image} key={key} {...image} />;
    })}
  </div>
);
