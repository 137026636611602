export enum FOCUS_AREA_URL {
  CarbonFreeEnergy = 'carbon-free-energy',
  CarbonNeutralization = 'carbon-neutralization',
  Buildings = 'buildings',
  HumanRights = 'human-rights',
  Transportation = 'transportation',
  WasteAndCircularity = 'waste-and-circularity',
  WaterStewardship = 'water-stewardship',
}

export const FOCUS_AREA_PAGE_ID = {
  [FOCUS_AREA_URL.CarbonFreeEnergy]: 'carbon-freeenergy',
  [FOCUS_AREA_URL.CarbonNeutralization]: 'carbonneutralization',
  [FOCUS_AREA_URL.Buildings]: 'buildings',
  [FOCUS_AREA_URL.HumanRights]: 'humanrights',
  [FOCUS_AREA_URL.Transportation]: 'transportation',
  [FOCUS_AREA_URL.WasteAndCircularity]: 'wasteandcircularity',
  [FOCUS_AREA_URL.WaterStewardship]: 'waterstewardship',
};

const invertObject = (obj: Record<FOCUS_AREA_URL, string>): Record<string, string> =>
  Object.fromEntries(Object.entries(obj).map((a) => a.reverse()));

export const FOCUS_AREA_URL_BY_PAGE_ID = invertObject(FOCUS_AREA_PAGE_ID);
