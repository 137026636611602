import classNames, { Argument } from 'classnames';
import { FunctionComponent, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useBundle } from '@amzn/react-arb-tools';

import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms/primary-button/PrimaryButton';
import { BACK_BUTTON_LABEL, COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers/translation-helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './BackButton.module.scss';

interface BackButtonProps {
  variant: 'iconOnlyMobile' | 'default';
  className?: Argument;
}

export const BackButton: FunctionComponent<BackButtonProps> = ({ variant, className }: BackButtonProps) => {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();
  const navigate = useNavigate();
  const location = useLocation();
  const lastValidReferrer = useRef<string | null>(null);

  useEffect(() => {
    if (document.referrer && document.referrer.startsWith(window.location.origin)) {
      const referrerPath = new URL(document.referrer).pathname;
      if (!referrerPath.includes('/search')) {
        lastValidReferrer.current = referrerPath;
      }
    }
  }, []);

  const handleBackClick = () => {
    if (location.state && Object.hasOwn(location.state, 'previousUrl')) {
      const { previousUrl } = location.state;

      // If there's a referrer from internal site, navigate to the previous page in the site
      if (previousUrl && previousUrl.startsWith(window.location.origin)) {
        navigate(-1);
      }
    } else if (lastValidReferrer.current) {
      navigate(lastValidReferrer.current);
    } else {
      // Otherwise, navigate to the home site
      navigate('/');
    }
  };

  return variant === 'iconOnlyMobile' ? (
    <PrimaryButton
      data-testid="back-button"
      buttonSize={PRIMARY_BUTTON_SIZE.Small}
      icon="chevronLeft"
      label={isSmallDesktop ? t(commonBundle, BACK_BUTTON_LABEL) : undefined}
      theme={isSmallDesktop ? SECTION_THEME.Dark : SECTION_THEME.Light}
      variant={isSmallDesktop ? 'inverted' : 'default'}
      onClick={handleBackClick}
      iconPosition="before"
      className={classNames(styles.iconOnlyButton, className)}
    />
  ) : (
    <PrimaryButton
      data-testid="back-button"
      buttonSize={PRIMARY_BUTTON_SIZE.Small}
      icon="chevronLeft"
      label={t(commonBundle, BACK_BUTTON_LABEL)}
      theme={SECTION_THEME.Dark}
      variant="inverted"
      onClick={handleBackClick}
      iconPosition="before"
      className={classNames(styles.backButton, className)}
    />
  );
};
