import classNames from 'classnames';
import { ReactElement, useState, useEffect, ChangeEvent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { COMMON_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';

import styles from './Picker.module.scss';

type RangeOption = {
  label: string;
};

interface PickerProps {
  ariaLabel?: string;
  ranges: Array<RangeOption>;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Picker({ ariaLabel, ranges, onChange }: PickerProps): ReactElement {
  const [valueIndex, setValueIndex] = useState(0);
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueIndex(Number(event.target.value));
    onChange(event);
  };

  const handleStepClick = (stepIndex: number) => {
    setValueIndex(stepIndex);
    const event = {
      target: {
        value: stepIndex.toString(),
      },
    } as ChangeEvent<HTMLInputElement>;
    handleChange(event);
  };

  const handleStepKeyDown = (event: React.KeyboardEvent, stepIndex: number) => {
    if (event.key === 'Enter') {
      handleStepClick(stepIndex);
    }
  };

  useEffect(() => {
    const pickerElement = document.querySelector(`input[type="range"]`) as HTMLInputElement;

    if (pickerElement) {
      const percentage = (valueIndex / (ranges.length - 1)) * 100;
      // Update the track progress based on the current value
      pickerElement.style.setProperty('--slider-progress', `${percentage}%`);
    }
  }, [valueIndex, ranges.length]);

  useEffect(() => {
    // Trigger onChange with the default value
    const event = {
      target: {
        value: valueIndex.toString(),
      },
    } as ChangeEvent<HTMLInputElement>;
    onChange(event);
  });

  return (
    <div className={styles.pickerContainer}>
      <div className={styles.pickerWrapper}>
        <input
          type="range"
          min="0"
          max={ranges.length - 1}
          value={valueIndex} // Use index for value
          className={styles.picker}
          onChange={handleChange}
          step="1"
          aria-label={ariaLabel}
          aria-valuetext={ranges[valueIndex].label.replace('-', ' to ')}
        />
      </div>
      <div className={styles.pickerTextWrapper}>
        <p className={styles.pickerMinimum}>{ranges[0].label}</p>
        <p className={styles.pickerMaximum}>{ranges[ranges.length - 1].label}</p>
      </div>
      <div className={styles.stepMarkers}>
        {ranges.map((range, index) => (
          <div key={index} className={styles.stepMarker}>
            <p
              className={classNames(styles.stepLabel, index === valueIndex && styles.active)}
              data-testid={index === valueIndex && 'picker-active-indicator'}
            >
              {range.label.replace(` ${t(commonBundle, 'picker_employees')}`, '')}
            </p>
            <span
              className={classNames(styles.stepThumb, index === valueIndex && styles.active)}
              role="button"
              aria-label={range.label.replace('-', ' to ')}
              tabIndex={0}
              onClick={() => handleStepClick(index)}
              onKeyDown={(event) => handleStepKeyDown(event, index)}
            >
              {/* This inner thumb is the visual part of the step, which is smaller than the clickable area */}
              <span className={styles.innerThumb} />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
