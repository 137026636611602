import { useBundle } from '@amzn/react-arb-tools';

import { ErrorTemplate } from 'src/components/templates/error/ErrorTemplate';
import { NOT_FOUND_RESOURCE_BUNDLE_NAME } from 'src/constants';

export const NotFoundPage = () => {
  const [notFoundBundle] = useBundle(NOT_FOUND_RESOURCE_BUNDLE_NAME);

  return (
    <ErrorTemplate
      bundle={notFoundBundle}
      errorPillLabel="notFoundPage_errorLabel"
      titleLabel="notFoundPage_title"
      descriptionLabel="notFoundPage_description"
      linkLabel="notFoundPage_linkLabel"
      altText="notFoundPage_altText"
    />
  );
};
