import { FC } from 'react';

import styles from 'src/components/common/modal/content/list/ModalListContent.module.scss';

interface ModalList {
  heading?: string;
  elements: string[];
}

interface ModalListContentProps {
  list?: ModalList;
}

/**
 * Content component for the ModalWrapper.tsx, specifically for lists of elements.
 * @param list - The header and list of elements to be displayed in the modal content.
 */
export const ModalListContent: FC<ModalListContentProps> = ({ list }) => (
  <div className={styles.ModalListContainer}>
    <h3 className={styles.ModalListHeading}>{list?.heading}</h3>
    <ol className={styles.ModalList} aria-labelledby={list?.heading}>
      {list?.elements.map((element, index) => (
        <div key={element} className={styles.ModalListElement}>
          <span className={styles.ListElementIndex}>[0{index + 1}]</span>
          <span className={styles.ListElementString}>{element}</span>
        </div>
      ))}
    </ol>
  </div>
);
