import { CoralRequest, CoralInterceptor } from '@amzn/ihm-js-coral-client';

const getCSRFToken = (): string =>
  document.querySelector("meta[name='anti-csrftoken-a2z']")?.getAttribute('content') ?? '';

const addCSRFToken = (request: CoralRequest): CoralRequest =>
  request.clone({ headers: [...request.headers, ['anti-csrftoken-a2z', getCSRFToken()]] });

const createCSRFInterceptor = (): CoralInterceptor => ({
  intercept(request, next) {
    return next.handle(addCSRFToken(request));
  },
});

export const CSRFInterceptor = createCSRFInterceptor();
