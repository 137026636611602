import { BrowserRouter, Route, Routes } from 'react-router-dom';
import arbManifest from 'translations/arbManifest';

import { MbmProvider } from '@amzn/react-arb-tools';

import { SSSAPIClientProvider } from 'src/clients/SSSAPIClientProvider';
import { SearchClientProvider } from 'src/clients/SearchClientProvider';
import { getTreatment, WEBLAB_NAME, WEBLAB_TREATMENT } from 'src/clients/weblab-client';
import { CreditsInterestFormPage } from 'src/components/pages/credits/interest/CreditsInterestFormPage';
import { ROUTES } from 'src/data/enums/Routes';
import { getMbmOptions } from 'src/helpers';

import {
  DataInitiativePage,
  ErrorBoundary,
  HomePage,
  FocusAreaPage,
  GettingStartedPage,
  NotFoundPage,
  ScienceExchangePage,
  SearchResultsPage,
  MeasurementPage,
  ContactFormPage,
} from './pages';
import { FooterBanner } from './templates/footer-banner/FooterBanner';
import { HandleReferer, ScrollToSection } from './wrappers';

const mbmOptions = getMbmOptions(arbManifest);
const shouldRenderAsxDeborahFormLaunch = WEBLAB_TREATMENT.T1 === getTreatment(WEBLAB_NAME.ASX_DEBORAH_FORM_LAUNCH);

export const App = () => (
  <MbmProvider {...mbmOptions}>
    <div className="ash-theme">
      <BrowserRouter>
        <ErrorBoundary>
          <ScrollToSection />
          <HandleReferer>
            <Routes>
              <Route path={ROUTES.ROOT} element={<HomePage />} />
              <Route path={`${ROUTES.FOCUS_AREAS}/:focusAreaUrl`} element={<FocusAreaPage />} />
              <Route path={ROUTES.GETTING_STARTED} element={<GettingStartedPage />} />
              <Route path={ROUTES.SCIENCE_EXCHANGE} element={<ScienceExchangePage />} />
              <Route path={ROUTES.MEASUREMENT} element={<MeasurementPage />} />
              <Route path={ROUTES.DATA_INITIATIVE} element={<DataInitiativePage />} />
              <Route
                path={ROUTES.SEARCH}
                element={
                  <SearchClientProvider>
                    <SearchResultsPage />
                  </SearchClientProvider>
                }
              />
              <Route
                path={ROUTES.CONTACT_US}
                element={
                  <SSSAPIClientProvider>
                    <ContactFormPage />
                  </SSSAPIClientProvider>
                }
              />
              {shouldRenderAsxDeborahFormLaunch && (
                <Route
                  path={ROUTES.DEBORAH_INTEREST}
                  element={
                    <SSSAPIClientProvider>
                      <CreditsInterestFormPage />
                    </SSSAPIClientProvider>
                  }
                />
              )}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <FooterBanner />
          </HandleReferer>
        </ErrorBoundary>
      </BrowserRouter>
    </div>
  </MbmProvider>
);
