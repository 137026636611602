import classNames, { Argument } from 'classnames';
import { FunctionComponent } from 'react';

import { FormattedLink, Icon } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './FormEntryPointCard.module.scss';

type FormEntryPointCardProps = {
  className?: Argument;
  theme?: SECTION_THEME;
  variant?: 'stories' | 'resources';
  formUrl: string;
};

export const FormEntryPointCard: FunctionComponent<FormEntryPointCardProps> = ({
  className,
  theme = SECTION_THEME.Dark,
  variant = 'stories',
  formUrl,
}) => {
  const { isSmallDesktop } = useDeviceSize();
  const { commonBundle } = useCommonStrings();

  return (
    <div className={classNames(className, styles.formEntryPointCard, styles[theme])}>
      <div className={classNames(styles.iconWrapper, styles[theme])}>
        <Icon name="union" className={classNames(styles.icon, styles[theme])} />
      </div>
      <div className={styles.textWrapper}>
        {isSmallDesktop && (
          <p className={classNames(styles.subtitle, styles[theme])}>
            {variant === 'resources'
              ? t(commonBundle, 'formEntryPoint_title_resources') ?? ''
              : t(commonBundle, 'formEntryPoint_title_stories') ?? ''}
          </p>
        )}
        <FormattedLink
          text={
            variant === 'resources'
              ? t(commonBundle, 'formEntryPoint_linkLabel_resources') ?? ''
              : t(commonBundle, 'formEntryPoint_linkLabel_stories') ?? ''
          }
          iconName="chevronRight"
          className={classNames(styles.link, styles[theme])}
          bold
          isInternal
          link={formUrl}
        />
      </div>
    </div>
  );
};
