import classNames from 'classnames';
import { FunctionComponent, PropsWithChildren } from 'react';

import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './TeaserCard.module.scss';

interface TeaserCardProps extends PropsWithChildren, React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  theme?: SECTION_THEME;
}

export const TeaserCard: FunctionComponent<TeaserCardProps> = ({
  title,
  children,
  theme = SECTION_THEME.Dark,
  ...props
}) => (
  <div className={classNames(styles.TeaserCard, styles[theme])} {...(props as React.HTMLAttributes<HTMLDivElement>)}>
    <h3 className={styles.title}>{title}</h3>
    <div className={styles.imagesWrapper}>{children}</div>
  </div>
);
