import alternativeFuels from 'src/components/atoms/icon/svg/alternative-fuels.svg';
import arrowDown from 'src/components/atoms/icon/svg/arrow-down.svg';
import arrowUp from 'src/components/atoms/icon/svg/arrow-up.svg';
import building from 'src/components/atoms/icon/svg/building.svg';
import check from 'src/components/atoms/icon/svg/check.svg';
import chevronDown from 'src/components/atoms/icon/svg/chevron-down.svg';
import chevronLeft from 'src/components/atoms/icon/svg/chevron-left.svg';
import chevronRight from 'src/components/atoms/icon/svg/chevron-right.svg';
import chevronTopRight from 'src/components/atoms/icon/svg/chevron-top-right.svg';
import close from 'src/components/atoms/icon/svg/close.svg';
import companyProfile from 'src/components/atoms/icon/svg/company-profile.svg';
import dashedBorder from 'src/components/atoms/icon/svg/dashed-border.svg';
import download from 'src/components/atoms/icon/svg/download.svg';
import error from 'src/components/atoms/icon/svg/error.svg';
import eye from 'src/components/atoms/icon/svg/eye.svg';
import info from 'src/components/atoms/icon/svg/info.svg';
import job from 'src/components/atoms/icon/svg/job.svg';
import library from 'src/components/atoms/icon/svg/library.svg';
import link from 'src/components/atoms/icon/svg/link.svg';
import logo from 'src/components/atoms/icon/svg/logo.svg';
import mail from 'src/components/atoms/icon/svg/mail.svg';
import menu from 'src/components/atoms/icon/svg/menu.svg';
import minus from 'src/components/atoms/icon/svg/minus.svg';
import packaging from 'src/components/atoms/icon/svg/packaging.svg';
import paper from 'src/components/atoms/icon/svg/paper.svg';
import plus from 'src/components/atoms/icon/svg/plus.svg';
import profile from 'src/components/atoms/icon/svg/profile.svg';
import progressDots from 'src/components/atoms/icon/svg/progress-dots.svg';
import resize from 'src/components/atoms/icon/svg/resize.svg';
import roundCheck from 'src/components/atoms/icon/svg/round-check.svg';
import screen from 'src/components/atoms/icon/svg/screen.svg';
import section01 from 'src/components/atoms/icon/svg/section-01.svg';
import tcp from 'src/components/atoms/icon/svg/tcp.svg';
import triangleDots from 'src/components/atoms/icon/svg/triangle-dots.svg';
import union from 'src/components/atoms/icon/svg/union.svg';
import wall from 'src/components/atoms/icon/svg/wall.svg';

export const icons = {
  alternativeFuels,
  arrowDown,
  arrowUp,
  building,
  check,
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronTopRight,
  close,
  companyProfile,
  dashedBorder,
  download,
  error,
  eye,
  info,
  job,
  library,
  link,
  logo,
  mail,
  packaging,
  progressDots,
  resize,
  screen,
  section01,
  tcp,
  triangleDots,
  wall,
  menu,
  paper,
  minus,
  plus,
  union,
  profile,
  roundCheck,
};

export type IconType = keyof typeof icons;
