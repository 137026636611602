// Locally stored keys should be prefixed with "ASH" and registerd in the following enum type
export enum LOCAL_STORAGE_KEYS {
  ASH_GETTING_STARTED_FILTERS,
}

export const getLocalValue = (key: LOCAL_STORAGE_KEYS) => localStorage.getItem(LOCAL_STORAGE_KEYS[key]);

export const setLocalValue = (key: LOCAL_STORAGE_KEYS, value: string) =>
  localStorage.setItem(LOCAL_STORAGE_KEYS[key], value);

export const clearLocaValue = (key: LOCAL_STORAGE_KEYS) => localStorage.removeItem(LOCAL_STORAGE_KEYS[key]);
