import { katTranslator } from '../../shared/i18n';

const strings = {
  "katal-carousel-default-aria-label": {
    "en-US": "Carousel of items below",
    "ar-AE": "عرض دوّار للمنتجات أدناه",
    "bn-IN": "নীচের আইটেমেগুলির ক্যারোজেল",
    "de-DE": "Karussell der unten aufgeführten Artikel",
    "en-GB": "Carousel of products below",
    "en-SG": "Carousel of products below",
    "en-ZA": "Carousel of products below",
    "es-ES": "Carrusel de productos a continuación",
    "es-MX": "Carrusel de los siguientes artículos",
    "fr-BE": "Liste des articles ci-dessous",
    "fr-FR": "Carrousel des articles ci-dessous",
    "gu-IN": "પ્રોડક્ટ કરાઉઝલ નીચે છે",
    "hi-IN": "नीचे दिए गए आइटम का कैरोसेल",
    "it-IT": "Slideshow articoli di seguito",
    "ja-JP": "以下の商品のカルーセル",
    "kn-IN": "ಕೆಳಗಿನ ಐಟಂಗಳ ಮೇಲಾಟ",
    "ko-KR": "아래 상품의 캐러셀",
    "ml-IN": "ചുവടെയുള്ള ഇനങ്ങളുടെ കറൗസൽ",
    "mr-IN": "खालील आयटमचे कॅरोसेल",
    "nl-BE": "Carrousel van items hieronder",
    "nl-NL": "Itemcarousel hieronder",
    "pl-PL": "Karuzela z produktami poniżej",
    "pt-BR": "Carrossel de itens abaixo",
    "pt-PT": "Carrossel de produtos abaixo",
    "sv-SE": "Karusell med artiklar nedan",
    "ta-IN": "கீழே உள்ள ஐட்டங்களின் கரோசல்",
    "te-IN": "దిగువ అంశాల కరౌసెల్",
    "th-TH": "ภาพสไลด์ของสินค้าด้านล่าง",
    "tr-TR": "Aşağıdaki ürünlerin döner vitrini",
    "vi-VN": "Carousel (dạng xem băng chuyền) cho các mục hàng bên dưới",
    "zh-CN": "以下商品轮播",
    "zh-TW": "下面的 Carousel 商品"
  },
  "katal-carousel-default-image-link-label": {
    "en-US": "Read More",
    "ar-AE": "قراءة المزيد",
    "bn-IN": "আরও পড়ুন",
    "de-DE": "Weitere Informationen",
    "en-AU": "Read more",
    "en-CA": "Read more",
    "en-GB": "Read more",
    "en-SG": "Read more",
    "en-ZA": "Read more",
    "es-ES": "Más información",
    "es-MX": "Seguir leyendo",
    "fr-BE": "En savoir plus",
    "fr-FR": "En savoir plus",
    "gu-IN": "વધુ વાંચો",
    "hi-IN": "ज़्यादा जानकारी पढ़ें",
    "it-IT": "Leggi di più",
    "ja-JP": "詳細はこちら",
    "kn-IN": "ಇನ್ನಷ್ಟು ಓದಿ",
    "ko-KR": "자세히 알아보기",
    "ml-IN": "കൂടുതൽ വായിക്കുക",
    "mr-IN": "आणखी वाचा",
    "nl-BE": "Meer lezen",
    "nl-NL": "Meer lezen",
    "pl-PL": "Więcej informacji",
    "pt-BR": "Leia mais",
    "pt-PT": "Ler mais",
    "sv-SE": "Läs mer",
    "ta-IN": "மேலும் வாசிக்கவும்",
    "te-IN": "మరింత చదవండి",
    "th-TH": "อ่านเพิ่มเติม",
    "tr-TR": "Daha fazla bilgi",
    "vi-VN": "Đọc thêm",
    "zh-CN": "了解更多信息",
    "zh-TW": "閱讀更多"
  }
}; 

export default katTranslator(strings);
    