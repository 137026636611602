import { type ReactElement } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import { Tagline } from 'src/components/atoms';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { t } from 'src/helpers';

import styles from './PrimaryHero.module.scss';

type PrimaryHeroProps = {
  label?: string;
  heading: string;
  description?: string;
  image: string;
};

export function PrimaryHero({ label, heading, image, description }: PrimaryHeroProps): ReactElement {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  return (
    <div className={styles.PrimaryHero} style={{ backgroundImage: `url(${image})` }}>
      <div className={styles.mainBox}>
        <div className={styles.mainCard}>
          {label && <Tagline className={styles.tagline}>{label}</Tagline>}
          <h2 className={styles.heading}>{heading}</h2>
          {description && (
            <div className={styles.cardFooter}>
              <p className={styles.label}>{description}</p>
            </div>
          )}
        </div>
      </div>

      <p className={styles.scrollDownLabel}>{t(commonBundle, 'primaryHeroScroll')}</p>
    </div>
  );
}
