import classNames from 'classnames';
import React, { type ReactElement, useState, useCallback } from 'react';

import { IconType, Accordion, type AccordionProps, PrimaryButton, PRIMARY_BUTTON_SIZE } from 'src/components/atoms';
import { GridImage } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './MainBanner.module.scss';

interface MainBannerProps extends React.HTMLAttributes<HTMLDivElement> {
  theme?: SECTION_THEME;
  title?: string;
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  subtitle?: string;
  accordions?: Array<AccordionProps>;
  cta?: {
    icon: IconType;
    label: string;
    href: string;
    target?: string;
  };
  imageUrl: string;
  alt?: string;
  className?: string;
}

export function MainBanner({
  theme = SECTION_THEME.Dark,
  title,
  subtitle,
  accordions,
  cta,
  imageUrl,
  alt,
  className,
  titleSize = 'h3',
  ...props
}: MainBannerProps): ReactElement {
  const { isSmallDesktop } = useDeviceSize();

  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0);

  const TitleTag = React.createElement(titleSize, { className: classNames(styles.title, styles[theme]) }, title);

  const handleAccordionClick = useCallback((index: number) => {
    setOpenAccordionIndex((previousIndex) => (previousIndex === index ? -1 : index));
  }, []);

  const onAccordionClick = useCallback(
    (index: number) => () => {
      handleAccordionClick(index);
    },
    [handleAccordionClick]
  );

  return (
    <div
      className={classNames(styles.MainBanner, styles[theme], !subtitle && styles.noSubtitle, className)}
      {...(props as React.HTMLAttributes<HTMLDivElement>)}
    >
      {TitleTag}
      {subtitle && <p className={classNames(styles.subtitle, styles[theme])}>{subtitle}</p>}
      <div className={styles.accordionsWrapper}>
        <div className={styles.accordionsList}>
          {accordions?.map((accordion, index) => {
            const key = `${accordion.title}-${index}`;
            return (
              <Accordion
                key={key}
                theme={theme}
                isOpen={index === openAccordionIndex}
                onClick={onAccordionClick(index)}
                {...accordion}
              />
            );
          })}
        </div>
        {cta && (
          <PrimaryButton
            className={styles.cta}
            link={cta.href}
            target={cta.target ? cta.target : '_blank'}
            icon={cta.icon}
            label={cta.label}
            theme={theme}
            buttonSize={isSmallDesktop ? PRIMARY_BUTTON_SIZE.Regular : PRIMARY_BUTTON_SIZE.Small}
            isBold
          />
        )}
      </div>
      <GridImage
        className={styles.gridImage}
        key={`${imageUrl}-${alt}`}
        imageUrl={imageUrl}
        alt={alt ?? ''}
        theme={theme}
      />
    </div>
  );
}
