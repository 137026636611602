import classNames from 'classnames';
import { type ReactElement } from 'react';

import { IconType, icons, AsxImage, FormattedLink } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './SecondaryHero.module.scss';

export type SecondaryHeroProps = {
  theme?: SECTION_THEME;
  copy?: string;
  heading?: string;
  link?: {
    text: string;
    url: string;
    iconName?: IconType;
  };
  image: {
    alt?: string;
    src: string;
  };
};

export function SecondaryHero({
  copy,
  heading,
  link,
  image,
  theme = SECTION_THEME.Light,
}: SecondaryHeroProps): ReactElement {
  const { isSmallDesktop } = useDeviceSize();

  return (
    <div className={classNames(styles.SecondaryHero, styles[theme])}>
      <div className={styles.flexContent}>
        {heading && <h1 className={styles.heading}>{heading}</h1>}
        {isSmallDesktop ? (
          <div className={styles.copyContainer}>
            {copy && <p className={styles.copy}>{copy}</p>}
            {link && (
              <FormattedLink
                className={styles.externalLink}
                text={link.text}
                link={link.url}
                showIcon
                iconName={link.iconName ? link.iconName : (icons.chevronTopRight as IconType)}
                openNewTab
              />
            )}
          </div>
        ) : (
          <div className={styles.imageWrapper}>
            <AsxImage key={`${image.src}-${image.alt}`} src={image.src} alt={image.alt} className={styles.image} />
          </div>
        )}
      </div>
      {isSmallDesktop ? (
        <div className={styles.imageWrapper}>
          <AsxImage key={`${image.src}-${image.alt}`} src={image.src} alt={image.alt} className={styles.image} />
        </div>
      ) : (
        <div className={styles.copyContainer}>
          {copy && <p className={styles.copy}>{copy}</p>}
          {link && (
            <FormattedLink
              className={styles.externalLink}
              text={link.text}
              link={link.url}
              showIcon
              iconName={link.iconName ? link.iconName : 'chevronTopRight'}
              openNewTab
            />
          )}
        </div>
      )}
    </div>
  );
}
