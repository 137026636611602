import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

import { KatModal } from '@amzn/katal-react';
import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import styles from './ModalWrapper.module.scss';

enum MODAL_VARIANT {
  info = 'info',
  form = 'form',
}

export interface ModalProps extends PropsWithChildren {
  isVisible: boolean;
  close: () => void;
  title?: string;
  subtitle?: string;
  variant?: MODAL_VARIANT;
}

/**
 * This component is a wrapper around KatModal. It includes styling and accessibility for the Modal container,
 * and can accept the inner modal content as children.
 * @param isVisible - boolean value indicating if the modal is visible
 * @param close - function that is triggered onClose
 * @param title - string value for the title of the modal
 * @param subtitle - string value for the subtitle of the modal
 * @param children - inner content of the modal
 * @param variant - type of modal container styling that applies
 */
// TODO create storybook stories for new Modal components https://i.amazon.com/issues/ASX-244
export const ModalWrapper: FunctionComponent<ModalProps> = ({
  isVisible,
  close,
  title,
  subtitle,
  children,
  variant = MODAL_VARIANT.info,
}) => {
  const isMediumDevice = useMediaQuery({ query: `(max-width: ${variables.mediumDevice})` });

  useEffect(() => {
    const modal = document.querySelector('kat-modal');
    const modalShadowRoot = modal?.shadowRoot;

    if (isVisible && modalShadowRoot) {
      const modalBody: HTMLDivElement | null = modalShadowRoot.querySelector('.body');
      const closeButton = modalShadowRoot.querySelector('button') as HTMLButtonElement;

      if (modalBody && closeButton) {
        // Make the modalBody focusable so is keyboard-scrollable
        modalBody.setAttribute('tabindex', '0');
        closeButton.focus();

        // Prevent background scrolling
        document.body.style.overflow = 'hidden';

        // Focus management
        const focusableElements = [closeButton, modalBody] as Array<HTMLElement>;
        let currentFocusIndex = 0;

        const handleTabKey = (event: KeyboardEvent) => {
          // istanbul ignore if
          if (event.key === 'Tab') {
            event.preventDefault();

            if (event.shiftKey) {
              // Move focus backward (Shift + Tab)
              currentFocusIndex = (currentFocusIndex - 1 + focusableElements.length) % focusableElements.length;
            } else {
              // Move focus forward (Tab)
              currentFocusIndex = (currentFocusIndex + 1) % focusableElements.length;
            }

            // Set focus to the new element
            focusableElements[currentFocusIndex].focus();
          }
        };

        document.addEventListener('keydown', handleTabKey);

        return () => {
          // Cleanup when modal is closed
          document.body.style.overflow = '';
          modalBody.setAttribute('tabindex', '-1');

          document.removeEventListener('keydown', handleTabKey);
        };
      }
    }

    // Explicitly return undefined if conditions are not met
    return undefined;
  }, [isVisible]);

  useEffect(() => {
    const modal = document.querySelector('kat-modal');
    const modalShadowRoot = modal?.shadowRoot;

    if (modalShadowRoot) {
      const modalContainer: HTMLDivElement | null = modalShadowRoot.querySelector('.container');
      if (modalContainer) {
        modalContainer.style.backdropFilter = 'blur(12px)';
        if (variant === MODAL_VARIANT.info) {
          modalContainer.style.alignItems = 'flex-start';
        }
        modalContainer.style.padding = '8px';
        if (isMediumDevice) {
          // Since until 768px our modal is essentially in "mobile" layout, we want to align it at the bottom of the screen
          modalContainer.style.justifyContent = 'flex-end';
        }
      }
    }

    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isMediumDevice, variant]);

  useEffect(() => {
    const modal = document.querySelector('kat-modal') as HTMLElement | null;

    if (modal) {
      const titleElement = document.getElementById('modal-title');

      if (titleElement) {
        // Use a minimal timeout to ensure the content is ready
        const timeoutId = setTimeout(() => {
          modal.setAttribute('aria-labelledby', titleElement.innerText);
        }, 0);

        return () => clearTimeout(timeoutId); // Clean up to avoid memory leaks
      }
    }
    // Explicitly return undefined if no cleanup is required
    return undefined;
  }, [isVisible]);

  return (
    <KatModal
      className={variant === MODAL_VARIANT.info ? styles.ModalWrapperList : styles.ModalWrapperForm}
      visible={isVisible}
      onClose={close}
    >
      <span slot="title" />
      <h2 className={styles.Title} id="modal-title">
        {title}
      </h2>
      <p className={styles.Subtitle}>{subtitle}</p>
      {children}
    </KatModal>
  );
};
