export const DEFAULT_LOCALE = 'en-US';
export const MAX_SEARCH_QUERY_LENGTH = 100;
// Search Panther Labels
export const MAIN_SEARCH_RESULTS_LABEL = 'mainSearchResults_label';
export const MAIN_SEARCH_RESULTS_LABEL_SINGULAR = 'mainSearchResultsSingular_label';
export const NO_SEARCH_RESULTS_LABEL = 'noSearchResults_label';
export const SEARCH_ERROR_LABEL = 'searchError_label';
export const SEARCH_TITLE_LABEL = 'searchTitle_label';
export const CLEAR_BUTTON_LABEL = 'searchClearButton_label';
export const SEARCH_BUTTON_LABEL = 'searchButton_label';
export const SEARCH_PLACEHOLDER_LABEL = 'searchPlaceholder_label';
export const NO_SEARCH_RESULTS_ACTION_LABEL = 'noSearchResultsAction_label';
export const GETTING_STARTED_LINK_LABEL = 'gettingStartedLink_label';
export const SCROLL_TO_TOP_LABEL = 'scrollToTop_label';
export const COLLAPSED_SEARCH_PLACEHOLDER_LABEL = 'collapsedSearchPlaceholder_label';
