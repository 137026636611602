import { useMemo } from 'react';

import { Page, SectionType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { useBundle } from '@amzn/react-arb-tools';

import { COLLABORATORS_RESOURCE_BUNDLE_NAME, COMMON_BUNDLE_NAME, FOCUS_AREA_PAGES_BUNDLE_NAME } from 'src/constants';
import AllResources from 'src/fixtures/structures/resources';
import { getSectionsByType } from 'src/helpers';

export const useFocusAreaSections = (page?: Page) => {
  const [commonBundle, isCommonBundleLoading] = useBundle(COMMON_BUNDLE_NAME);
  const [focusAreaBundle, isFocusAreaBundleLoading] = useBundle(FOCUS_AREA_PAGES_BUNDLE_NAME);
  const [collaboratorsBundle, isCollaboratorsBundleLoading] = useBundle(COLLABORATORS_RESOURCE_BUNDLE_NAME);

  return useMemo(() => {
    if (!page) return {};

    // OUR PARTNERS SECTION
    const [collaboratorsSection] = getSectionsByType(page.sections, SectionType.COLLABORATORS);
    const collaboratorsResources = collaboratorsSection
      ? collaboratorsSection.resources.map((id) => AllResources[id])
      : [];

    const isBundleLoading = isCommonBundleLoading || isFocusAreaBundleLoading || isCollaboratorsBundleLoading;

    return {
      isBundleLoading,
      collaboratorsBundle,
      collaboratorsSection,
      collaboratorsResources,
      focusAreaBundle,
      commonBundle,
    };
  }, [
    page,
    isCommonBundleLoading,
    isFocusAreaBundleLoading,
    isCollaboratorsBundleLoading,
    collaboratorsBundle,
    focusAreaBundle,
    commonBundle,
  ]);
};
