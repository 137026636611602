import classNames, { type Argument } from 'classnames';
import { ReactNode, FunctionComponent } from 'react';

import styles from './Heading.module.scss';

export enum HEADING_SIZE {
  SubsectionSize = 'subsectionSize',
  LargeSubsectionSize = 'largeSubsectionSize',
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
}

export type HeadingProps = Partial<Omit<HTMLHeadingElement, 'children'>> & {
  children: string | ReactNode;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: HEADING_SIZE;
  className?: Argument;
  /* Optional boolean to remove heading from accessibility tree */
  isAriaHidden?: boolean;
};

export const Heading: FunctionComponent<HeadingProps> = ({
  className,
  as: Tag = 'h2',
  size = HEADING_SIZE.SubsectionSize,
  isAriaHidden,
  children,
}) => (
  <Tag className={classNames(styles.heading, styles[size], className)} aria-hidden={Boolean(isAriaHidden)}>
    {children}
  </Tag>
);
