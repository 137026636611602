import { useState, useCallback } from 'react';

import { toError } from 'src/logger';

type ApiCallState<T> = {
  isLoading: boolean;
  error?: Error | null;
  response: T | null;
  callApi: (apiCall: (...args: any[]) => Promise<T>, ...args: any[]) => void;
};

/**
 * useApi is a custom React hook that provides a way to manage API calls and their state.
 *
 * @template T - The type of the response data expected from the API call.
 *
 * @returns An object with the following properties:
 *   - isLoading: A boolean indicating whether an API call is currently in progress.
 *   - error: An Error object or null representing any error that occurred during the API call.
 *   - response: The response data from the API call, or null if no call has been made or if an error occurred.
 *   - callApi: A function that takes an API call function and its arguments, and executes the API call while managing the loading, error, and response state.
 *
 * Usage:
 * const { isLoading, error, response, callApi } = useApi<APIResponseType>();
 */
export const useApi = <T>(customErrorHandler?: (err: Error) => void): ApiCallState<T> => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [response, setResponse] = useState<T | null>(null);

  const callApi = useCallback(
    async (apiCall: (...args: any[]) => Promise<T>, ...args: any[]) => {
      setIsLoading(true);
      setError(null);

      try {
        const result = await apiCall(...args);
        setResponse(result);
      } catch (err: any) {
        if (customErrorHandler) {
          customErrorHandler(err);
        } else {
          setError(toError(err));
        }
      } finally {
        setIsLoading(false);
      }
    },
    [customErrorHandler]
  );

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return { isLoading, error, response, callApi };
};
