import classNames from 'classnames';
import React, { ReactElement } from 'react';

import { KatCard } from '@amzn/katal-react';
import { useBundle } from '@amzn/react-arb-tools';
import base from '@amzn/sss-website-theme/dist/theme/base.module.scss';

import { PrimaryButtonV2, PRIMARY_BUTTON_V2_SIZE } from 'src/components/atoms';
import { AsxImage } from 'src/components/atoms/asx-image/AsxImage';
import { IconType } from 'src/components/atoms/icons/icons';
import { COMMON_BUNDLE_NAME } from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import styles from './CardV2.module.scss';

interface CardLink {
  label: string;
  url: string;
}

export interface CardV2Props {
  title?: string;
  subtitle?: string;
  image?: string;
  alt?: string;
  pill?: string;
  details?: string;
  link?: CardLink;
  icon?: IconType;
  theme?: SECTION_THEME;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  imagePosition?: 'center' | 'right';
  openInSameTab?: boolean;
  skipAccessibleLabeling?: boolean;
}

export function CardV2({
  title,
  subtitle,
  link,
  image,
  alt,
  pill,
  details,
  icon,
  theme = SECTION_THEME.Light,
  headingSize = 'h3',
  imagePosition = 'center',
  openInSameTab,
  skipAccessibleLabeling,
}: CardV2Props): ReactElement {
  const [commonBundle] = useBundle(COMMON_BUNDLE_NAME);

  const HeadingTag = React.createElement(
    headingSize,
    { className: classNames(styles[theme], styles.cardHeading) },
    title
  );

  const handleClick = () => {
    if (link?.url) {
      if (openInSameTab) {
        window.location.href = link.url;
      } else {
        window.open(link.url, '_blank');
      }
    }
  };

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    // for accessibility reasons we need the link to be the only interactive element but since the whole card is clickable we are going to prevent the clicking of the <a>
    e.preventDefault();
  };

  const handleLinkKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <KatCard className={classNames(styles[theme], styles.card)} onClick={handleClick}>
      {(pill ?? details) && (
        <div className={styles.cardHeader} slot="title">
          {pill && <div className={classNames(styles[theme], styles.cardPill)}>{pill}</div>}
          {details && <div className={classNames(styles[theme], styles.details)}>{details}</div>}
        </div>
      )}
      <div className={classNames(styles[theme], styles.cardContent)}>
        <div className={styles.cardText}>
          {HeadingTag}
          <span className={styles.cardSubtitle}> {subtitle} </span>
        </div>

        {image && (
          <AsxImage
            className={styles.cardImage}
            src={image}
            alt={alt ?? ''}
            style={{ objectPosition: imagePosition }}
          />
        )}
      </div>
      <div className={classNames(styles[theme], styles.cardFooter)} slot="footer">
        {link && (
          <>
            <a
              className={classNames(styles.link, styles[theme])}
              href={link.url}
              target={openInSameTab ? undefined : '_blank'}
              rel={openInSameTab ? undefined : 'noreferrer'}
              onClick={handleLinkClick}
              onKeyDown={handleLinkKeyDown}
            >
              {link.label}
              {!skipAccessibleLabeling && (
                <span className={base.visuallyHidden}>
                  {`${t(commonBundle, 'card_about')} ${title}${openInSameTab ? '' : t(commonBundle, 'new_tab')}`}
                </span>
              )}
            </a>

            <PrimaryButtonV2
              className={styles.cardActionButton}
              icon={icon}
              buttonSize={PRIMARY_BUTTON_V2_SIZE.Small}
              theme={theme}
              hasHoverAnimation={false}
              tabIndex={-1}
              aria-hidden
            />
          </>
        )}
      </div>
    </KatCard>
  );
}
