import classNames from 'classnames';
import { type ReactElement } from 'react';

import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './GridSvg.module.scss';

type GridSvgProps = {
  theme?: SECTION_THEME;
};

export const GridSvg = ({ theme = SECTION_THEME.Dark }: GridSvgProps): ReactElement => {
  const { isSmallDesktop } = useDeviceSize();

  return (
    <svg className={classNames(styles.grid, styles[theme])} fill="none" xmlns="http://www.w3.org/2000/svg">
      {isSmallDesktop ? (
        <g>
          <path d="M32 0V288" stroke="currentColor" />
          <path d="M64 0V288" stroke="currentColor" />
          <path d="M96 0V288" stroke="currentColor" />
          <path d="M128 0V288" stroke="currentColor" />
          <path d="M160 0V288" stroke="currentColor" />
          <path d="M192 0V288" stroke="currentColor" />
          <path d="M224 0V288" stroke="currentColor" />
          <path d="M256 0V288" stroke="currentColor" />
          <path d="M288 0V288" stroke="currentColor" />
          <path d="M0 32H320" stroke="currentColor" />
          <path d="M0 64H320" stroke="currentColor" />
          <path d="M0 96H320" stroke="currentColor" />
          <path d="M0 128H320" stroke="currentColor" />
          <path d="M0 160H320" stroke="currentColor" />
          <path d="M0 192H320" stroke="currentColor" />
          <path d="M0 224H320" stroke="currentColor" />
          <path d="M0 256H320" stroke="currentColor" />
        </g>
      ) : (
        <g>
          <path d="M32 0V288" stroke="currentColor" />
          <path d="M64 0V288" stroke="currentColor" />
          <path d="M96 0V288" stroke="currentColor" />
          <path d="M128 0V288" stroke="currentColor" />
          <path d="M160 0V288" stroke="currentColor" />
          <path d="M192 0V288" stroke="currentColor" />
          <path d="M224 0V288" stroke="currentColor" />
          <path d="M0 32H320" stroke="currentColor" />
          <path d="M0 64H320" stroke="currentColor" />
          <path d="M0 96H320" stroke="currentColor" />
          <path d="M0 128H320" stroke="currentColor" />
          <path d="M0 160H320" stroke="currentColor" />
          <path d="M0 192H320" stroke="currentColor" />
        </g>
      )}
    </svg>
  );
};
