// eslint-disable-next-line no-restricted-imports
import { getLibraryDomainRoot } from 'src/helpers/library-helpers';

// Converts a string to sentence case (i.e where just the first letter of the string is capitalized
export const convertToSentenceCase = (str: string) =>
  str.toLowerCase().replace(/^\w/, (char) => char.toLocaleUpperCase());

export const formatUrl = (url?: string) => {
  if (!url) return '';
  // if the string is already a valid url, simply return
  return url.startsWith('http') || url.startsWith('www') ? url : `${getLibraryDomainRoot()}${url}`;
};

export const splitLastWordFromText = (copy: string) => {
  const initialCopyArr = copy.split(' ');
  const lastWord = initialCopyArr.pop();
  const initialCopy = initialCopyArr.join(' ');

  return [initialCopy, lastWord];
};

export const addRequiredLabelSymbol = (inputLabel: string) => `${inputLabel} *`;
