import { createContext, FunctionComponent, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { emitCountMetric, logger, truncateContext } from 'src/logger';
import { ORIGIN_DIMENSION, REFERER_ENCOUNTER } from 'src/logging-helpers/metrics-constants';

export const RefererContext = createContext<string | null>(null);
interface HandleRefererProps {
  children: React.ReactNode;
}

/**
 * Wrapper component that checks to see if the app is loaded with a ref tag (modeled as a "ref" query string).
 * If a ref tag is found, emit a metric.
 */
export const HandleReferer: FunctionComponent<HandleRefererProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [referer, setReferer] = useState<string | null>(null);

  useEffect(() => {
    const refString = searchParams.get('ref');
    if (refString) {
      setReferer(refString);
      const refContext = { [ORIGIN_DIMENSION]: refString };
      emitCountMetric(REFERER_ENCOUNTER, 1, refContext);
      logger.info('Referer tag found.', truncateContext(refContext));

      // clear out ref tag once captured to avoid emitting metric more than once per page ingress
      searchParams.delete('ref');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  return <RefererContext.Provider value={referer}>{children}</RefererContext.Provider>;
};
