export function isInViewport(
  element: HTMLElement,
  requiredPercentVisible = 0.8
) {
  const bounding = element.getBoundingClientRect();
  const allowedWidth = bounding.width * (1 - requiredPercentVisible);
  const allowedHeight = bounding.height * (1 - requiredPercentVisible);
  const { height: viewportHeight, width: viewportWidth } = getViewport();

  return (
    bounding.top >= -allowedHeight &&
    bounding.left >= -allowedWidth &&
    bounding.bottom <= viewportHeight + allowedHeight &&
    bounding.right <= viewportWidth + allowedWidth
  );
}

export function getViewport() {
  return {
    height: window.innerHeight || document.documentElement.clientHeight,
    width: window.innerWidth || document.documentElement.clientWidth,
  };
}
