import { useCallback, useEffect, useState } from 'react';
import { ReactElement } from 'react-markdown';
import { useLocation } from 'react-router-dom';

import { AsxFormType, ModuleIngress, PageIngress } from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';
import { useBundle } from '@amzn/react-arb-tools';

import { Dropdown, DropdownOption, Heading, HEADING_SIZE, PrimaryButton } from 'src/components/atoms';
import { CONTACT_FORM_KEYS } from 'src/components/pages/contact/ContactForm';
import { pageIngressToTopic } from 'src/components/pages/contact/form-helpers';
import { FOCUS_AREA_URL } from 'src/components/pages/resource-pages/focus-areas/focusAreaPath';
import { COMMON_BUNDLE_NAME, FORM_OVERRIDES_QUERY_PARAM, METADATA_QUERY_PARAM } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers';

import styles from './FooterBanner.module.scss';

const PAGE_INGRESS_BY_ROUTE: Record<string, PageIngress> = {
  [ROUTES.ROOT]: PageIngress.HOME_PAGE,
  [ROUTES.SEARCH]: PageIngress.SEARCH,
  [ROUTES.GETTING_STARTED]: PageIngress.GETTING_STARTED,
  [ROUTES.SCIENCE_EXCHANGE]: PageIngress.SCIENCE_EXCHANGE,
  [ROUTES.DATA_INITIATIVE]: PageIngress.DATA_INITIATIVE,
  [ROUTES.MEASUREMENT]: PageIngress.MEASUREMENT,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.Buildings}`]: PageIngress.BUILDINGS,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.CarbonFreeEnergy}`]: PageIngress.CARBON_FREE_ENERGY,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.CarbonNeutralization}`]: PageIngress.CARBON_NEUTRALIZATION,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.HumanRights}`]: PageIngress.HUMAN_RIGHTS,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.Transportation}`]: PageIngress.TRANSPORTATION,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.WasteAndCircularity}`]: PageIngress.WASTE_AND_CIRCULARITY,
  [`${ROUTES.FOCUS_AREAS}/${FOCUS_AREA_URL.WaterStewardship}`]: PageIngress.WATER_STEWARDSHIP,
};

export function FooterBanner(): ReactElement {
  const location = useLocation();
  const [bundle, isBundleLoading] = useBundle(COMMON_BUNDLE_NAME);

  const [updatedDropdownOptions, setUpdatedDropdownOptions] = useState<DropdownOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<string | null>();

  useEffect(() => {
    if (!isBundleLoading) {
      setUpdatedDropdownOptions([
        {
          text: t(bundle, 'footerBanner_dropdownOptionOne') ?? '',
          value: AsxFormType.GENERAL_FEEDBACK,
        },
        {
          text: t(bundle, 'footerBanner_dropdownOptionTwo') ?? '',
          value: AsxFormType.GENERAL_CONTENT_INTAKE,
        },
      ]);

      // Default to general feedback option
      setSelectedOption(AsxFormType.GENERAL_FEEDBACK);
    }
  }, [bundle, isBundleLoading]);

  const handleDropdownChange = useCallback(
    // Event type is any because it's a customEvent coming from Katal
    (event: any) => {
      const selectedDropdownOptionValue = event.detail.value;

      // Update the options by removing the 'icon' property from all
      // Then find the selected option and add the 'icon' property
      const newDropdownOptions: Array<DropdownOption> = updatedDropdownOptions.map((option) => ({
        ...option,
        icon: option.text === selectedDropdownOptionValue ? 'check' : undefined, // Apply icon only to the selected option
      }));

      setSelectedOption(selectedDropdownOptionValue);
      setUpdatedDropdownOptions(newDropdownOptions);
    },
    [updatedDropdownOptions]
  );

  const pageIngress = PAGE_INGRESS_BY_ROUTE[location.pathname];
  const topic = pageIngressToTopic[pageIngress];

  // Set up query param overrides that will be sent to contact page
  const queryStrings: Record<string, string> = {
    [FORM_OVERRIDES_QUERY_PARAM]: JSON.stringify({
      [CONTACT_FORM_KEYS.FORM_TYPE]: selectedOption,
      ...(topic && { [CONTACT_FORM_KEYS.FORM_TOPICS]: [topic] }),
    }),
    [METADATA_QUERY_PARAM]: JSON.stringify({
      // TODO: update with actual metadata keys and values once they are in the SSSAPI Model
      pageIngress,
      moduleIngress: ModuleIngress.FOOTER,
    }),
  };

  // Don't render the FooterBanner on the contact-us route.
  if (location.pathname === ROUTES.CONTACT_US) return <div />;

  return (
    <div className={styles.footerBannerWrapper}>
      <div className={styles.textWrapper}>
        <Heading className={styles.heading} size={HEADING_SIZE.h2}>
          {t(bundle, 'footerBanner_title')}
        </Heading>
        <p className={styles.description}>{t(bundle, 'footerBanner_description')}</p>
      </div>
      <div className={styles.dropdownWrapper}>
        <p className={styles.dropdownLabel}>{t(bundle, 'footerBanner_dropdownLabel')}</p>
        <Dropdown
          className={styles.dropdown}
          options={updatedDropdownOptions}
          onChange={handleDropdownChange}
          reversedIconOrder
        />
        <PrimaryButton
          className={styles.cta}
          theme={SECTION_THEME.Dark}
          label={t(bundle, 'footerBanner_ctaLabel') ?? ''}
          icon="chevronRight"
          role="button"
          contentClassName={styles.ctaContent}
          link={ROUTES.CONTACT_US}
          queryStrings={queryStrings}
        />
      </div>
    </div>
  );
}
