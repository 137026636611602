import classnames, { Argument } from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'src/components/atoms';
import { IconType } from 'src/components/atoms/icons/icons';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './PrimaryButton.module.scss';

export enum PRIMARY_BUTTON_SIZE {
  Regular = 'regularSize',
  Small = 'smallSize',
}

type PrimaryButtonBaseProps = {
  /**
   * String label displayed in button
   */
  label?: string;
  isBold?: boolean;
  /**
   * Optional icon to display in the button
   */
  icon?: IconType;
  highlightedIcon?: string;
  buttonSize?: PRIMARY_BUTTON_SIZE;
  /**
   * Optional display variant for the button
   */
  variant?: 'default' | 'inverted' | 'outlined';
  /**
   * Optional theme style to apply to the button
   */
  theme?: SECTION_THEME;
  className?: Argument;
  /**
   * Optional position string for icon displayed in the button
   */
  iconPosition?: 'after' | 'before';
  /**
   * Optional argument to add a class to the content of the button, it's different from className because this one targets the
   * inner div where the content/copy is, rather than targeting the outer anchor like className does
   */
  contentClassName?: Argument;
};

type PrimaryButtonProps = (
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
  | React.ButtonHTMLAttributes<HTMLButtonElement>
) &
  PrimaryButtonBaseProps & {
    /**
     * Optional link to open on button click
     */
    link?: string;
    /**
     * Optional query strings to add to the link
     */
    queryStrings?: Record<string, string>;
  };

/**
 * Main button element to be used on ASX. Supports themes and variations for different rendering styles.
 */
export const PrimaryButton: FC<PrimaryButtonProps> = ({
  label,
  isBold,
  icon,
  variant = 'default',
  theme = 'light',
  buttonSize = PRIMARY_BUTTON_SIZE.Regular,
  className,
  link,
  queryStrings,
  iconPosition = 'after',
  contentClassName,
  ...props
}) => {
  const commonProps = {
    className: classnames(
      className,
      styles.primaryButton,
      styles[variant],
      theme === 'dark' && styles.isDark,
      !label && styles.isOnlyIcon,
      styles[buttonSize],
      isBold && styles.bold,
      icon && label && styles.hasIcon
    ),
  };

  const content = (
    <div className={styles.buttonContent}>
      <div className={classnames(styles.primaryContent, contentClassName)}>
        {iconPosition === 'after' ? (
          <>
            {label && <span>{label}</span>}
            {icon && <Icon name={icon} className={styles.icon} />}
          </>
        ) : (
          <>
            {icon && <Icon name={icon} className={styles.icon} />}
            {label && <span>{label}</span>}
          </>
        )}
      </div>
      <div aria-hidden="true" className={classnames(styles.secondaryContent, contentClassName)}>
        {iconPosition === 'after' ? (
          <>
            {label && <span className={styles.label}>{label}</span>}
            {icon && <Icon name={icon} className={styles.icon} />}
          </>
        ) : (
          <>
            {icon && <Icon name={icon} className={styles.icon} />}
            {label && <span className={styles.label}>{label}</span>}
          </>
        )}
      </div>
    </div>
  );

  if (link) {
    // TODO: Refactor this into a "SmartLink" wrapper element which uses the correct native or react-router link
    const relativeLink = link.startsWith('/');
    const formattedLink = queryStrings ? `${link}?${new URLSearchParams(queryStrings).toString()}` : link;
    return !relativeLink ? (
      <a
        target="_blank"
        rel="noopener noreferrer"
        data-testid="primaryButton"
        href={formattedLink}
        {...commonProps}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {content}
      </a>
    ) : (
      <Link
        data-testid="primaryButton"
        to={formattedLink}
        {...commonProps}
        {...(props as React.AnchorHTMLAttributes<HTMLAnchorElement>)}
        state={{ previousUrl: window.location.href }}
      >
        {content}
      </Link>
    );
  }

  return (
    <button
      data-testid="primaryButton"
      type="button"
      {...commonProps}
      {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
    >
      {content}
    </button>
  );
};
