import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { Dropdown, DropdownOption, FilterTag } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './AccordionsComponent.module.scss';
import {
  AccordionsComponentContent,
  type AccordionsComponentContentProps,
} from './accordions-component-content/AccordionsComponentContent';

export type AccordionsComponentProps = {
  theme?: SECTION_THEME;
  variant?: string;
  content: Array<AccordionsComponentContentProps>;
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const AccordionsComponent: React.FC<AccordionsComponentProps> = ({
  content,
  theme = SECTION_THEME.Light,
  variant = 'full',
  titleSize = 'h4',
}) => {
  const [showContentIndex, setShowContentIndex] = useState<number>(0);
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number>(0);
  const { isLargeDevice } = useDeviceSize();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setActiveTab(0);
  }, []);

  const handleFilterTagClick = useCallback((index: number) => {
    setShowContentIndex(index);
    setOpenAccordionIndex(0);
  }, []);

  const handleAccordionClick = useCallback((index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? -1 : index));
  }, []);

  const handleDropdownChange = useCallback(
    (event: any) => {
      const e = event as { target: { value: string } };
      const selectedValue = e.target.value;
      const selectedIndex = content.findIndex((item) => item.cardTitle === selectedValue);
      setShowContentIndex(selectedIndex);
      setOpenAccordionIndex(0);
    },
    [content]
  );

  const dropdownOptions: DropdownOption[] = content.map((item) => ({
    text: item.cardTitle,
    icon: item.icon,
  }));

  return (
    <div className={styles.AccordionsComponent} data-testid="accordions-component">
      <div className={styles.contentWrapper}>
        {content.length > 1 &&
          (isLargeDevice ? (
            <div className={styles.filterTags} role="tablist">
              {content.map((item, index) => {
                const tabId = `tab-${index + 1}`;
                const panelId = `tabpanel-${index + 1}`;

                return (
                  <FilterTag
                    theme={theme}
                    key={item.cardTitle}
                    onClick={() => handleFilterTagClick(index)}
                    icon={item.icon}
                    label={item.cardTitle}
                    isActive={index === showContentIndex}
                    data-testid="filter-tag"
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                    index={index}
                    id={tabId}
                    aria-controls={panelId}
                  />
                );
              })}
            </div>
          ) : (
            <div className={classNames(styles.dropdownWrapper, styles[theme])}>
              <Dropdown
                className={styles.dropdown}
                options={dropdownOptions}
                value={dropdownOptions[showContentIndex].text}
                onChange={handleDropdownChange}
                data-testid="filter-dropdown"
              />
            </div>
          ))}
        <div
          className={styles.accordions}
          role={variant === 'reduced' ? undefined : 'tabpanel'}
          id={variant === 'reduced' ? undefined : `tabpanel-${showContentIndex + 1}`}
          aria-labelledby={variant === 'reduced' ? undefined : `tab-${showContentIndex + 1}`}
        >
          <AccordionsComponentContent
            titleSize={titleSize}
            theme={theme}
            {...(variant === 'reduced' && { showCardHeader: false })}
            cardTitle={content[showContentIndex]?.cardTitle}
            cardSubtitle={content[showContentIndex]?.cardSubtitle}
            cardImage={content[showContentIndex]?.cardImage}
            accordions={content[showContentIndex]?.accordions.map((accordion, index) => ({
              ...accordion,
              isOpen: index === openAccordionIndex,
              onClick: () => handleAccordionClick(index),
            }))}
            icon={content[showContentIndex]?.icon}
            data-testid="accordions-component-content"
          />
        </div>
      </div>
    </div>
  );
};
