import React, { createContext, useMemo } from 'react';

import {
  SaveASXFormRequest,
  SaveASXFormResponse,
  SaveCreditsInterestFormRequest,
  SaveCreditsInterestFormResponse,
} from '@amzn/coral_com-amazon-sssapinterface-model-operations';
import {
  FormUser,
  RelationToAmazonStruct,
  AsxFormTopicStruct,
  AsxFormContentTypeStruct,
  FormMetadata,
  FormQuestion,
} from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';

import { sssAPIClientSingleton as sssAPIClient } from 'src/clients/ash-service-client';
import { ContactFormValues } from 'src/components/pages/contact/ContactForm';
import { CreditsInterestFormValues } from 'src/components/pages/credits/interest/CreditsInterestForm';
import { CreditsInterestFormQuestionInfo } from 'src/constants';

const saveASXForm = async (formValues: ContactFormValues, metadata?: FormMetadata): Promise<SaveASXFormResponse> => {
  const request = SaveASXFormRequest.clone({
    description: formValues.description,
    user: FormUser.clone({
      name: formValues.name,
      email: formValues.email,
      relationToAmazon: RelationToAmazonStruct.clone({
        selectedRelationsToAmazon: formValues.relationsToAmazon,
        ...(formValues.additionalRelations && { additionalRelationToAmazon: formValues.additionalRelations }),
      }),
      company: formValues.company,
      companySize: formValues.companySize,
      website: formValues.website,
      jobTitle: formValues.jobTitle,
    }),
    ...(formValues.contentTypes && {
      contentType: AsxFormContentTypeStruct.clone({
        selectedContentTypes: formValues.contentTypes,
        ...(formValues.additionalContentTypes && { additionalContentTypes: formValues.additionalContentTypes }),
      }),
    }),
    formType: formValues.formType,
    topic: AsxFormTopicStruct.clone({
      selectedFormTopics: formValues.formTopics,
      ...(formValues.additionalTopics && { additionalFormTopics: formValues.additionalTopics }),
    }),
    ...(metadata &&
      Object.entries(metadata).length > 0 && {
        metadata,
      }),
  });

  if (!sssAPIClient) {
    throw new Error('SSSAPI client is not defined');
  }

  return sssAPIClient.saveASXForm(request).toPromise();
};

const saveCreditsInterestForm = async (
  formValues: CreditsInterestFormValues,
  metadata?: FormMetadata
): Promise<SaveCreditsInterestFormResponse> => {
  const request = SaveCreditsInterestFormRequest.clone({
    user: FormUser.clone({
      name: formValues.name,
      email: formValues.email,
      relationToAmazon: RelationToAmazonStruct.clone({
        selectedRelationsToAmazon: formValues.relationsToAmazon,
      }),
      company: formValues.company,
      companySize: formValues.companySize,
      website: formValues.website,
      jobTitle: formValues.jobTitle,
    }),
    questions: [
      FormQuestion.clone({
        questionId: CreditsInterestFormQuestionInfo.QUESTION_1.id,
        questionText: CreditsInterestFormQuestionInfo.QUESTION_1.text,
        questionResponse: formValues.question1,
      }),
      FormQuestion.clone({
        questionId: CreditsInterestFormQuestionInfo.QUESTION_2.id,
        questionText: CreditsInterestFormQuestionInfo.QUESTION_2.text,
        questionResponse: formValues.question2,
        ...(formValues.subquestion2 && {
          subquestions: [
            FormQuestion.clone({
              questionId: CreditsInterestFormQuestionInfo.SUBQUESTION_2.id,
              questionText: CreditsInterestFormQuestionInfo.SUBQUESTION_2.text,
              questionResponse: formValues.subquestion2,
            }),
          ],
        }),
      }),
      FormQuestion.clone({
        questionId: CreditsInterestFormQuestionInfo.QUESTION_3.id,
        questionText: CreditsInterestFormQuestionInfo.QUESTION_3.text,
        questionResponse: formValues.question3,
        ...(formValues.subquestion3 && {
          subquestions: [
            FormQuestion.clone({
              questionId: CreditsInterestFormQuestionInfo.SUBQUESTION_3.id,
              questionText: CreditsInterestFormQuestionInfo.SUBQUESTION_3.text,
              questionResponse: formValues.subquestion3,
            }),
          ],
        }),
      }),
    ],
    ...(metadata &&
      Object.entries(metadata).length > 0 && {
        metadata,
      }),
  });
  if (!sssAPIClient) {
    throw new Error('SSSAPI client is not defined');
  }

  return sssAPIClient.saveCreditsInterestForm(request).toPromise();
};

export interface SSSAPIContext {
  saveASXForm: (formInputs: ContactFormValues, metadata?: FormMetadata) => Promise<SaveASXFormResponse>;
  saveCreditsInterestForm: (
    form: CreditsInterestFormValues,
    metadata?: FormMetadata
  ) => Promise<SaveCreditsInterestFormResponse>;
}

export const SSSAPIClientContext = createContext<SSSAPIContext>({ saveASXForm, saveCreditsInterestForm });

export const SSSAPIClientProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const contextValue = useMemo<SSSAPIContext>(() => ({ saveASXForm, saveCreditsInterestForm }), []);
  return <SSSAPIClientContext.Provider value={contextValue}>{children}</SSSAPIClientContext.Provider>;
};
