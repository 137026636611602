import { html } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import styles from './grid-item.lit.scss';

/**
 * @component {kat-grid-item} KatGridItem Child component used in KatGrid.
 */
@register('kat-grid-item')
export class KatGridItem extends KatLitElement {
  static get styles() {
    return [baseStyles, styles];
  }

  render() {
    return html`<slot></slot>`;
  }
}
