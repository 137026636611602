import fallbackImage1 from 'src/images/fallback/fallback-image-1.jpg';
import fallbackImage2 from 'src/images/fallback/fallback-image-2.jpg';
import fallbackImage3 from 'src/images/fallback/fallback-image-3.png';

const fallbackImages = [fallbackImage1, fallbackImage2, fallbackImage3];

const getFallbackImage = () => {
  const randomIndex = Math.floor(Math.random() * fallbackImages.length);
  return fallbackImages[randomIndex];
};

export { getFallbackImage, fallbackImages };
