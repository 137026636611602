import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState, type FunctionComponent } from 'react';

import { FormattedLink } from 'src/components/atoms';
import { Heading, HEADING_SIZE } from 'src/components/atoms/heading/Heading';
import { Tagline } from 'src/components/atoms/tagline/Tagline';
import { DynamicListItem } from 'src/components/common/dynamic-list/DynamicList';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import styles from './DynamicItem.module.scss';

type DynamicListProps = DynamicListItem & {
  isFirst: boolean;
  isLast: boolean;
  isActive: boolean;
  onActiveChange: () => void;
  isModalVisible?: boolean;
  index?: number;
};

export const DynamicItem: FunctionComponent<DynamicListProps> = ({
  body,
  title,
  link,
  tagline,
  isActive,
  isFirst,
  isLast,
  index,
  onActiveChange,
}) => {
  const { isDesktop } = useDeviceTracker();
  const self = useRef<HTMLLIElement>(null);
  const [linkFocused, setLinkFocused] = useState(false);

  const handleScroll = useCallback(() => {
    if (self.current) {
      const distanceTopPx = self.current.getBoundingClientRect().top;
      const distanceBottomPx = window.innerHeight - self.current.getBoundingClientRect().bottom;

      const triggerOffset = isDesktop
        ? window.innerHeight / 2 - self.current.offsetHeight
        : window.innerHeight / 4 - self.current.offsetHeight;

      const shouldBeActive = (isLast || distanceTopPx > triggerOffset) && (isFirst || distanceBottomPx > triggerOffset);

      if (shouldBeActive) {
        onActiveChange();
      }
    }
  }, [isFirst, isLast, onActiveChange, isDesktop]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    const externalLink = self.current?.querySelector<HTMLAnchorElement | HTMLButtonElement>('a, button');

    if (externalLink) {
      const handleFocus = () => setLinkFocused(true);
      const handleBlur = () => setLinkFocused(false);

      externalLink.addEventListener('focus', handleFocus);
      externalLink.addEventListener('blur', handleBlur);

      return () => {
        externalLink.removeEventListener('focus', handleFocus);
        externalLink.removeEventListener('blur', handleBlur);
      };
    }

    return undefined; // Explicitly return undefined when no externalLink
  }, []);

  useEffect(() => {
    if (linkFocused && self.current) {
      self.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [linkFocused]);

  return (
    <li ref={self} className={classNames(styles.dynamicItem, !isActive && styles.disabled)} data-testid="dynamicItem">
      {tagline && <Tagline className={styles.tagline}>{tagline}</Tagline>}
      <Heading className={styles.title} as="h3" size={HEADING_SIZE.h3}>
        {title}
      </Heading>
      <p className={styles.copy}>{body}</p>
      {link && (
        <FormattedLink
          className={styles.externalLink}
          iconName={link.icon}
          text={link.text}
          {...(link.onClickOverride && { onClick: link.onClickOverride })}
          {...(link.href && { link: link.href })}
          bold
          data-testid="dynamicItemLink"
          data-cta-index={index}
          openNewTab={Boolean(link.href)}
          aria-label={link.href ? undefined : 'Opens a modal'}
          renderAsButton={!link.href}
        />
      )}
    </li>
  );
};
