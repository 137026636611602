import { FC, useEffect } from 'react';

import { KatToggle } from '@amzn/katal-react';

import './Toggle.module.scss';

interface ToggleProps extends KatToggle.Props {
  state: boolean;
  labelAria: string;
}

/**
 * Toggle component to be used on ASX. Extends `KatToggle`
 * https://katal.amazon.dev/design-system/components/toggle/
 */
export const Toggle: FC<ToggleProps> = ({ state, labelAria, name, onChange }) => {
  useEffect(() => {
    const toggleElement = document.querySelector(`[data-testid="${name}"]`);

    // istanbul ignore next
    if (toggleElement) {
      const { shadowRoot } = toggleElement;
      // istanbul ignore next
      if (shadowRoot) {
        const inputElement = shadowRoot.querySelector('input.input');
        if (inputElement) {
          inputElement.removeAttribute('aria-label');
          inputElement.setAttribute('role', 'switch');
        }

        const labelElement = shadowRoot.querySelector('label');
        if (labelElement) {
          labelElement.setAttribute('aria-label', labelAria);
        }
      }
    }
  }, [name, labelAria]);

  return <KatToggle name={name} checked={state} onChange={onChange} data-testid={name} />;
};
