import classNames, { type Argument } from 'classnames';
import { useState, type KeyboardEvent as ReactKeyboardEvent, type ReactElement } from 'react';

import { Icon } from 'src/components/atoms';

import styles from './CheckboxDescription.module.scss';

interface CheckboxDescriptionProps {
  className?: Argument;
  label?: string;
  description?: string;
  error?: boolean;
  onChange(isChecked: boolean): void;
  checked?: boolean;
}

function isEnterOrSpacePressed(event: KeyboardEvent | ReactKeyboardEvent): boolean {
  return event.key === 'Enter' || event.key === 'Space';
}

export function CheckboxDescription({
  className,
  error,
  label,
  description,
  onChange,
  checked = false,
}: CheckboxDescriptionProps): ReactElement {
  const [isChecked, setIsChecked] = useState(checked);

  const onHandleCheck = (): void => {
    onChange(!isChecked);
    setIsChecked((wasChecked) => !wasChecked);
  };

  const onHandleKeyDown = (event: ReactKeyboardEvent<Element>): void => {
    if (!isEnterOrSpacePressed(event)) return;
    onHandleCheck();
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      data-testid={`checkbox-description-${label}`}
      className={classNames(className, styles.checkboxDescription, error && styles.error)}
      onClick={onHandleCheck}
      onKeyDown={onHandleKeyDown}
    >
      <div className={styles.checkbox}>
        <input
          tabIndex={0}
          className={styles.input}
          type="checkbox"
          checked={isChecked}
          onChange={onHandleCheck}
          onClick={onHandleCheck}
        />
        <span className={styles.checkmark}>
          <Icon className={styles.icon} name="check" aria-hidden />
        </span>

        <span className={styles.label} aria-hidden>
          {label}
        </span>
      </div>

      <p className={styles.description}>{description}</p>
    </div>
  );
}
