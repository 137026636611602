import { SSSAPInterface, sSSAPInterfaceHttpBindingInterceptor } from '@amzn/coral_com-amazon-sssapinterface';
import { CoralFetchHandler, CoralInterceptor, InterceptingHandler } from '@amzn/ihm-js-coral-client';

import { CSRFInterceptor } from './csrf-interceptor';

export const SSS_API_PATH = '/sss-api';

/**
 * Instantiate a client leveraging the generated Coral Client Http Interceptor bound to the
 * SSSAPI Model.
 */
export const createSSSAPIClient = (): SSSAPInterface => {
  const coralClientConfig = {
    httpEndpoint: {
      url: `${SSS_API_PATH}`,
    },
  };

  const interceptors: CoralInterceptor[] = [sSSAPInterfaceHttpBindingInterceptor, CSRFInterceptor];

  return new SSSAPInterface(coralClientConfig, new InterceptingHandler(new CoralFetchHandler(), interceptors));
};

export const sssAPIClientSingleton = createSSSAPIClient();
