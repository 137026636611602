import { html, LitElement, property } from 'lit-element';
import { nothing } from 'lit-html';
import MultiClamp from 'multi-clamp';
import {
  KatLitElement,
  Keys,
  register,
  event,
  EventEmitter,
} from '../../shared/base';
import {
  onBodyWeblabClassChange,
  offBodyWeblabClassChange,
} from '../../utils/weblab-helpers';
import { isInViewport } from '../../utils/viewport-utils';
import { resizeCallback } from './resize';
import getString from './strings';

const REQUIRED_PERCENT_VISIBLE = 0.8;

/**
 * @component {kat-carousel} KatalCarousel A carousel sequentially displays items from a set of content. It can be set to cycle through the items either automatically or on user interaction.
 * @status Production
 * @theme flo
 * @slot default Should contain `kat-carousel-*` specific elements.
 * @guideline Do Do use aspect-width and aspect-height to define the aspect ratio of the images in the carousel.
 * @subcomponent ./image-item.ts
 * @example Simple {
 * "aspect-width": "8", "aspect-height": "3", "style": "max-width: 600px",
 * "content": "
 * <kat-carousel-items>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat1._CB467620335_.png\" label=\"First panel, no link\"></kat-carousel-image>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat2._CB467620332_.jpg\" label=\"Second panel, has a link and lots of text. Some text that it overflows the contain and gets truncated with an ellipsis Second panel, has a link and lots of text. Some text that it overflows the contain and gets truncated with an ellipsis\" link-href=\"https://katal.corp.amazon.com\"></kat-carousel-image>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat3._CB467620268_.jpg\" link-href=\"https://amazon.com\"></kat-carousel-image>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat4._CB467620269_.jpg\"></kat-carousel-image>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat_wrong_ratio._CB467620269_.jpg\" image-fit=\"cover\" label=\"This picture has a different aspect ratio but uses image-fit=cover\"></kat-carousel-image>
 * </kat-carousel-items>
 * "}
 * @example AutoCycle {
 * "auto-cycle": "true", "auto-cycle-period-ms": "5000", "aspect-width": "8", "aspect-height": "3", "style": "max-width: 600px",
 * "content": "
 * <kat-carousel-items>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat1._CB467620335_.png\" label=\"First panel, no link\"></kat-carousel-image>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat2._CB467620332_.jpg\" label=\"Second panel, has a link and lots of text. Some text that it overflows the contain and gets truncated with an ellipsis Second panel, has a link and lots of text. Some text that it overflows the contain and gets truncated with an ellipsis\" link-href=\"https://katal.corp.amazon.com\"></kat-carousel-image>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat3._CB467620268_.jpg\" link-href=\"https://amazon.com\"></kat-carousel-image>
 *   <kat-carousel-image src=\"https://m.media-amazon.com/images/G/01/katal/internal/example_cat4._CB467620269_.jpg\"></kat-carousel-image>
 * </kat-carousel-items>
 * "}
 * @example Shoveler {
 * "disable-nav-dots": "true",
 * "content": "
 * <kat-carousel-items>
 *   <kat-carousel-item>
 *     <div class=\"content kat-row\">
 *       <section class=\"kat-col-md-6\" style=\"border-right: 2px solid #DDD; padding: 20px 0\">
 *         <div class=\"content kat-row\">
 *           <section class=\"kat-col-xs-4\">
 *             <img src=\"https://m.media-amazon.com/images/G/01/katal/internal/SquareCat1._CB462098110_.jpg\" style=\"width: 100%\" />
 *           </section>
 *           <section class=\"kat-col-xs-8\">
 *             <kat-link label=\"Cat 1\"></kat-link>
 *             <p>This is a cat</p>
 *             <kat-button variant=\"secondary\" label=\"Do something\"></kat-button>
 *           </section>
 *         </div>
 *       </section>
 *       <section class=\"kat-col-md-6\" style=\"padding: 20px 0 20px 20px\">
 *         <div class=\"content kat-row\">
 *           <section class=\"kat-col-xs-4\">
 *             <img src=\"https://m.media-amazon.com/images/G/01/katal/internal/SquareCat2._CB462098110_.jpg\" style=\"width: 100%\" />
 *           </section>
 *           <section class=\"kat-col-xs-8\">
 *             <kat-link label=\"Cat 2\"></kat-link>
 *             <p>This is a cat</p>
 *             <kat-button variant=\"secondary\" label=\"Do something\"></kat-button>
 *           </section>
 *         </div>
 *       </section>
 *     </div>
 *   </kat-carousel-item>
 *   <kat-carousel-item>
 *     <div class=\"content kat-row\">
 *       <section class=\"kat-col-md-6\" style=\"border-right: 2px solid #DDD; padding: 20px 0\">
 *         <div class=\"content kat-row\">
 *           <section class=\"kat-col-xs-4\">
 *             <img src=\"https://m.media-amazon.com/images/G/01/katal/internal/SquareCat1._CB462098110_.jpg\" style=\"width: 100%\" />
 *           </section>
 *           <section class=\"kat-col-xs-8\">
 *             <kat-link label=\"Cat 1\"></kat-link>
 *             <p>This is a cat</p>
 *             <kat-button variant=\"secondary\" label=\"Do something\"></kat-button>
 *           </section>
 *         </div>
 *       </section>
 *       <section class=\"kat-col-md-6\" style=\"padding: 20px 0 20px 20px\">
 *         <div class=\"content kat-row\">
 *           <section class=\"kat-col-xs-4\">
 *             <img src=\"https://m.media-amazon.com/images/G/01/katal/internal/SquareCat2._CB462098110_.jpg\" style=\"width: 100%\" />
 *           </section>
 *           <section class=\"kat-col-xs-8\">
 *             <kat-link label=\"Cat 2\"></kat-link>
 *             <p>This is a cat</p>
 *             <kat-button variant=\"secondary\" label=\"Do something\"></kat-button>
 *           </section>
 *         </div>
 *       </section>
 *     </div>
 *   </kat-carousel-item>
 * </kat-carousel-items>
 * "}
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-carousel')
export class KatCarousel extends KatLitElement {
  /**
   * When enabled, the carousel automatically goes to the next panel every so many milliseconds. "auto-cycle-period-ms"
   * Defines the delay. The carousel stops auto-cycling when the user focuses, mouses over, or if the carousel is not fully visible on the screen.
   */
  @property({ attribute: 'auto-cycle' })
  autoCycle?: boolean;

  /**
   * Defines the delay for the next time the panel automatically transitions. Depends on "auto-cycle" being enabled.
   * When focus is lost this timer resets.
   */
  @property({ attribute: 'auto-cycle-period-ms' })
  autoCyclePeriodMs = 3000;

  /**
   * Defines the width part of the aspect ratio. Used along side aspect-height to determine the height of the carousel in
   * response to its containers size.
   */
  @property({ attribute: 'aspect-width' })
  aspectWidth = 0;

  /**
   * Defines the height part of the aspect ratio. Used along side aspect-width to determine the height of the carousel in
   * response to its containers size.
   */
  @property({ attribute: 'aspect-height' })
  aspectHeight = 0;

  /**
   * When set, the arrows that allow the user to navigate to the previous or next panels are removed. Allows client teams
   * to define their own navigation controls.
   */
  @property({ attribute: 'disable-nav-arrows' })
  disableNavArrows?: boolean;

  /**
   * When set, the dots that allow the user to navigate to a specific panel are removed. Allows client teams
   * to define their own navigation controls.
   */
  @property({ attribute: 'disable-nav-dots' })
  disableNavDots?: boolean;

  /** Defines the label read to screen-reader users when they select the carousel. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /** Fires when a transition from one item to another starts. */
  @event('slideStart', true)
  private _slideStart: EventEmitter<{
    previousIndex: number;
    newIndex: number;
  }>;

  /** Fires when a transition ends. At this point all animations have finished and all internal state is set to the new item. */
  @event('slideEnd', true)
  private _slideEnd: EventEmitter<{
    previousIndex: number;
    newIndex: number;
  }>;

  constructor() {
    super();

    this._items = [];
    this._currentItem = 0;
    this._mobileClamps = [];
    this._autoCycleActive = false;
    this._autoCycleTimeout = null;
    this._fakeCurrent = null;
    this._sliding = false;
    this._isMouseover = false;

    this._memoizeContainer();

    this._previousItemBoundFunc = () => {
      this._focusCarousel();
      this._changeItem(-1);
    };
    this._nextItemBoundFunc = () => {
      this._focusCarousel();
      this._changeItem(1);
    };

    this.observeChildren(this, () => {
      this._memoizeContainer();
      if (!this._itemContainer) return;

      this.requestUpdate();

      if (this._itemsObserver) {
        this._itemsObserver.disconnect();
      }
      this._itemsObserver = this.observeChildren(this._itemContainer, () => {
        this._sliding = false;
        this.requestUpdate();
      });
    });
  }

  connectedCallback(): void {
    super.connectedCallback();
    onBodyWeblabClassChange(() => this.requestUpdate());
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    offBodyWeblabClassChange(() => this.requestUpdate());
  }

  // disable shadow-dom
  createRenderRoot() {
    return this;
  }

  updated(changedProperties) {
    if (changedProperties.has('autoCycle') && this.autoCycle) {
      this._updateAutoCycle();
    }
    if (changedProperties.has('autoCyclePeriodMs') && this.autoCycle) {
      this._stopAutoCycle();
      this._updateAutoCycle();
    }
    if (
      changedProperties.has('aspectWidth') ||
      changedProperties.has('aspectHeight')
    ) {
      this._updateAspectRatio();
    }

    this._onResize();
    this._renderAriaAlternatives();
  }

  _memoizeContainer() {
    this._itemContainer = this.getElementsByTagName('kat-carousel-items')[0];
  }

  _updateAspectRatio() {
    if (!this._itemContainer) {
      return;
    }

    if (this.aspectWidth === 0 || this.aspectHeight === 0) {
      this._itemContainer.style.paddingTop = null;
    } else {
      this._itemContainer.style.paddingTop = `calc(${this.aspectHeight}/${this.aspectWidth} * 100%)`;
    }
  }

  firstUpdated() {
    this._updateAspectRatio();

    this._resizeCleanup = resizeCallback(this, this._onResize.bind(this));
    this.addEventListener('keydown', this._onKeyDown);

    this.addEventListener('mouseover', () => {
      this._isMouseOver = true;
      this._updateAutoCycle();
    });
    this.addEventListener('mouseleave', evt => {
      if (evt.target === this) {
        this._isMouseOver = false;
        this._updateAutoCycle();
      }
    });

    this.addEventListener('focusin', this._updateAutoCycle);
    this.addEventListener('focusout', this._updateAutoCycle);

    document.addEventListener(
      'visibilitychange',
      this._updateAutoCycle.bind(this)
    );
    document.addEventListener('scroll', this._updateAutoCycle.bind(this));

    this._updateAutoCycle();
  }

  _isInViewport() {
    return isInViewport(this, REQUIRED_PERCENT_VISIBLE);
  }

  _isFocused() {
    return document.activeElement === this._itemContainer;
  }

  _updateAutoCycle() {
    // Only enable auto-cycle if not moused-over, not focused, in viewport, and window visible.
    if (
      !this._isMouseOver &&
      !this._isFocused() &&
      this._isInViewport() &&
      !document.hidden
    ) {
      this._startAutoCycle();
    } else {
      this._stopAutoCycle();
    }
  }

  _startAutoCycle() {
    if (!this.autoCycle || this._autoCycleTimeout) return;

    this._autoCycleTimeout = setTimeout(() => {
      const items = this._getItems();
      if (this._currentItem < items.length - 1) {
        this.goToNextItem();
        this._autoCycleTimeout = null;
        this._startAutoCycle();
      }
    }, this.autoCyclePeriodMs);
  }

  _stopAutoCycle() {
    clearTimeout(this._autoCycleTimeout);
    this._autoCycleTimeout = null;
  }

  _onKeyDown(evt) {
    const key = evt.keyCode;
    if (key === Keys.ArrowLeft) {
      evt.preventDefault();
      this._focusCarousel();
      this.goToPreviousItem();
    } else if (key === Keys.ArrowRight) {
      evt.preventDefault();
      this._focusCarousel();
      this.goToNextItem();
    } else if (key >= Keys.One && key <= Keys.Nine) {
      // Handle keys 1 through 9, go to the item matching the number.
      this.goToItem(key - Keys.One);
    } else if (key === Keys.Space || key === Keys.Enter) {
      evt.preventDefault();
      // Attempt to click the link of the current item if it's a kat-carousel-image
      const item = this._getItem(this._currentItem);
      if (item.tagName === 'KAT-CAROUSEL-IMAGE') {
        const evt = new Event('click');
        item.dispatchEvent(evt);
      }
    }
  }

  _focusCarousel() {
    if (this._itemContainer) {
      this._itemContainer.focus();
    }
  }

  _changeItem(direction) {
    const items = this._getItems();
    let newIndex = this._currentItem + direction;
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    this.goToItem(newIndex);
  }

  /**
   * Starts a transition to the next item in the carousel. Will not interrupt an existing transition.
   * @katalmethod
   */
  goToNextItem() {
    this._changeItem(1);
  }

  /**
   * Starts a transition to the previous item in the carousel. Will not interrupt an existing transition.
   * @katalmethod
   */
  goToPreviousItem() {
    this._changeItem(-1);
  }

  /**
   * Starts a transition to the specified item in the carousel. Will not interrupt an existing transition.
   * @katalmethod
   */
  goToItem(newIndex) {
    const next = this._getItem(newIndex);
    if (this._sliding || this._currentItem === newIndex || !next) return;

    const previous = this._getItem(this._currentItem);
    const dir = this._currentItem < newIndex ? 'right' : 'left';
    this._sliding = true;

    // Update the carousel immediately with the future state so the arrows and dots are immediately correct.
    this._fakeCurrent = newIndex;
    this.requestUpdate();

    this._slideStart.emit({
      previousIndex: this._currentItem,
      newIndex,
    });

    this._performTranstion(previous, next, dir, () => {
      this._sliding = false;
      this._fakeCurrent = null;
      this._slideEnd.emit({
        previousIndex: this._currentItem,
        newIndex,
      });

      this._currentItem = newIndex;
      this.requestUpdate();
    });
  }

  _performTranstion(prevEle, nextEle, dir, onEnd) {
    if (prevEle && nextEle) {
      prevEle.classList.add(`kat-animating-${dir}-away`);
      nextEle.classList.add(`kat-animating-${dir}-into-start`);
      setTimeout(() => {
        nextEle.classList.remove(`kat-animating-${dir}-into-start`);
        nextEle.classList.add(`kat-animating-${dir}-into`);
      }, 30);

      setTimeout(() => {
        prevEle.classList.remove(`kat-animating-${dir}-away`);
        prevEle.classList.remove(`kat-current-item`);
        nextEle.classList.remove(`kat-animating-${dir}-into`);
        nextEle.classList.add(`kat-current-item`);

        if (onEnd) {
          onEnd();
        }
      }, 500);
    }
  }

  _getCurrentItem() {
    if (this._fakeCurrent !== null) {
      return this._fakeCurrent;
    }
    return this._currentItem;
  }

  _getItem(index) {
    const items = this._getItems();
    return items ? items[index] : null;
  }

  _onDotClick(evt) {
    const target = evt.target;
    if (target.dataset.index) {
      const index = parseInt(target.dataset.index, 10);
      if (this._currentItem !== index) {
        this.goToItem(index);
      }
    }
  }

  _onResize() {
    this._getItems()
      .filter(item => item.onCarouselResize)
      .forEach(item => item.onCarouselResize());
    this._clampText();
  }

  _getItems() {
    if (!this._itemContainer) {
      return [];
    }

    return [].slice
      .call(this._itemContainer.children)
      .filter(
        child => !child.classList.contains('kat-carousel-controls-container')
      );
  }

  _clampText() {
    const clamp = this._mobileClamps[this._currentItem];
    const labels = this.getElementsByClassName('kat-carousel-mobile-labels')[0];
    if (!labels || labels.children.length === 0) return;

    let label = labels.children[this._currentItem];
    if (label.children.length === 0) return;

    label = label.children[0];
    const item = this._getItem(this._currentItem);
    if (clamp && clamp.label === label) {
      label.innerText = item.getAttribute('label');
      clamp.instance.reload();
    } else {
      this._mobileClamps[this._currentItem] = {
        label,
        instance: new MultiClamp(label, {
          ellipsis: '...',
          clamp: 'auto',
        }),
      };
    }
  }

  renderDot(item, i) {
    const current = i === this._getCurrentItem() ? ' kat-current-dot' : '';
    const coordinate = 10;
    const radius = 4;
    return html`
      <span
        class="kat-carousel-nav-dot ${current}"
        data-index=${i}
        @click=${this._onDotClick.bind(this)}
      >
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <circle cx=${coordinate} cy=${coordinate} r=${radius} />
        </svg>
      </span>
    `;
  }

  _renderMobileLabel(item, i) {
    if (
      item.tagName.toLowerCase() === 'kat-carousel-image' &&
      item.hasAttribute('label')
    ) {
      const classes = i === this._getCurrentItem() ? 'kat-current-item' : '';
      const label = item.getAttribute('label');
      return html`
        <div class=${classes}>
          <div class="kat-carousel-mobile-label-inner">${label}</div>
        </div>
      `;
    }
    return html`<div class="kat-carousel-empty-label"></div>`;
  }

  _renderAriaItem(item) {
    const label = item.getAriaLabel ? item.getAriaLabel() : '';
    if (item.tagName.toLowerCase() === 'kat-carousel-image' && item.linkHref) {
      return html`
        <li>
          <a href=${item.linkHref} tabindex="-1">${label}</a>
        </li>
      `;
    }
    return html`<li>${label}</li>`;
  }

  /**
   * Render a separate set of screen-reader only elements as it's impossible to provide a sensible experience with the rest of the DOM.
   */
  _renderAriaAlternatives() {
    let alternates = this.getElementsByClassName(
      'kat-carousel-aria-alternate-items'
    )[0];
    if (!alternates) {
      alternates = document.createElement('ul');
      alternates.classList.add('kat-carousel-aria-alternate-items');
      alternates.classList.add('kat-visually-hidden');
      this.appendChild(alternates);
    }

    const ariaItems = this._getItems().map(item => this._renderAriaItem(item));
    LitElement.render(html`${ariaItems}`, alternates, {
      scopeName: 'aria-items',
    });
  }

  render() {
    const items = this._getItems();
    const iconSize = 'small';

    if (items.length > 0) {
      const currentItem = items[this._currentItem];
      if (currentItem) {
        currentItem.classList.add('kat-current-item');
      }
    }

    if (!this._itemContainer) {
      this._memoizeContainer();
    }
    if (!this._itemContainer) {
      // prevent lit from blowing away light dom
      return super.render();
    }

    const ariaLabel = this.katAriaLabel
      ? this.katAriaLabel
      : getString('katal-carousel-default-aria-label');

    this._itemContainer.setAttribute('aria-label', ariaLabel);
    if (!this._itemContainer.hasAttribute('tabindex')) {
      this._itemContainer.setAttribute('tabindex', 0);
    }

    let controls = this.getElementsByClassName(
      'kat-carousel-controls-container'
    )[0];
    if (!controls) {
      controls = document.createElement('div');
      controls.classList.add('kat-carousel-controls-container');
      this._itemContainer.appendChild(controls);
    } else if (this._itemContainer.lastChild !== controls) {
      this._itemContainer.appendChild(controls);
    }

    let leftClasses = 'kat-carousel-left-button';
    if (this._getCurrentItem() === 0) {
      leftClasses += ' kat-carousel-disable';
    }

    let rightClasses = 'kat-carousel-right-button';
    if (this._getCurrentItem() === items.length - 1) {
      rightClasses += ' kat-carousel-disable';
    }
    const buttons = this.disableNavArrows
      ? nothing
      : html`
          <button
            type="button"
            tabindex="-1"
            aria-hidden="true"
            class=${leftClasses}
            @click=${this._previousItemBoundFunc}
          >
            <kat-icon name="chevron-left" size=${iconSize}></kat-icon>
          </button>
          <button
            type="button"
            tabindex="-1"
            aria-hidden="true"
            class=${rightClasses}
            @click=${this._nextItemBoundFunc}
          >
            <kat-icon name="chevron-right" size=${iconSize}></kat-icon>
          </button>
        `;

    const dots = items.map((item, i) => this.renderDot(item, i));

    LitElement.render(html`${buttons}`, controls, {
      scopeName: 'kat-carousel-controls',
    });

    if (!this.disableNavDots) {
      let dotNavigation = this.getElementsByClassName(
        'kat-carousel-nav-dots'
      )[0];
      if (!dotNavigation) {
        dotNavigation = document.createElement('div');
        dotNavigation.classList.add('kat-carousel-nav-dots');
        this.appendChild(dotNavigation);
      }
      LitElement.render(html`${dots}`, dotNavigation, {
        scopeName: 'kat-carousel-navigation-dots',
      });
    }

    // prevent lit from blowing away light dom
    return super.render();
  }
}
