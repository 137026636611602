import { FunctionComponent } from 'react';

import { useBundle } from '@amzn/react-arb-tools';

import {
  Card,
  PageSection,
  PrimaryHero,
  SecondaryBanner,
  Slider,
  TextComponent,
  CardV2,
  SliderV2,
  Grid,
  GridV2,
} from 'src/components/common';
import { AssetBanner } from 'src/components/common/asset-banner/AssetBanner';
import { useFocusAreaCards, useFocusAreaCardsAccessible } from 'src/components/pages/home/hooks/useFocusAreaCards';
import { ClimatePledgeSection } from 'src/components/templates/ClimatePledgeSection';
import { HOMEPAGE_MESSAGE_BUNDLE_NAME } from 'src/constants';
import { ROUTES } from 'src/data/enums/Routes';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { getImageRoot, shouldRenderAccessibleSlider, t } from 'src/helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

export const HomePage: FunctionComponent = () => {
  // TODO remove after testing https://taskei.amazon.dev/tasks/V1558443290
  const renderAccessibleSlider = shouldRenderAccessibleSlider();

  const [homepageBundle] = useBundle(HOMEPAGE_MESSAGE_BUNDLE_NAME);
  const { isSmallDesktop } = useDeviceSize();
  const imageRoot = getImageRoot(isSmallDesktop);

  const focusAreaCards = useFocusAreaCards();
  const focusAreaCardsAccessible = useFocusAreaCardsAccessible();
  return (
    <>
      <PrimaryHero
        heading={t(homepageBundle, 'hero_title') ?? ''}
        description={t(homepageBundle, 'hero_description')}
        image={`${imageRoot}/home-hero.png`}
      />
      <PageSection>
        {/* TODO: The Slider component uses TextComponent which has the below bug. */}
        {/* TODO: Copy is required when it should be optional and text not full-width due to copy still injected in dom */}
        {/* TODO: https://app.asana.com/0/1207500805232850/1207595341447795 */}
        {/* TODO: Also, extra padding on the slides causes it not to align with the heading: https://app.asana.com/0/1207500805232850/1207595341447807 */}
        {/* TODO: Add alt text to these card images when fixed (strings are already created): https://app.asana.com/0/1207500805232850/1207615185237716 */}
        {renderAccessibleSlider ? (
          <SliderV2 heading={t(homepageBundle, 'news_title') ?? ''} displayContents>
            <CardV2
              title={t(homepageBundle, 'news_card1Title')}
              subtitle={t(homepageBundle, 'news_card1Description')}
              image={`${imageRoot}/home-newsandhighlights-getstarted.png`}
              alt={t(homepageBundle, 'news_card1ImageAltText')}
              link={{
                label: t(homepageBundle, 'news_card1CTA') ?? '',
                url: ROUTES.GETTING_STARTED,
              }}
              openInSameTab
              icon="chevronRight"
              skipAccessibleLabeling
            />
            <CardV2
              title={t(homepageBundle, 'news_card2Title')}
              subtitle={t(homepageBundle, 'news_card2Description')}
              image={`${imageRoot}/home-newsandhighlights-measurement.png`}
              alt={t(homepageBundle, 'news_card2ImageAltText')}
              link={{
                label: t(homepageBundle, 'news_card2CTA') ?? '',
                url: ROUTES.MEASUREMENT,
              }}
              openInSameTab
              icon="chevronRight"
              skipAccessibleLabeling
            />
            <CardV2
              title={t(homepageBundle, 'news_card3Title')}
              subtitle={t(homepageBundle, 'news_card3Description')}
              image={`${imageRoot}/home-newsandhighlights-DPM.png`}
              alt={t(homepageBundle, 'news_card3ImageAltText')}
              link={{
                label: t(homepageBundle, 'news_card3CTA') ?? '',
                url: ROUTES.SCIENCE_EXCHANGE,
              }}
              openInSameTab
              icon="chevronRight"
              skipAccessibleLabeling
            />
          </SliderV2>
        ) : (
          <Slider heading={t(homepageBundle, 'news_title') ?? ''} displayContents>
            <Card
              title={t(homepageBundle, 'news_card1Title')}
              subtitle={t(homepageBundle, 'news_card1Description')}
              image={`${imageRoot}/home-newsandhighlights-getstarted.png`}
              alt={t(homepageBundle, 'news_card1ImageAltText')}
              link={{
                label: t(homepageBundle, 'news_card1CTA') ?? '',
                url: ROUTES.GETTING_STARTED,
              }}
              target="_self"
              icon="chevronRight"
            />
            <Card
              title={t(homepageBundle, 'news_card2Title')}
              subtitle={t(homepageBundle, 'news_card2Description')}
              image={`${imageRoot}/home-newsandhighlights-measurement.png`}
              alt={t(homepageBundle, 'news_card2ImageAltText')}
              link={{
                label: t(homepageBundle, 'news_card2CTA') ?? '',
                url: ROUTES.MEASUREMENT,
              }}
              target="_self"
              icon="chevronRight"
            />
            <Card
              title={t(homepageBundle, 'news_card3Title')}
              subtitle={t(homepageBundle, 'news_card3Description')}
              image={`${imageRoot}/home-newsandhighlights-DPM.png`}
              alt={t(homepageBundle, 'news_card3ImageAltText')}
              link={{
                label: t(homepageBundle, 'news_card3CTA') ?? '',
                url: ROUTES.SCIENCE_EXCHANGE,
              }}
              target="_self"
              icon="chevronRight"
            />
          </Slider>
        )}
      </PageSection>

      <AssetBanner
        description={t(homepageBundle, 'approach_description') ?? ''}
        heading={t(homepageBundle, 'approach_title') ?? ''}
        cta={{
          link: ROUTES.GETTING_STARTED,
          copy: t(homepageBundle, 'approach_ctaLabel') ?? '',
        }}
      />

      <PageSection theme="slate-dark">
        <TextComponent
          copy={t(homepageBundle, 'focusAreas_description')}
          heading={t(homepageBundle, 'focusAreas_title') ?? ''}
        />
        {renderAccessibleSlider ? <GridV2 cards={focusAreaCardsAccessible} /> : <Grid cards={focusAreaCards} />}
      </PageSection>

      <PageSection>
        <SecondaryBanner
          imagePrimary={{
            url: `${imageRoot}/home-thescienceexchange-1.png`,
            alt: t(homepageBundle, 'scienceExchange_imageOneAltText') ?? '',
          }}
          imageSecondary={{
            url: `${imageRoot}/home-thescienceexchange-2.png`,
            alt: t(homepageBundle, 'scienceExchange_imageTwoAltText') ?? '',
          }}
          description={t(homepageBundle, 'scienceExchange_description') ?? ''}
          title={t(homepageBundle, 'scienceExchange_title') ?? ''}
          subTitle={t(homepageBundle, 'scienceExchange_subTitle') ?? ''}
          theme={SECTION_THEME.Light}
          cta={{
            label: t(homepageBundle, 'scienceExchange_ctaLabel') ?? '',
            link: ROUTES.SCIENCE_EXCHANGE,
          }}
        />
      </PageSection>

      <ClimatePledgeSection />
    </>
  );
};
