import { FunctionComponent } from 'react';

import { RedirectionCard, RedirectionCardProps } from 'src/components/common/redirection-card/RedirectionCard';

import styles from './RedirectionCardList.module.scss';

export interface RedirectionCardListProps {
  subtitle: string;
  cards: RedirectionCardProps[];
}

export const RedirectionCardList: FunctionComponent<RedirectionCardListProps> = ({ subtitle, cards }) => (
  <div className={styles.redirectionCardListContainer}>
    <h4>{subtitle}</h4>
    <div className={styles.cardList}>
      {cards.map((card, index) => (
        <RedirectionCard key={`${card.copy}-${index}`} {...card} />
      ))}
    </div>
  </div>
);
