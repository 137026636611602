import KatalLogger, { Level } from '@amzn/katal-logger';

import { AsxMetric } from 'src/interfaces/AsxMetric';
import { isBotTraffic } from 'src/logging-helpers/botTraffic';

// We hare having fatal count issues in our logging
// Katal uses sendBeacon: https://stackoverflow.com/questions/28989640/navigator-sendbeacon-data-size-limits
export const BEACON_LOG_CHAR_SIZE = 65536;
export const LOG_MAX_SAFETY_PADDING = 0.9;
export const MAX_LOG_LINE_SIZE = Math.floor(BEACON_LOG_CHAR_SIZE * LOG_MAX_SAFETY_PADDING);

export const MAX_CONTEXT_SIZE = MAX_LOG_LINE_SIZE - 15000;
export const MAX_ERROR_STACK_SIZE = 2000;

const katalLoggerConfig = {
  url: '/log',
  logThreshold: Level.INFO,
  // https://sage.amazon.dev/posts/1371557?t=7
  maxLogLineSize: MAX_LOG_LINE_SIZE,
  logToConsole: false,
  isEmfEnabled: true,
  decodeStackTrace: true,
};

/**
 * Creates Katal logger
 */
const logger = new KatalLogger(katalLoggerConfig);

/**
 * Creates a metric in EMF format
 * https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html
 */
const createEmfFormattedMetric = (metric: AsxMetric) => ({
  // This has to be added by the client side logger
  emfLog: {
    // This is the AWS EMF expected payload
    _aws: {
      Timestamp: Date.now(),
      CloudWatchMetrics: [
        {
          Namespace: 'SustainabilitySolutionsHubWebsite',
          Dimensions: [metric.dimensions ? Object.keys(metric.dimensions) : []],
          Metrics: [
            {
              Name: metric.name,
              Unit: metric.unit,
              Value: metric.value,
            },
          ],
        },
      ],
    },
    ...metric.dimensions,
    [metric.name]: metric.value,
  },
});

/**
 * Emits EMF metric in logger
 * https://code.amazon.com/packages/KatalLoggerCloudWatchBackend/blobs/mainline-1.4-LPT/--/README.md
 */
const emitMetric = (metric: AsxMetric) => {
  logger.info(metric.name, createEmfFormattedMetric(metric));
};

/**
 * Emits count EMF metric in logger
 */
const emitCountMetric = (name: string, value: number, dimensions?: { [key: string]: any }) => {
  const metric: AsxMetric = {
    name,
    value,
    unit: 'Count',
    dimensions,
  };
  if (!isBotTraffic()) {
    emitMetric(metric);
  }
};

/**
 * Emits time EMF metric in logger
 */
const emitTimeMetric = (name: string, value: number, dimensions?: { [key: string]: any }) => {
  const metric: AsxMetric = {
    name,
    value,
    unit: 'Milliseconds',
    dimensions,
  };
  emitMetric(metric);
};

const truncateContext = (context?: Record<string, unknown>): Record<string, unknown> | undefined =>
  context && JSON.stringify(context).length > MAX_CONTEXT_SIZE
    ? {
        truncatedContext: JSON.stringify(context).substring(0, MAX_CONTEXT_SIZE),
      }
    : context;

const truncateError = (error: Error) => {
  if (error.stack && error.stack?.length > MAX_ERROR_STACK_SIZE) {
    // line was an es lint error because of parameter reassignment, but in this case
    // there are no setter methods so is necessary
    // eslint-disable-next-line
    error.stack = error.stack?.substring(0, MAX_ERROR_STACK_SIZE);
  }

  return error;
};

const toError = (error: any) => {
  if (error instanceof Error) {
    return truncateError(error);
  }
  // instead of throw null back we throw an error allowing us to at least track the metric
  if (error === null || error === undefined) return new Error('error was null or undefined');
  // JSON.stringify ignores Functions without throwing an error
  if (error instanceof Function) return new Error(String(error));

  try {
    // note: Symbols are also ignored by JSON.stringify
    return new Error(JSON.stringify(error));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(error));
  }
};

/**
 * Error logger with wrapper in case of logging failures
 */
const logError = (source: string, message: string, error?: any, context?: Record<string, any>) => {
  if (!isBotTraffic()) {
    try {
      if (error) {
        logger.error(
          message,
          toError(error),
          truncateContext({
            source,
            ...context,
          })
        );
      } else {
        logger.error(
          message,
          truncateContext({
            source,
            ...context,
          })
        );
      }
    } catch (err: any) {
      logger.error(`unable to log error from ${source}`, err);
    }
  }
};

/**
 * Sets up global error and unhandled promise rejection logging.
 * The callback function receives an error. The error will log only if the callback function returns true.
 */
logger.addErrorListener((error: any) => {
  if (error && error.stack) {
    logError('GlobalListener', 'error caught at global listener', error);
  }
  return false;
});

export { logger, emitCountMetric, emitTimeMetric, logError, truncateContext, toError };
