import {
  AsxFormContentType,
  AsxFormTopic,
  AsxFormType,
  PageIngress,
  RelationToAmazon,
} from '@amzn/coral_com-amazon-sssapinterface-model-structures-form';

import { ContactFormStrings } from 'src/constants';

export const relationToAmazonTranslationMap: Record<RelationToAmazon, string> = {
  [RelationToAmazon.SUPPLIER]: ContactFormStrings.SUPPLIER_LABEL,
  [RelationToAmazon.AWS_CUSTOMER]: ContactFormStrings.AWS_CUSTOMER_LABEL,
  [RelationToAmazon.VENDOR]: ContactFormStrings.VENDOR_LABEL,
  [RelationToAmazon.SELLING_PARTNER]: ContactFormStrings.SELLING_PARTNER_LABEL,
  [RelationToAmazon.THE_CLIMATE_PLEDGE_SIGNATORY]: ContactFormStrings.TCP_SIGNATORY_LABEL,
  [RelationToAmazon.AMAZON_CUSTOMER]: ContactFormStrings.AMAZON_CUSTOMER_LABEL,
  [RelationToAmazon.OTHER]: ContactFormStrings.OTHER_LABEL,
};

export const relationToAmazonDescriptionTranslationMap: Record<RelationToAmazon, string> = {
  [RelationToAmazon.SUPPLIER]: ContactFormStrings.SUPPLIER_DESCRIPTION,
  [RelationToAmazon.AWS_CUSTOMER]: ContactFormStrings.AWS_CUSTOMER_DESCRIPTION,
  [RelationToAmazon.VENDOR]: ContactFormStrings.VENDOR_DESCRIPTION,
  [RelationToAmazon.SELLING_PARTNER]: ContactFormStrings.SELLING_PARTNER_DESCRIPTION,
  [RelationToAmazon.THE_CLIMATE_PLEDGE_SIGNATORY]: ContactFormStrings.TCP_SIGNATORY_DESCRIPTION,
  [RelationToAmazon.AMAZON_CUSTOMER]: ContactFormStrings.AMAZON_CUSTOMER_DESCRIPTION,
  [RelationToAmazon.OTHER]: '',
};

export const asxFormTopicTranslationMap: Record<AsxFormTopic, string> = {
  [AsxFormTopic.MEASUREMENT]: ContactFormStrings.MEASUREMENT_LABEL,
  [AsxFormTopic.CARBON_FREE_ENERGY]: ContactFormStrings.CARBON_FREE_ENERGY_LABEL,
  [AsxFormTopic.WASTE_AND_CIRCULARITY]: ContactFormStrings.WASTE_AND_CIRCULARITY_LABEL,
  [AsxFormTopic.GETTING_STARTED]: ContactFormStrings.GETTING_STARTED_LABEL,
  [AsxFormTopic.WATER_STEWARDSHIP]: ContactFormStrings.WATER_STEWARDSHIP_LABEL,
  [AsxFormTopic.BUILDINGS]: ContactFormStrings.BUILDINGS_LABEL,
  [AsxFormTopic.CARBON_NEUTRALIZATION]: ContactFormStrings.CARBON_NEUTRALIZATION_LABEL,
  [AsxFormTopic.HUMAN_RIGHTS]: ContactFormStrings.HUMAN_RIGHTS_LABEL,
  [AsxFormTopic.THE_SCIENCE_EXCHANGE]: ContactFormStrings.THE_SCIENCE_EXCHANGE_LABEL,
  [AsxFormTopic.TRANSPORTATION]: ContactFormStrings.TRANSPORTATION_LABEL,
  [AsxFormTopic.OTHER]: ContactFormStrings.OTHER_LABEL,
};

export const asxFormTypeTranslationMap: Record<AsxFormType, string> = {
  [AsxFormType.GENERAL_CONTENT_INTAKE]: ContactFormStrings.GENERAL_CONTENT_INTAKE_LABEL,
  [AsxFormType.GENERAL_FEEDBACK]: ContactFormStrings.GENERAL_FEEDBACK_LABEL,
};

export const asxFormContentTypeTranslationMap: Record<AsxFormContentType, string> = {
  [AsxFormContentType.TOOL]: ContactFormStrings.TOOL_LABEL,
  [AsxFormContentType.RESOURCE]: ContactFormStrings.RESOURCE_LABEL,
  [AsxFormContentType.CASE_STUDY]: ContactFormStrings.CASE_STUDY_LABEL,
  [AsxFormContentType.OTHER]: ContactFormStrings.OTHER_LABEL,
};

export const pageIngressToTopic: Partial<Record<PageIngress, AsxFormTopic>> = {
  [PageIngress.CARBON_FREE_ENERGY]: AsxFormTopic.CARBON_FREE_ENERGY,
  [PageIngress.MEASUREMENT]: AsxFormTopic.MEASUREMENT,
  [PageIngress.BUILDINGS]: AsxFormTopic.BUILDINGS,
  [PageIngress.CARBON_NEUTRALIZATION]: AsxFormTopic.CARBON_NEUTRALIZATION,
  [PageIngress.HUMAN_RIGHTS]: AsxFormTopic.HUMAN_RIGHTS,
  [PageIngress.TRANSPORTATION]: AsxFormTopic.TRANSPORTATION,
  [PageIngress.SCIENCE_EXCHANGE]: AsxFormTopic.THE_SCIENCE_EXCHANGE,
  [PageIngress.WATER_STEWARDSHIP]: AsxFormTopic.WATER_STEWARDSHIP,
  [PageIngress.WASTE_AND_CIRCULARITY]: AsxFormTopic.WASTE_AND_CIRCULARITY,
  [PageIngress.GETTING_STARTED]: AsxFormTopic.GETTING_STARTED,
};
