import { FunctionComponent, HTMLAttributes } from 'react';

import { Heading, HEADING_SIZE, PrimaryButton } from 'src/components/atoms';
import { AssetGraphic } from 'src/components/common';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import styles from './AssetBanner.module.scss';

interface AssetBannerProps extends HTMLAttributes<HTMLDivElement> {
  description: string;
  heading: string;
  cta?: {
    link: string;
    copy: string;
  };
}

export const AssetBanner: FunctionComponent<AssetBannerProps> = ({ description, heading, cta }) => {
  const { isDesktop } = useDeviceTracker();

  return (
    <div className={styles.assetBanner} id={styles.assetBanner}>
      {isDesktop ? (
        <>
          <Heading className="accesibilityHelper" as="h3" size={HEADING_SIZE.h3}>
            {heading}
          </Heading>
          <AssetGraphic className={styles.assetGraphic} />
          <div className={styles.content}>
            <Heading className={styles.heading} as="h3" size={HEADING_SIZE.h3} isAriaHidden>
              {heading}
            </Heading>
            <p className={styles.body}>{description}</p>
            {cta && (
              <PrimaryButton
                className={styles.cta}
                contentClassName={styles.ctaContent}
                label={cta.copy}
                link={cta.link}
                theme={SECTION_THEME.Dark}
                icon="chevronRight"
                data-testid="assetBannerCta"
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className={styles.content}>
            <Heading className={styles.heading} as="h3" size={HEADING_SIZE.h3}>
              {heading}
            </Heading>
            <p className={styles.body}>{description}</p>
            {cta && (
              <PrimaryButton
                className={styles.cta}
                contentClassName={styles.ctaContent}
                label={cta.copy}
                link={cta.link}
                theme={SECTION_THEME.Dark}
                icon="chevronRight"
                data-testid="assetBannerCta"
              />
            )}
          </div>
          <AssetGraphic className={styles.assetGraphic} />
        </>
      )}
    </div>
  );
};
