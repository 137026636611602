import { FunctionComponent } from 'react';
import styled from 'styled-components';

import {
  ContentFormat,
  DisplayType,
  Page,
  Resource,
  ResourceType,
  SectionType,
} from '@amzn/coral_com-amazon-sssapinterface-model';
import { useBundle } from '@amzn/react-arb-tools';
import variables from '@amzn/sss-website-theme/dist/theme/variables.module.scss';

import { IconType } from 'src/components/atoms';
import { Card, Slider, CardV2, SliderV2 } from 'src/components/common';
import { FOCUS_AREA_URL_BY_PAGE_ID } from 'src/components/pages/resource-pages/focus-areas/focusAreaPath';
import {
  CASE_STUDY_RESOURCE_BUNDLE_NAME,
  CLOUDFRONT_PREFIX,
  COLLABORATORS_RESOURCE_BUNDLE_NAME,
  GETTING_STARTED_PAGE_BUNDLE_NAME,
  GUIDANCE_RESOURCE_BUNDLE_NAME,
  METHODOLOGY_RESOURCE_BUNDLE_NAME,
  PLAYBOOK_RESOURCE_BUNDLE_NAME,
  POLICY_RESOURCE_BUNDLE_NAME,
  SECONDARY_HERO_RESOURCE_BUNDLE_NAME,
} from 'src/constants';
import { Pages } from 'src/fixtures/structures/pages';
import {
  shouldRenderAccessibleSlider,
  getResourcesBySectionType,
  extractAssetDisplayType,
  t,
  getLibraryDomainRoot,
  getDisplayType,
} from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './GettingStartedPage.module.scss';

interface FocusAreaTakeActionProps {
  focusAreaId: string;
}

const FocusAreaHairline = styled.hr`
  background-color: ${variables.greenPrimary};
  margin: 0 0 40px;
  height: 1px;
  border: 0;
  opacity: 0.2;

  @media (min-width: 1280px) {
    margin: 47px 0 68px;
  }
`;

const convertToTitleCase = (s: string) =>
  s.toLowerCase().replace(/^_*(.)|_+(.)/g, (_s: any, c: string, d: string) => (c ? c.toUpperCase() : ` ${d}`));

const getResourceLink = (resource: Resource): string => {
  const asset = extractAssetDisplayType(resource.assets.primaryAsset);
  switch (asset?.contentFormat) {
    case ContentFormat.WEB_PAGE:
      return asset.assetUrl;
    default:
      return `${CLOUDFRONT_PREFIX}${asset?.assetUrl}`;
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FocusAreaTakeAction: FunctionComponent<FocusAreaTakeActionProps> = ({ focusAreaId }) => {
  const { getCtaByResource } = useCommonStrings();
  const [gettingStartedBundle] = useBundle(GETTING_STARTED_PAGE_BUNDLE_NAME);
  const [secondaryHeroResourceBundle] = useBundle(SECONDARY_HERO_RESOURCE_BUNDLE_NAME);
  const [policyResourceBundle] = useBundle(POLICY_RESOURCE_BUNDLE_NAME);
  const [playBookResourceBundle] = useBundle(PLAYBOOK_RESOURCE_BUNDLE_NAME);
  const [caseStudyResourceBundle] = useBundle(CASE_STUDY_RESOURCE_BUNDLE_NAME);
  const [methodologyResourceBundle] = useBundle(METHODOLOGY_RESOURCE_BUNDLE_NAME);
  const [guidanceResourceBundle] = useBundle(GUIDANCE_RESOURCE_BUNDLE_NAME);
  const [collaboratorsResourceBundle] = useBundle(COLLABORATORS_RESOURCE_BUNDLE_NAME);

  const bundleByResourceType: Record<string, any> = {
    [ResourceType.SECONDARY_HERO]: secondaryHeroResourceBundle,
    [ResourceType.POLICY]: policyResourceBundle,
    [ResourceType.PLAYBOOK]: playBookResourceBundle,
    [ResourceType.CASE_STUDY]: caseStudyResourceBundle,
    [ResourceType.METHODOLOGY]: methodologyResourceBundle,
    [ResourceType.GUIDANCE]: guidanceResourceBundle,
    [ResourceType.COLLABORATORS]: collaboratorsResourceBundle,
  };

  const { isSmallDesktop } = useDeviceSize();
  const displayType = isSmallDesktop ? DisplayType.STANDARD : DisplayType.MOBILE;

  // TODO: Once additional focus area data is available, update this to be the impactArea input prop instead of hard-coding
  const pageData: Page = Pages[focusAreaId];

  // Extract the PLAYBOOK, rank 1 CASE_STUDY, and rank 1 ADDITIONAL_RESOURCE from the focus area to show as a preview
  const playbookResource = getResourcesBySectionType(pageData.sections, SectionType.PLAYBOOK)[0];
  const caseStudyResource = getResourcesBySectionType(pageData.sections, SectionType.CASE_STUDIES).sort(
    (resourceA, resourceB) =>
      resourceA.control.rank && resourceB.control.rank ? resourceA.control.rank - resourceB.control.rank : 0
  )[0];
  const additionalResource = getResourcesBySectionType(pageData.sections, SectionType.ADDITIONAL_RESOURCES).sort(
    (resourceA, resourceB) =>
      resourceA.control.rank && resourceB.control.rank ? resourceA.control.rank - resourceB.control.rank : 0
  )[0];

  const previewResources = [
    ...(playbookResource && [playbookResource]),
    ...(caseStudyResource && [caseStudyResource]),
    ...(additionalResource && [additionalResource]),
  ];

  // Extract SECONDARY_HERO information to get Focus Area name/description
  const secondaryHero = getResourcesBySectionType(pageData.sections, SectionType.SECONDARY_HERO)?.[0];
  // TODO remove after testing https://taskei.amazon.dev/tasks/V1558443290
  const renderAccessibleSlider = shouldRenderAccessibleSlider();

  return (
    <>
      <FocusAreaHairline aria-hidden />
      {renderAccessibleSlider ? (
        <SliderV2
          className={styles.slider}
          headingSize="h3"
          size="medium-large"
          displayContents
          heading={secondaryHero && t(secondaryHeroResourceBundle, secondaryHero.display?.name)}
          description={secondaryHero && t(secondaryHeroResourceBundle, secondaryHero.display?.shortDescription)}
          cta={{
            label: t(gettingStartedBundle, 'viewAllResourcesButton_label'),
            icon: 'chevronRight',
            url: `/focus-areas/${FOCUS_AREA_URL_BY_PAGE_ID[focusAreaId]}`,
          }}
        >
          {previewResources.map((resource: Resource) => {
            const { icon, label } = getCtaByResource(resource.resourceType as ResourceType);
            return (
              <CardV2
                key={resource.id}
                title={t(
                  bundleByResourceType[resource.resourceType],
                  resource.resourceType === ResourceType.PLAYBOOK
                    ? resource.display?.playbookPrimaryTitle
                    : resource.display?.name
                )}
                subtitle={t(
                  bundleByResourceType[resource.resourceType],
                  resource.display?.shortDescription ?? resource.display?.playbookAccordion?.[0].description
                )}
                image={
                  resource.assets.secondaryAssets?.thumbnail &&
                  `${getLibraryDomainRoot()}${extractAssetDisplayType(resource.assets.secondaryAssets?.thumbnail, displayType)?.assetUrl}`
                }
                alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                pill={convertToTitleCase(resource.resourceType)}
                icon={icon as IconType}
                link={{
                  label,
                  url: getResourceLink(resource),
                }}
              />
            );
          })}
        </SliderV2>
      ) : (
        <Slider
          className={styles.slider}
          headingSize="h3"
          size="medium-large"
          displayContents
          heading={secondaryHero && t(secondaryHeroResourceBundle, secondaryHero.display?.name)}
          description={secondaryHero && t(secondaryHeroResourceBundle, secondaryHero.display?.shortDescription)}
          cta={{
            label: t(gettingStartedBundle, 'viewAllResourcesButton_label'),
            icon: 'chevronRight',
            url: `/focus-areas/${FOCUS_AREA_URL_BY_PAGE_ID[focusAreaId]}`,
          }}
        >
          {previewResources.map((resource: Resource) => {
            const { icon, label } = getCtaByResource(resource.resourceType as ResourceType);
            return (
              <Card
                key={resource.id}
                title={t(
                  bundleByResourceType[resource.resourceType],
                  resource.resourceType === ResourceType.PLAYBOOK
                    ? resource.display?.playbookPrimaryTitle
                    : resource.display?.name
                )}
                subtitle={t(
                  bundleByResourceType[resource.resourceType],
                  resource.display?.shortDescription ?? resource.display?.playbookAccordion?.[0].description
                )}
                image={
                  resource.assets.secondaryAssets?.thumbnail &&
                  `${CLOUDFRONT_PREFIX}${extractAssetDisplayType(resource.assets.secondaryAssets?.thumbnail, getDisplayType(isSmallDesktop))?.assetUrl}`
                }
                alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                pill={convertToTitleCase(resource.resourceType)}
                icon={icon as IconType}
                link={{
                  label,
                  url: getResourceLink(resource),
                }}
              />
            );
          })}
        </Slider>
      )}
    </>
  );
};
