import classNames, { type Argument } from 'classnames';
import { type ReactElement } from 'react';
import React from 'react';

import { IconType, PrimaryButton, FormEntryPointCard, FormattedLink } from 'src/components/atoms';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './TextComponent.module.scss';

interface TextComponentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  copy?: string;
  externalLink?: string;
  heading: string;
  ctas?: {
    primary?: {
      copy: string;
      href: string;
      icon?: string;
    };
    secondary?: {
      copy: string;
      href: string;
    };
    tertiary?: {
      href: string;
    };
  };
  theme?: SECTION_THEME;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  contentClassName?: Argument;
  headingClassName?: Argument;
  copyClassName?: Argument;
  formVariant?: 'stories' | 'resources';
}

export function TextComponent({
  className,
  copy,
  externalLink,
  heading,
  ctas,
  theme = SECTION_THEME.Dark,
  headingSize = 'h2',
  headingClassName,
  contentClassName,
  copyClassName,
  formVariant = 'stories',
  ...props
}: TextComponentProps): ReactElement {
  const HeadingTag = React.createElement(
    headingSize,
    { className: classNames(styles.heading, headingClassName) },
    heading
  );

  return (
    <div
      className={classNames(styles.TextComponent, styles[theme], className, ctas?.tertiary && styles.entryPoint)}
      {...(props as React.HTMLAttributes<HTMLDivElement>)}
    >
      {ctas?.tertiary ? (
        <>
          <div className={classNames(styles.textWrapper, styles[theme], styles.contactUsWrapper)}>
            {HeadingTag}
            <div className={classNames(styles.content, contentClassName)}>
              {copy && (
                <div className={classNames(styles.description, copyClassName)} data-testid="textComponentDescription">
                  {copy
                    .split('*')
                    .map((part, index) =>
                      index % 2 === 0
                        ? part
                        : externalLink && (
                            <FormattedLink
                              key={part}
                              className={styles.externalLink}
                              showIcon
                              variant="outlined"
                              text={part}
                              link={externalLink}
                              openNewTab
                            />
                          )
                    )}
                </div>
              )}

              <div className={styles.ctasContainer}>
                {ctas?.primary && (
                  <PrimaryButton
                    icon={(ctas.primary.icon as IconType) ?? 'chevronRight'}
                    label={ctas.primary.copy}
                    link={ctas.primary.href}
                    theme={theme}
                    isBold
                    className={styles.primaryCta}
                  />
                )}

                {ctas?.secondary && (
                  <FormattedLink
                    className={styles.secondaryCta}
                    showIcon
                    text={ctas.secondary.copy}
                    link={ctas.secondary.href}
                    openNewTab
                  />
                )}
              </div>
            </div>
          </div>
          <FormEntryPointCard theme={theme} formUrl={ctas.tertiary.href} variant={formVariant} />
        </>
      ) : (
        <>
          {HeadingTag}
          <div className={classNames(styles.content, contentClassName)}>
            {copy && (
              <div className={classNames(styles.description, copyClassName)} data-testid="textComponentDescription">
                {copy
                  .split('*')
                  .map((part, index) =>
                    index % 2 === 0
                      ? part
                      : externalLink && (
                          <FormattedLink
                            key={part}
                            className={styles.externalLink}
                            showIcon
                            variant="outlined"
                            text={part}
                            link={externalLink}
                            openNewTab
                          />
                        )
                  )}
              </div>
            )}

            <div className={styles.ctasContainer}>
              {ctas?.primary && (
                <PrimaryButton
                  icon={(ctas.primary.icon as IconType) ?? 'chevronRight'}
                  label={ctas.primary.copy}
                  link={ctas.primary.href}
                  theme={theme}
                  isBold
                  className={styles.primaryCta}
                />
              )}

              {ctas?.secondary && (
                <FormattedLink
                  className={styles.secondaryCta}
                  showIcon
                  text={ctas.secondary.copy}
                  link={ctas.secondary.href}
                  openNewTab
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
