import React, { createContext, useMemo } from 'react';

import {
  GetSearchResultsRequest,
  GetSearchResultsResponse,
} from '@amzn/coral_com-amazon-sssapinterface-model-operations';

import { sssAPIClientSingleton as searchClient } from 'src/clients/ash-service-client';

const DEFAULT_PAGE_NUMBER = 1;
const DEFAULT_PAGE_SIZE = 20;

const fetchSearchResults = async (searchParams: string): Promise<GetSearchResultsResponse> => {
  const request = GetSearchResultsRequest.clone({
    query: searchParams,
    pageNumber: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  if (!searchClient) {
    throw new Error('Search client is not defined');
  }

  // TODO not properly deserializing Cloudfront errors
  // https://issues.amazon.com/issues/ASX-6
  return searchClient.getSearchResults(request).toPromise();
};

export interface SearchContext {
  fetchSearchResults: (searchParams: string) => Promise<GetSearchResultsResponse>;
}

export const SearchClientContext = createContext<SearchContext>({ fetchSearchResults });

export const SearchClientProvider: React.FunctionComponent<{ children: React.ReactNode }> = ({ children }) => {
  const contextValue = useMemo<SearchContext>(() => ({ fetchSearchResults }), []);
  return <SearchClientContext.Provider value={contextValue}>{children}</SearchClientContext.Provider>;
};
