import { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';

import { Resource, ResourceType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { KatProgress } from '@amzn/katal-react';

import { Divider } from 'src/components/atoms';
import { Card, PageSection, Slider, CardV2, SliderV2 } from 'src/components/common';
import { ResourceTemplate } from 'src/components/pages/resource-pages/ResourceTemplate';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { Pages } from 'src/fixtures/structures/pages';
import { extractAssetDisplayType, getDisplayType, shouldRenderAccessibleSlider, t, formatUrl } from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';
import { useDeviceTracker } from 'src/hooks/useDeviceTracker.hook';

import { useMeasurementSections } from './useMeasurementSections';

export const MeasurementPage: FunctionComponent = () => {
  const { getCtaByResource } = useCommonStrings();
  const { isDesktop } = useDeviceTracker();
  const { isSmallDesktop } = useDeviceSize();

  const page = Pages.measurement;

  const {
    isBundleLoading,
    commonBundle,
    dataSection,
    dataResources,
    dataResourcesBundle,
    measurementResourcesBundle,
    guidanceSection,
    guidanceResources,
    guidanceResourcesBundle,
    toolSection,
    toolResources,
    toolResourcesBundle,
  } = useMeasurementSections(page);

  if (!page) {
    return <Navigate to="/404" />;
  }

  if (isBundleLoading || !measurementResourcesBundle) {
    return <KatProgress indeterminate type="circular" />;
  }

  // TODO remove after testing https://taskei.amazon.dev/tasks/V1558443290
  const renderAccessibleSlider = shouldRenderAccessibleSlider();

  return (
    <ResourceTemplate page={page} mainBundle={measurementResourcesBundle}>
      {toolSection && (
        <PageSection data-anchor id={t(commonBundle, 'toolsAnchor_id')}>
          {renderAccessibleSlider ? (
            <SliderV2
              key={`tools-slider-${page.id}`}
              theme={SECTION_THEME.Light}
              heading={t(measurementResourcesBundle, toolSection.display?.title)}
              description={t(measurementResourcesBundle, toolSection.display?.shortDescription)}
            >
              {toolResources.map((resource: Resource) => (
                <CardV2
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={
                    resource.assets.secondaryAssets?.thumbnail &&
                    formatUrl(
                      extractAssetDisplayType(
                        resource.assets.secondaryAssets?.thumbnail,
                        getDisplayType(isSmallDesktop)
                      )?.assetUrl
                    )
                  }
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(toolResourcesBundle, resource.display?.title)}
                  subtitle={t(toolResourcesBundle, resource.display?.shortDescription)}
                  icon="chevronTopRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </SliderV2>
          ) : (
            <Slider
              key={`tools-slider-${page.id}`}
              theme={SECTION_THEME.Light}
              heading={t(measurementResourcesBundle, toolSection.display?.title)}
              description={t(measurementResourcesBundle, toolSection.display?.shortDescription)}
            >
              {toolResources.map((resource: Resource) => (
                <Card
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={
                    resource.assets.secondaryAssets?.thumbnail &&
                    formatUrl(
                      extractAssetDisplayType(
                        resource.assets.secondaryAssets?.thumbnail,
                        getDisplayType(isSmallDesktop)
                      )?.assetUrl
                    )
                  }
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(toolResourcesBundle, resource.display?.title)}
                  subtitle={t(toolResourcesBundle, resource.display?.shortDescription)}
                  icon="chevronRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </Slider>
          )}
        </PageSection>
      )}

      {isDesktop && <Divider />}

      {guidanceSection && (
        <PageSection data-anchor id={t(commonBundle, 'guidanceAnchor_id')}>
          {renderAccessibleSlider ? (
            <SliderV2
              key={`guidance-slider-${page.id}`}
              size="small"
              theme={SECTION_THEME.Light}
              heading={t(measurementResourcesBundle, guidanceSection.display?.title)}
              description={t(measurementResourcesBundle, guidanceSection.display?.shortDescription)}
            >
              {guidanceResources.map((resource: Resource) => (
                <CardV2
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(guidanceResourcesBundle, resource.display?.title)}
                  subtitle={t(guidanceResourcesBundle, resource.display?.shortDescription)}
                  icon="chevronTopRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </SliderV2>
          ) : (
            <Slider
              key={`guidance-slider-${page.id}`}
              size="small"
              theme={SECTION_THEME.Light}
              heading={t(measurementResourcesBundle, guidanceSection.display?.title)}
              description={t(measurementResourcesBundle, guidanceSection.display?.shortDescription)}
            >
              {guidanceResources.map((resource: Resource) => (
                <Card
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(guidanceResourcesBundle, resource.display?.title)}
                  subtitle={t(guidanceResourcesBundle, resource.display?.shortDescription)}
                  icon="chevronRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </Slider>
          )}
        </PageSection>
      )}

      {dataSection && (
        <PageSection data-anchor id={t(commonBundle, 'dataAnchor_id')}>
          {renderAccessibleSlider ? (
            <SliderV2
              key={`data-resources-slider-${page.id}`}
              size="small"
              theme={SECTION_THEME.Light}
              heading={t(measurementResourcesBundle, dataSection.display?.title)}
              description={t(measurementResourcesBundle, dataSection.display?.shortDescription)}
            >
              {dataResources.map((resource: Resource) => (
                <CardV2
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(dataResourcesBundle, resource.display?.title)}
                  subtitle={t(dataResourcesBundle, resource.display?.shortDescription)}
                  icon="chevronTopRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: resource.assets.primaryAsset[0].assetUrl,
                  }}
                />
              ))}
            </SliderV2>
          ) : (
            <Slider
              key={`data-resources-slider-${page.id}`}
              size="small"
              theme={SECTION_THEME.Light}
              heading={t(measurementResourcesBundle, dataSection.display?.title)}
              description={t(measurementResourcesBundle, dataSection.display?.shortDescription)}
            >
              {dataResources.map((resource: Resource) => (
                <Card
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(dataResourcesBundle, resource.display?.title)}
                  subtitle={t(dataResourcesBundle, resource.display?.shortDescription)}
                  icon="chevronRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: resource.assets.primaryAsset[0].assetUrl,
                  }}
                />
              ))}
            </Slider>
          )}
        </PageSection>
      )}
    </ResourceTemplate>
  );
};
