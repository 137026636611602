import { FunctionComponent } from 'react';
import { useParams, Navigate } from 'react-router-dom';

import { Resource, ResourceType } from '@amzn/coral_com-amazon-sssapinterface-model';
import { KatProgress } from '@amzn/katal-react';

import { Card, CardV2, PageSection, Slider, SliderV2 } from 'src/components/common';
import { ResourceTemplate } from 'src/components/pages/resource-pages/ResourceTemplate';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { Pages } from 'src/fixtures/structures/pages';
import { shouldRenderAccessibleSlider, t, formatUrl } from 'src/helpers';
import { useCommonStrings } from 'src/hooks/useCommonStrings';

import { FOCUS_AREA_PAGE_ID, FOCUS_AREA_URL } from './focusAreaPath';
import { useFocusAreaSections } from './useFocusAreaSections';

export const FocusAreaPage: FunctionComponent = () => {
  const { focusAreaUrl } = useParams();
  const { getCtaByResource } = useCommonStrings();

  const focusAreaId = FOCUS_AREA_PAGE_ID[focusAreaUrl as FOCUS_AREA_URL];

  const page = Pages[focusAreaId];

  const {
    isBundleLoading,
    commonBundle,
    focusAreaBundle,
    collaboratorsSection,
    collaboratorsResources,
    collaboratorsBundle,
  } = useFocusAreaSections(page);

  if (!page) {
    return <Navigate to="/404" />;
  }

  if (isBundleLoading || !focusAreaBundle) {
    return <KatProgress indeterminate type="circular" />;
  }

  // TODO remove after testing https://taskei.amazon.dev/tasks/V1558443290
  const renderAccessibleSlider = shouldRenderAccessibleSlider();

  return (
    <ResourceTemplate page={page} mainBundle={focusAreaBundle}>
      {collaboratorsSection && (
        <PageSection data-anchor id={t(commonBundle, 'collaboratorsAnchor_id')}>
          {renderAccessibleSlider ? (
            <SliderV2
              key={`collaborators-slider-${page.id}`}
              size="small"
              theme={SECTION_THEME.Light}
              heading={t(focusAreaBundle, collaboratorsSection.display?.title)}
              description={t(focusAreaBundle, collaboratorsSection.display?.shortDescription)}
            >
              {collaboratorsResources.map((resource: Resource) => (
                <CardV2
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(collaboratorsBundle, resource.display?.title)}
                  subtitle={t(collaboratorsBundle, resource.display?.shortDescription)}
                  icon="chevronTopRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </SliderV2>
          ) : (
            <Slider
              key={`collaborators-slider-${page.id}`}
              size="small"
              theme={SECTION_THEME.Light}
              heading={t(focusAreaBundle, collaboratorsSection.display?.title)}
              description={t(focusAreaBundle, collaboratorsSection.display?.shortDescription)}
            >
              {collaboratorsResources.map((resource: Resource) => (
                <Card
                  theme={SECTION_THEME.Light}
                  key={resource.display?.name}
                  image={formatUrl(resource.assets.secondaryAssets?.thumbnail?.[0].assetUrl)}
                  alt={resource.assets.secondaryAssets?.thumbnail?.[0].altText}
                  title={t(collaboratorsBundle, resource.display?.title)}
                  subtitle={t(collaboratorsBundle, resource.display?.shortDescription)}
                  icon="chevronRight"
                  link={{
                    label: getCtaByResource(resource.resourceType as ResourceType).label,
                    url: formatUrl(resource.assets.primaryAsset[0].assetUrl),
                  }}
                />
              ))}
            </Slider>
          )}
        </PageSection>
      )}
    </ResourceTemplate>
  );
};
