import { FunctionComponent, RefObject } from 'react';

import { MessageBundle } from '@amzn/arb-tools';
import { SearchResult as SearchResultModel } from '@amzn/coral_com-amazon-sssapinterface-model-structures-search';

import { Icon } from 'src/components/atoms';
import { PRIMARY_BUTTON_SIZE, PrimaryButton } from 'src/components/atoms/primary-button/PrimaryButton';
import { INPUT_STYLE, SearchInput } from 'src/components/pages/search/SearchInput';
import {
  CLEAR_BUTTON_LABEL,
  MAX_SEARCH_QUERY_LENGTH,
  SEARCH_BUTTON_LABEL,
  SEARCH_PLACEHOLDER_LABEL,
  SEARCH_TITLE_LABEL,
} from 'src/constants';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';
import { t } from 'src/helpers/translation-helpers';
import { useDeviceSize } from 'src/hooks/useDeviceSize.hook';

import styles from './SearchBar.module.scss';

export interface SearchBarProps {
  query: string;
  searchBundle: MessageBundle;
  inputRef: RefObject<HTMLInputElement>;
  handleSubmit: (event: React.FormEvent) => void;
  handleSearchInput: (event: any) => void;
  handleClearClick: () => void;
  searchParams: URLSearchParams;
  searchResults: SearchResultModel[] | undefined;
  hasError: boolean;
}

export const SearchBar: FunctionComponent<SearchBarProps> = ({
  query,
  searchBundle,
  inputRef,
  handleSubmit,
  handleSearchInput,
  handleClearClick,
  searchParams,
  searchResults,
  hasError,
}) => {
  const { isSmallDesktop } = useDeviceSize();

  // For mobile, if there are search results the search bar collapses to provide more space
  return searchResults && searchResults?.length > 0 && !isSmallDesktop ? (
    <form className={styles.collapsedSearch} data-testid="search-form" onSubmit={handleSubmit} role="search">
      <div className={styles.collapsedContainer}>
        <SearchInput
          placeholder={t(searchBundle, SEARCH_PLACEHOLDER_LABEL)}
          inputRef={inputRef}
          value={query}
          maxLength={MAX_SEARCH_QUERY_LENGTH}
          onInput={handleSearchInput}
          className={INPUT_STYLE.Collapsed}
        />
        <div className={styles.collapsedClearButtonContainer} data-testid="clear-button">
          {query.trim() && (
            <button type="button" className={styles.collapsedClearButton} onClick={handleClearClick} aria-label="Clear">
              <Icon name="close" className={styles.clearIcon} />
            </button>
          )}
        </div>
      </div>
    </form>
  ) : (
    <div className={styles.searchBarContainer}>
      <p className={styles.title}>{t(searchBundle, SEARCH_TITLE_LABEL)}</p>
      <form data-testid="search-form" onSubmit={handleSubmit} role="search">
        <div className={styles.inputContainer}>
          <SearchInput
            placeholder={t(searchBundle, SEARCH_PLACEHOLDER_LABEL)}
            inputRef={inputRef}
            value={query}
            maxLength={MAX_SEARCH_QUERY_LENGTH}
            onInput={handleSearchInput}
            className={INPUT_STYLE.Regular}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <PrimaryButton
            disabled={hasError ? false : !query.trim() || query === searchParams.get('q')}
            className={styles.submitButton}
            contentClassName={styles.submitButtonContent}
            label={t(searchBundle, SEARCH_BUTTON_LABEL)}
            icon="chevronRight"
            type="submit"
            buttonSize={PRIMARY_BUTTON_SIZE.Small}
            data-testid="submit-button"
          />
          {query.trim() && (
            <PrimaryButton
              className={styles.clearButton}
              label={t(searchBundle, CLEAR_BUTTON_LABEL)}
              buttonSize={PRIMARY_BUTTON_SIZE.Small}
              theme={SECTION_THEME.Dark}
              variant="inverted"
              onClick={handleClearClick}
              data-testid="clear-button"
            />
          )}
        </div>
      </form>
    </div>
  );
};
