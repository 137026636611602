import classNames from 'classnames';
import React, { ReactElement } from 'react';

import { KatCard } from '@amzn/katal-react';

import { PrimaryButton, PRIMARY_BUTTON_SIZE } from 'src/components/atoms';
import { AsxImage } from 'src/components/atoms/asx-image/AsxImage';
import { IconType } from 'src/components/atoms/icons/icons';
import { SECTION_THEME } from 'src/data/enums/SectionTheme';

import styles from './Card.module.scss';

interface CardLink {
  label: string;
  url: string;
}

export interface CardProps {
  title?: string;
  subtitle?: string;
  image?: string;
  alt?: string;
  pill?: string;
  details?: string;
  link?: CardLink;
  icon?: IconType;
  theme?: SECTION_THEME;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  target?: string;
  imagePosition?: 'center' | 'right';
}

export function Card({
  title,
  subtitle,
  link,
  image,
  alt,
  pill,
  details,
  icon,
  theme = SECTION_THEME.Light,
  headingSize = 'h3',
  target = '_blank',
  imagePosition = 'center',
}: CardProps): ReactElement {
  const HeadingTag = React.createElement(
    headingSize,
    { className: classNames(styles[theme], styles.CardHeading) },
    title
  );

  const handleClick = () => {
    if (link?.url) {
      if (target === '_blank') {
        window.open(link.url, '_blank');
      } else {
        window.location.href = link.url;
      }
    }
  };

  return (
    <KatCard
      className={classNames(styles[theme], styles.Card)}
      onClick={handleClick}
      // applying tabIndex 0 since now the card is clickeable
      tabIndex={0}
    >
      {(pill ?? details) && (
        <div className={styles.CardHeader} slot="title">
          {pill && <div className={classNames(styles[theme], styles.CardPill)}>{pill}</div>}
          {details && <div className={classNames(styles[theme], styles.Details)}>{details}</div>}
        </div>
      )}
      <div className={classNames(styles[theme], styles.CardContent)}>
        <div className={styles.CardText}>
          {HeadingTag}
          <span className={styles.CardSubtitle}> {subtitle} </span>
        </div>

        {image && (
          <AsxImage
            className={styles.CardImage}
            src={image}
            alt={alt ?? ''}
            style={{ objectPosition: imagePosition }}
          />
        )}
      </div>
      <div className={classNames(styles[theme], styles.CardFooter)} slot="footer">
        {link && (
          <>
            <p className={classNames(styles.link, styles[theme])}>{link.label}</p>

            <PrimaryButton
              className={styles.CardActionButton}
              icon={icon}
              buttonSize={PRIMARY_BUTTON_SIZE.Small}
              theme={theme}
              // applying tabIndex -1 since this button it's not an interactive element, the whole card is.
              tabIndex={-1}
            />
          </>
        )}
      </div>
    </KatCard>
  );
}
