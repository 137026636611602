import { classMap } from 'lit-html/directives/class-map.js';
import { nothing } from 'lit-html';
import { html, property } from 'lit-element';
import { ifNotNull } from '../../utils/directives';
import {
  KatLitElement,
  Keys,
  register,
  nextUniqueId,
  event,
  EventEmitter,
} from '../../shared/base';
import { checkSlots } from '../../shared/slot-utils';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './toggle.lit.scss';

/**
 * @component {kat-toggle} KatalToggle Toggles allow the user to switch between two states or to turn something on and off and immediately update the backend state without requiring a "save" or other confirmation action.
 * @guideline Do Only use for binary states such as on/off, visible/hidden, and enabled/disabled.
 * @guideline Dont Don't use toggles for selecting between two choices, for example, red/green. Instead, use radio buttons.
 * @slot default Elements without a named slot attribute appearsinside the default description text slot. Is always visible.
 * @slot checked "Checked" helper text appears inside the toggle description section. Only visible when checked is true.
 * @slot unchecked "Unchecked" helper text appears inside the toggle description section. Only visible when checked is false.
 * @status Production
 * @theme flo
 * @example Slotted {"checked": true, "content": "<kat-statusindicator slot=\"checked\" variant=\"success\" label=\"nice\"></kat-statusindicator><kat-statusindicator slot=\"unchecked\" variant=\"error\" label=\"no go\"></kat-statusindicator>"}
 * @example Disabled {"checked": false, "disabled": true, "content": "<span>Disabled</span>"}
 * @example CheckedText {"checked": true, "text-checked": "visible when the toggle is checked"}
 * @example UncheckedText {"checked": false, "text-unchecked": "visible when the toggle is unchecked"}
 * @a11y {keyboard}
 * @a11y {contrast}
 * @a11y {sr}
 */
@register('kat-toggle')
export class KatToggle extends KatLitElement {
  /** Input name - submitted as a pair with toggle's value as part of the form data. */
  @property()
  name?: string;

  /** Prohibits toggle click/change events. */
  @property()
  disabled?: boolean;

  /** Binary state indicating whether the toggle is "on/active" or "off/inactive". */
  @property()
  checked?: boolean;

  /** Text to display when the toggle is checked. Defaults to empty string. */
  @property({ attribute: 'text-checked' })
  textChecked?: string;

  /** Text to display when the toggle is not checked. Defaults to empty string. */
  @property({ attribute: 'text-unchecked' })
  textUnchecked?: string;

  /** Input value. Defaults to native checkbox "on". */
  @property()
  value?: string;

  /** The id on the inner checkbox input, only necessary if the user plans to wire up their own label element */
  @property({ attribute: 'unique-id' })
  uniqueId: string = nextUniqueId();

  /** Fires whenever the toggle is clicked. */
  @event('change', true)
  private _change: EventEmitter<{ checked: boolean }>;

  static get styles() {
    return [baseStyles, styles];
  }

  _handleToggle(e) {
    e.preventDefault();
    e.stopImmediatePropagation();

    if (this.disabled) {
      return;
    }

    this.toggle();
  }

  _handleKeyDown(e) {
    if (e.keyCode === Keys.Enter || e.keyCode === Keys.Space) {
      this._handleToggle(e);
    }
  }

  /**
   * Call to toggle the toggle "checked" state. Fires the 'change' event.
   * @param {Boolean} toState The checked state to toggle to. If undefined, toggles from the current state.
   * @katalmethod
   */
  public async toggle(toState?: boolean) {
    const newVal = toState !== undefined ? !!toState : !this.checked;
    this.checked = newVal;

    await this.updateComplete;
    this._change.emit({ checked: this.checked });
  }

  render() {
    const result = checkSlots(this);
    const hasText =
      result.default ||
      this.textChecked ||
      this.textUnchecked ||
      result.checked ||
      result.unchecked;
    const value = this.value ? this.value : 'on';

    return html`
      <input
        class="input"
        type="checkbox"
        id="${this.uniqueId}"
        ?checked=${this.checked}
        ?disabled=${this.disabled}
        aria-checked=${this.checked ? 'true' : 'false'}
        aria-label=${this.checked ? this.textChecked : this.textUnchecked}
        value=${value}
        part="toggle-input"
        @keydown=${this._handleKeyDown}
        name=${ifNotNull(this.name)}
      />
      <label for="${this.uniqueId}">
        <div part="toggle-action" class="action" @click=${this._handleToggle}>
          <div
            part="toggle-wrapper"
            class=${classMap({
              indicator__wrapper: true,
              'has-text': hasText,
            })}
          >
            <div class="indicator" part="toggle-indicator"></div>
            <div class="text__wrapper">
              <slot></slot>
              <span class="text--unchecked">
                <slot name="unchecked">${this.textUnchecked || nothing}</slot>
              </span>
              <span class="text--checked">
                <slot name="checked">${this.textChecked || nothing}</slot>
              </span>
            </div>
          </div>
        </div>
      </label>
    `;
  }

  createRenderRoot() {
    return this.attachShadow({ mode: 'open', delegatesFocus: true });
  }
}
